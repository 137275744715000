import React, { useEffect, useState } from 'react';
import { gql, useQuery, useLazyQuery } from '@apollo/client';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { ChevronLeft as ChevronLeftIcon, Pencil as PencilIcon } from 'lucide-react';

import DetailDisplay from './DetailDisplay';

const IMG_AUTH = gql`
    query userImgAuth {
        b2Auth @client
        businessRid @client
    }
`;

const GET_SWAGINVENTORY = gql`
    query GetSwagInventory(
        $getRid: String
    ) {
        swaginventorydetail(
            getRid: $getRid
        ) {
            swag{
                rid
                manufacturer
                name
                category
                subcategory
                vendor
                description
                images
                value
                currency
            }
            results{
                rid
                color
                size
                status
                quantity
                location
                value
                currency
            }
            disbursements{
                rid
                status
                destinationtype
                destination
                value
                currency
                client{
                    rid
                    name
                }
                person{
                    rid
                    persontype
                    firstName
                    lastName
                    jobTitle
                }
                quantity
                updated
            }
            responseType
            responseInfo
        }
    }
`;

export default function DetailInventory(props) {
    const { isMobile } = props;
    const location = useLocation();
    const updateActionInit = location?.state?.modify || false;
    const [updateAction, setUpdateAction] = useState(false);

    let { rid } = useParams();

    let navigate = useNavigate();

    const { data: cacheImgAuth } = useQuery(IMG_AUTH);
    const b2Auth = cacheImgAuth?.b2Auth;
    const businessRid = cacheImgAuth?.businessRid;

    const [getDetail, { error, loading, data }] = useLazyQuery(
        GET_SWAGINVENTORY, { fetchPolicy: "network-only", variables: { "getRid": rid } }
    );

    useEffect(() => {
        getDetail();
    }, [rid]);

    useEffect(() => {
        setUpdateAction(updateActionInit);
    }, [updateActionInit]);

    const navGoBack = () => {
        navigate(-1);
    }

    const editBtnAction = () => {
        navigate(`/inventory/modify/${rid}`)
    }

    return (
        <Box component="main" sx={{flexGrow: 1,height: '100vh',overflow: 'auto',}}>
        <Container sx={{ marginTop: '80px' }} maxWidth="xl">

            <Grid container direction="row" justifyContent="space-between" alignItems="flex-end" spacing={2} sx={{ marginBottom: '8px' }}>
                <Grid item>
                    {isMobile ?
                        <Button onClick={navGoBack} aria-label="go back"><ChevronLeftIcon strokeWidth={1} /></Button>
                        :
                        <Button onClick={navGoBack} aria-label="go back" startIcon={<ChevronLeftIcon strokeWidth={1} />}>Go Back</Button>
                    }
                </Grid>
                {/* <Grid item md order={{ xs: 2, md: 3 }}>
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item>
                            {isMobile ?
                                <Button aria-label="edit" variant="outlined" onClick={editBtnAction}><PencilIcon strokeWidth={1} /></Button>
                                :
                                <Button aria-label="edit" variant="outlined" onClick={editBtnAction} endIcon={<PencilIcon strokeWidth={1} size={14} />}>Edit Swag</Button>
                            }
                        </Grid>
                    </Grid>
                </Grid> */}
                <Grid item order={{ xs: 2, md: 3 }}>
                    {isMobile ?
                        <Button aria-label="edit" variant="outlined" onClick={editBtnAction}><PencilIcon strokeWidth={1} size={14} /></Button>
                        :
                        <Button aria-label="edit" variant="outlined" onClick={editBtnAction} endIcon={<PencilIcon strokeWidth={1} size={14} />}>Edit Swag</Button>
                    }
                </Grid>
            </Grid>

            {updateAction &&
                <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ marginTop: '12px', marginBottom: '12px' }}>
                    <Grid item>
                        <Alert severity="success" variant="outlined" onClose={() => {setUpdateAction(false);}}>
                            <AlertTitle>Inventory Modified</AlertTitle>
                            This inventory was {updateAction} successfully!
                        </Alert>
                    </Grid>
                </Grid>
            }

            {error ?
                <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ marginTop: '20%' }}>
                    <Grid item>
                        <Alert severity="error" variant="outlined">
                            <AlertTitle>Error</AlertTitle>
                            There was an error processing this request. Please try again or contact us for support!
                        </Alert>
                    </Grid>
                </Grid>
                :
                loading ?
                    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
                        <CircularProgress sx={{ color: '#c9fb00' }} thickness={1} size={60} />
                    </Backdrop>
                    :
                    <DetailDisplay data={data} isMobile={isMobile} b2Auth={b2Auth} businessRid={businessRid} />
            }

        </Container>
        </Box>
    );
}