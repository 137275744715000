import React, { useState, useEffect } from 'react';
import { gql, useLazyQuery, useQuery, useApolloClient } from '@apollo/client';
import { useTheme } from '@mui/material/styles';
import { useSearchParams } from "react-router-dom";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';



/***
 * 
 * 
 * IMPORT ALL COMPANIES & CONTACTS FROM HUBSPOT!
 * IMPORT DEALS???
 * 
 * 
 */

const GET_HSAUTH = gql`
    query GetHSAuth(
        $action: String
        $code: String
    ) {
        gethsauth(
            action: $action
            code: $code
        ) {
            responseCode
            responseText
            clientId
            scope
            redirectUri
        }
    }
`;

const HS_PREAUTH = gql`
    query hsPreauthQuery {
        clientId @client
        scope @client
		redirectUri @client
    }
`;

const HS_CONNECTED = gql`
    query hsConnectedQuery {
        hsConnected @client
        hsClientId @client
    }
`;

export default function Settings() {
    const client = useApolloClient();
    const theme = useTheme();
    const [searchParams] = useSearchParams();
    const [connectGetURI, connectGetURISet] = useState(false);
    const [connectGetURILoading, connectGetURILoadingSet] = useState(false);
    const [connectURISuccess, connectURISuccessSet] = useState(false);
    const [connectURIError, connectURIErrorSet] = useState(false);
    const [accountConnected, accountConnectedSet] = useState(false);

    const [getHSConn, { data:dataHSConn }] = useLazyQuery(HS_CONNECTED,{
        onCompleted: (getHSConnData) => {
            console.log(`getHSConnData: ${getHSConnData}`);
        }
    });

    // const [getHSConn, { loading: accountConnLoading }] = useLazyQuery(HS_CONNECTED, {
    //     onCompleted: (accountConnData) => {
    //         const hsConnected = accountConnData?.hsConnected;
    //         const HSclientId = accountConnData?.clientId;
    //         console.log(`hsConnected:${hsConnected} (${typeof hsConnected}) - HSclientId:${HSclientId} --- [${accountConnected}] ---`);
    //         if(hsConnected && HSclientId){
                
    //             accountConnectedSet(true);
    //             console.log(`accountConnected :: ${accountConnected}`);
    //         }
    //         else{
    //             accountConnectedSet(false);
    //         }
    //     }
    // });

    const [getHSauth, { loading: authLoading }] = useLazyQuery(GET_HSAUTH, {
        fetchPolicy: "network-only",
        onCompleted: (authData) => {
            if (authData && authData?.gethsauth?.[0]?.responseCode === "200") {

                if(authData?.gethsauth?.[0]?.responseText === "pre-auth"){
                    const clientId = authData?.gethsauth?.[0]?.clientId;
                    const scope = authData?.gethsauth?.[0]?.scope;
                    const redirectUri = authData?.gethsauth?.[0]?.redirectUri;
                    const authUrl =
                        'https://app.hubspot.com/oauth/authorize' +
                        `?client_id=${encodeURIComponent(clientId)}` +
                        `&scope=${encodeURIComponent(scope)}` +
                        `&redirect_uri=${encodeURIComponent(redirectUri)}`;
                    client.writeQuery({
                        query: HS_PREAUTH,
                        data: {
                            clientId: clientId,
                            scope: scope,
                            redirectUri: redirectUri,
                        },
                    });
                    window.location.href = authUrl;
                    return null;
                }
                else if(authData?.gethsauth?.[0]?.responseText === "auth"){
                    client.writeQuery({query: HS_CONNECTED, data: {hsConnected: true,},});
                    accountConnectedSet(true);
                    return null;
                }
                else if(authData?.gethsauth?.[0]?.responseText === "un-auth"){
                    client.writeQuery({query: HS_CONNECTED, data: {hsConnected: false,},});
                    accountConnectedSet(false);
                    return null;
                }
                else{
                    return null;
                }
            }
            else {
                connectURIErrorSet(true);
            }
        },
        onError: (authError) => {
            connectURIErrorSet(true);
        }
    });

    useEffect(() => {
        const code = searchParams.get('code');
        if (code) {
            console.log(`code: ${code}`);
            getHSauth({variables:{'action':'auth','code':code}});
        }
    }, [searchParams]);

    useEffect(() => {
        console.log(`getHSConn`);
        getHSConn();
    }, []);

    useEffect(() => {
        console.log(`dataHSConn-${dataHSConn}`);
        if(dataHSConn){
            const hsConnected = dataHSConn?.hsConnected;
            const hsClientId = dataHSConn?.hsClientId;
            if(hsConnected && hsConnected){
                accountConnectedSet(true);
            }
        }
    }, [dataHSConn]);

    if (authLoading) {
        return (
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}><CircularProgress sx={{ color: '#c9fb00' }} thickness={1} size={60} /></Backdrop>
        )
    }

    const connectHS = () => {
        getHSauth({variables:{'action':'pre-auth'}});
    }

    const disconnectHS = () => {
        getHSauth({variables:{'action':'un-auth'}});
    }

    return (
        <Box component="main" sx={{ flexGrow: 1, height: '100vh', overflow: 'auto', }}>
            <Container sx={{ marginTop: '80px' }} maxWidth="xl">
                <Typography variant="h5">Settings</Typography>


                {connectURIError &&
                    <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ marginTop: '12px', marginBottom: '12px' }}>
                        <Grid item>
                            <Alert severity="error" variant="outlined">
                                <AlertTitle>Error</AlertTitle>
                                There was an issue connecting to your HubSpot® account.
                            </Alert>
                        </Grid>
                    </Grid>
                }

                {connectGetURILoading &&
                    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}><CircularProgress sx={{ color: '#c9fb00' }} thickness={1} size={60} /></Backdrop>
                }

                <Paper sx={{ width: '100%', padding: '20px', marginBottom: '3rem' }}>



                <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ marginTop: '12px', marginBottom: '12px' }}>
                    <Grid item xs={12} sx={{marginBottom: '12px'}}><Typography variant="h5">HubSpot®</Typography></Grid>

                    {accountConnected ?
                        <Grid item xs={12}>
                            <Alert severity="success" variant="outlined" sx={{marginBottom: '20px'}}>
                                <AlertTitle>Connected</AlertTitle>
                                Swag Manager is connected to your HubSpot® account!
                                <Typography>Client ID: {dataHSConn?.hsClientId}</Typography>
                            </Alert>

                            <Box sx={{border: '1px solid gray', padding: '16px'}}>
                                <Button sx={theme.customStyles.disconButton} onClick={()=>disconnectHS} variant="outlined" color="error">
                                    Disconnect your HubSpot® account
                                </Button>
                                <Typography sx={{ marginTop: '16px', marginBottom: '16px' }}>
                                    Note: You will also need to remove the App from your HubSpot® settings!
                                </Typography>
                            </Box>
                        </Grid>
                    :
                    <Grid item xs={12}>
                        <Button
                            onClick={connectHS}
                            sx={theme.customStyles.hsButton}
                            variant="outlined"
                        >
                            Connect to HubSpot®!
                        </Button>
                    </Grid>
                    }

                </Grid>

                </Paper>

            </Container>
        </Box>
    );
}