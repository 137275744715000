import React, { Fragment } from 'react';
import { useNavigate } from "react-router-dom";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';

import { dateOptionsShort } from '../../utils/dateoptions';

const columns = [
    // { id: 'fieldid', label: 'Field Label', minWidth: 170, align: 'left', format: (value) => value.toLocaleString('en-US'),}, or .toFixed(2), etc.
    { id: 'img', label: '' },
    { id: 'name', label: 'Name' },
    { id: 'status', label: 'Status' },
    { id: 'city', label: 'City' },
    { id: 'country', label: 'Country' },
    { id: 'phoneNumber', label: 'Phone Number' },
    { id: 'crmId', label: 'CRM ID' },
    { id: 'lastDisbursement', label: 'Last Disburse.' },
];

export default function DataTable(props) {
    const { b2Auth, businessRid, loading, data, currentLimit, setCurrentLimit, getPage, setGetPage, sort, setSort, sortDir, setSortDir } = props;

    let navigate = useNavigate();

    let data_totalResults=0, data_totalPages=0, data_getPage=0, data_currentLimit=0, paginationData, data_sortField, data_sortDir, dataToRender;

    if(data){
        data_totalResults = parseInt(data?.clients[0].totalResults);
        data_totalPages = parseInt(data?.clients[0].totalPages);
        data_getPage = parseInt(data?.clients[0].getPage);
        data_currentLimit = parseInt(data?.clients[0].currentLimit);
        paginationData = { data_totalResults, data_totalPages, data_getPage, data_currentLimit };
        data_sortField = data?.clients[0].sortField;
        data_sortDir = data?.clients[0].sortDir;
        dataToRender = data?.clients[0].results;
    }

    const handleChangePage = (event, newPage) => {
        setGetPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setCurrentLimit(+event.target.value);
        setGetPage(0);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = sort === property && sortDir === 'asc';
        setSort(property);
        setSortDir(isAsc ? 'desc' : 'asc');
    };

    const clickRow = (rid) => {
        navigate(`/companies/detail/${rid}`);
    }

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            {loading ?
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
                    <CircularProgress sx={{color:'#c9fb00'}} thickness={1} size={60} />
                </Backdrop>
                :
                <Fragment>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            <TableSortLabel
                                                active={sort === column.id}
                                                direction={sort === column.id ? sortDir : 'asc'}
                                                onClick={createSortHandler(column.id)}
                                            >
                                                {column.label}
                                                {sort === column.id ? (
                                                    <Box component="span" sx={visuallyHidden}>
                                                        {sortDir === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </Box>
                                                ) : null}
                                            </TableSortLabel>


                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data_totalResults > 0 ?
                                dataToRender
                                    .map((row) => {
                                        let imgSrc = "";
                                        let displayImages = "";
                                        const currentImages = row.images;

                                        if(currentImages && currentImages !== "[]"){
                                            const displayImagesPreSort = JSON.parse(currentImages);
                                            try{
                                                displayImages = displayImagesPreSort.sort((a, b)=>{return a.displayOrder - b.displayOrder;});
                                                if(displayImages.length > 0){
                                                    const imgSrcVal = displayImages[0]["key"];
                                                    if(imgSrcVal){
                                                        imgSrc = `https://f004.backblazeb2.com/file/swagmanager/clients/${businessRid}/images/client_${row.rid}_s_${imgSrcVal}?Authorization=${b2Auth}`;
                                                    }
                                                }
                                            }
                                            catch{displayImages = [];}
                                        }

                                        let lastDisbursement = "";
                                        if (row.lastDisbursement){
                                            lastDisbursement = (new Date(row.lastDisbursement)).toLocaleDateString(undefined, dateOptionsShort);
                                        }

                                        return (
                                            <TableRow hover role="button" tabIndex={-1} key={row.rid} onClick={()=>clickRow(row.rid)} sx={{cursor: 'pointer'}}>
                                                <TableCell>
                                                    <Avatar alt={`${row.name}`} src={imgSrc} />
                                                </TableCell>
                                                <TableCell>{row.name}</TableCell>
                                                <TableCell>{row.status}</TableCell>
                                                <TableCell>{row.city}</TableCell>
                                                <TableCell>{row.country}</TableCell>
                                                <TableCell>{row.phoneNumber}</TableCell>
                                                <TableCell>{row.crmId}</TableCell>
                                                <TableCell>{lastDisbursement}</TableCell>
                                            </TableRow>
                                        );
                                    })
                                :
                                <TableRow>
                                    <TableCell colSpan="100%">No Results</TableCell>
                                </TableRow>
                                }

                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={data_totalResults}
                        rowsPerPage={currentLimit}
                        page={getPage}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Fragment>
            }
        </Paper>
    );
}