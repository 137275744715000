import React, { useState, useEffect, Fragment } from 'react';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Save as SaveIcon } from 'lucide-react';

import { swagInventoryStatus, currencies } from '../../utils/selectOptions';

const CREATE_INVENTORY = gql`
    mutation CreateSwaginventory($swagRid: String, $status: String, $color: String, $size: String, $location: String, $quantity: String) {
        createSwaginventory(swagRid: $swagRid, status: $status, color: $color, size: $size, location: $location, quantity: $quantity){
            responseCode
            responseText
            actionType
            objectType
            objectId
		}
	}
`;

const GET_LOCATIONS = gql`
    query GetLocationlist
        {
        locationslist
            {
            results
        }
    }
`;

const scrollToTop = () => { window.scrollTo({ top: 0, behavior: 'smooth' }); };

export default function CreateInventoryForm(props) {
    const { data, navGoBack, navToUpdate } = props;
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState(false);
    const [serverErr, setServerErr] = useState(false);
    const [openLocation, setOpenLocation] = useState(false);
    const [optionsLocation, setOptionsLocation] = useState([]);

    const [errCode, errCodeSet] = useState(false);
    const [statusErr, statusErrSet] = useState(false);

    const [getLocationlist, { data: dataLocationlist, loading: loadingLocationList }] = useLazyQuery(
        GET_LOCATIONS, { fetchPolicy: "network-only" }
    );

    const swagRid = data?.swaginventorydetail?.[0]?.swag?.rid;
    const swagManufacturer = data?.swaginventorydetail?.[0]?.swag?.manufacturer;
    const swagName = data?.swaginventorydetail?.[0]?.swag?.name;
    const swagCategory = data?.swaginventorydetail?.[0]?.swag?.category;
    const swagSubcategory = data?.swaginventorydetail?.[0]?.swag?.subcategory;
    // const swagCurrency = data?.swaginventorydetail?.[0]?.swag?.currency;
    // const swagValue = data?.swaginventorydetail?.[0]?.swag?.value;

    useEffect(() => {
        if (dataLocationlist) {
            const resultData = dataLocationlist?.locationslist?.[0]?.results;
            const quotedData = resultData.replace(/'/g, '"');
            let locList;
            try {
                locList = JSON.parse(quotedData);
            }
            catch {
                locList = [];
            }
            setOptionsLocation(locList);
        }
    }, [dataLocationlist]);

    useEffect(() => {
        if (openLocation && !dataLocationlist) { getLocationlist(); }
    }, [openLocation]);

    const [status, statusSet] = useState("");
    const handleChangestatus = (event) => {
        statusSet(event.target.value);
    };

    const [color, colorSet] = useState("");
    const handleChangecolor = (event) => {
        colorSet(event.target.value);
    };

    const [size, sizeSet] = useState("");
    const handleChangesize = (event) => {
        sizeSet(event.target.value);
    };

    const [location, locationSet] = useState("");
    const handleChangelocation = (value) => {
        locationSet(value);
    };

    const [quantity, quantitySet] = useState("");
    const handleChangequantity = (event) => {
        quantitySet(event.target.value);
    };

    // const [currency, currencySet] = useState("");
    // const handleChangecurrency = (event) => {
    //     currencySet(event.target.value);
    // };

    // const [value, valueSet] = useState("");
    // const handleChangevalue = (event) => {
    //     valueSet(event.target.value);
    // };

    const collectFormData = () => {
        const formData = {};
        let formValid = true;

        errCodeSet(false);
        setServerErr(false);
        statusErrSet(false);

        if (!status || status === "") {
            statusErrSet(true);
            formValid = false;
        }

        // formValid = false;

        if (formValid) {
            setServerErr(false);
            formData.swagRid = swagRid;
            formData.status = status;
            formData.color = color;
            formData.size = size;
            formData.location = location;
            formData.quantity = quantity;
            // formData.currency = currency;
            // formData.value = value;

            setIsLoading(true);
            createSwagInventory({ variables: { ...formData } });
        }
        else {
            scrollToTop();
            if (!errCode) {
                errCodeSet(true);
            }
        }
    }

    const [createSwagInventory] = useMutation(CREATE_INVENTORY, {
        onCompleted: (setData) => {
            setIsLoading(false);
            if (setData && setData?.createSwaginventory?.responseCode === "200") {
                const rid = setData?.createSwaginventory?.objectId;

                // swagRid
                navToUpdate(swagRid);
            }
            else {
                setServerErr(`There was an issue with your request.`);
                scrollToTop();
            }
        },
        onError: (setError) => {
            setIsLoading(false);
            setServerErr(`There was an error processing your request.`);
            scrollToTop();
        }
    });

    return (
        <Container maxWidth="sm">

            <Paper sx={{ width: '100%', padding: '20px', marginBottom: '3rem' }}>
                <Grid container direction="column" justifyContent="" alignItems="">
                    <Grid item>
                        <Box sx={theme.customStyles.infoItem}>
                            <Box sx={theme.customStyles.infoItemTitle}>Category</Box>
                            <Box sx={theme.customStyles.infoItemContent}>{swagCategory} - {swagSubcategory}</Box>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box sx={theme.customStyles.infoItem}>
                            <Box sx={theme.customStyles.infoItemTitle}>Item</Box>
                            <Box sx={theme.customStyles.infoItemContent}>{swagManufacturer} - {swagName}</Box>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>

            {isLoading &&
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}><CircularProgress sx={{ color: '#c9fb00' }} thickness={1} size={60} /></Backdrop>
            }

            {serverErr &&
                <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ marginTop: '12px', marginBottom: '12px' }}>
                    <Grid item>
                        <Alert severity="error" variant="outlined">
                            <AlertTitle>Error</AlertTitle>
                            {serverErr}
                        </Alert>
                    </Grid>
                </Grid>
            }

            {errCode &&
                <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ marginTop: '12px', marginBottom: '12px' }}>
                    <Grid item>
                        <Alert severity="error" variant="outlined">
                            <AlertTitle>Error</AlertTitle>
                            Please resolve the form errors below!
                        </Alert>
                    </Grid>
                </Grid>
            }

            <Paper sx={{ width: '100%', padding: '20px', marginBottom: '3rem' }}>
                {/* <Box component="form" sx={{ '& > :not(style)': { padding: '20px', paddingBottom: '30px' } }}> */}
                <Box component="form">

                    <TextField variant="standard" id="status" label="Status" error={statusErr} value={status} onChange={handleChangestatus} select fullWidth sx={{ padding: '20px', paddingBottom: '30px' }}>
                        {swagInventoryStatus.map((option) => (<MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>))}
                    </TextField>

                    <Autocomplete
                        id="location"
                        freeSolo
                        autoSelect
                        open={openLocation}
                        onOpen={() => { setOpenLocation(true); }}
                        onClose={() => { setOpenLocation(false); }}
                        isOptionEqualToValue={(option, value) => option === value}
                        getOptionLabel={(option) => option}
                        options={optionsLocation}
                        loading={loadingLocationList}
                        renderOption={(props, option) => { return (<li {...props} key={option}>{option}</li>); }}
                        value={location}
                        onChange={(event, newValue) => {
                            handleChangelocation(newValue);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Location"
                                variant="standard"
                                sx={{ padding: '20px', paddingBottom: '30px' }}
                                fullWidth
                                InputProps={{
                                    ...params.InputProps, endAdornment: (
                                        <Fragment>
                                            {loadingLocationList ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </Fragment>
                                    ),
                                }}
                            />
                        )}
                    />

                    <TextField variant="standard" id="color" label="Color" value={color} onChange={handleChangecolor} fullWidth sx={{ padding: '20px', paddingBottom: '30px' }} />
                    <TextField variant="standard" id="size" label="Size" value={size} onChange={handleChangesize} fullWidth sx={{ padding: '20px', paddingBottom: '30px' }} />
                    <TextField variant="standard" id="quantity" type="number" label="Quantity" value={quantity} onChange={handleChangequantity} fullWidth sx={{ padding: '20px', paddingBottom: '30px' }} />

                    {/* <TextField variant="standard" id="currency" label="Currency" value={currency} onChange={handleChangecurrency} select fullWidth sx={{ padding: '20px', paddingBottom: '30px' }}>
                        {currencies.map((option) => (<MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>))}
                    </TextField>
                    <TextField variant="standard" id="value" type="number" label="Value" value={value} onChange={handleChangevalue} fullWidth sx={{ padding: '20px', paddingBottom: '30px' }} /> */}

                </Box>

                <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ marginTop: '20px' }}>
                    <Grid item>
                        <Button aria-label="cancel" variant="outlined" onClick={navGoBack}>Cancel</Button>
                    </Grid>
                    <Grid item>
                        <Button aria-label="save form" variant="outlined" onClick={collectFormData} endIcon={<SaveIcon strokeWidth={1} size={16} />}>Create Inventory</Button>
                    </Grid>
                </Grid>

            </Paper>

        </Container>
    );
}