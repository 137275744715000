import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import { Plus as PlusIcon, ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from 'lucide-react';

import { dateOptionsMidTime } from '../../utils/dateoptions';

let displayImages = [];

export default function DetailDisplay(props) {
    const { data, isMobile, b2Auth, businessRid } = props;
    const theme = useTheme();
    let navigate = useNavigate();

    const [imgSrc, setImgSrc] = useState("");
    const [imgCount, setImgCount] = useState(0);
    const [imgii, setImgii] = useState(1);
    const [imgiiDisplay, setImgiiDisplay] = useState(1);

    const data_swag_rid = data?.swaginventorydetail[0].swag?.rid;
    const data_swag_manufacturer = data?.swaginventorydetail[0].swag?.manufacturer;
    const data_swag_name = data?.swaginventorydetail[0].swag?.name;
    const data_swag_category = data?.swaginventorydetail[0].swag?.category;
    const data_swag_subcategory = data?.swaginventorydetail[0].swag?.subcategory;
    const data_swag_vendor = data?.swaginventorydetail[0].swag?.vendor;
    const data_swag_description = data?.swaginventorydetail[0].swag?.description;
    const data_results = data?.swaginventorydetail[0].results;
    const data_disbursements = data?.swaginventorydetail[0].disbursements;
    // const data_swag_business_rid = data?.swaginventorydetail[0].swag?.business?.rid;

    let dispValue = "", dispCurrency = "";
    const currency = data?.swaginventorydetail[0].swag?.currency;
    const value = data?.swaginventorydetail[0].swag?.value;
    if (currency){
        const splitCurrency = currency.split("-");
        if(splitCurrency[1]){dispCurrency = splitCurrency[1];}
        else{dispCurrency = currency;}
    }
    if(currency || value){dispValue = `${dispCurrency} ${value}`;}

    const currentImages = data?.swaginventorydetail[0].swag?.images;
    if(currentImages && currentImages !== "[]"){
        const displayImagesPreSort = JSON.parse(currentImages);
        try{
            displayImages = displayImagesPreSort.sort((a, b)=>{return a.displayOrder - b.displayOrder;});
        }
        catch{
            displayImages = [];
        }
    }

    useEffect(() => {
        if (currentImages && currentImages !== "[]") {
            setImgCount(displayImages.length);
            const imgSrcVal = displayImages[0]?.["key"];
            if (imgSrcVal) {
                setImgSrc(`https://f004.backblazeb2.com/file/swagmanager/clients/${businessRid}/images/swag_${data_swag_rid}_m_${imgSrcVal}?Authorization=${b2Auth}`);
            }
        }
    }, [currentImages]);

    const handleImgChange = (action) => {
        if(imgCount > 1){
            if (currentImages && currentImages !== "[]" && imgCount > 0) {
                if (action === "prev"){
                    if (imgii === 0){
                        setImgiiDisplay(1);
                        setImgii(imgCount - 1);}
                    else if (imgii < 1){
                        setImgii(imgCount - 1);}
                    else {
                        setImgiiDisplay(imgii + 1);
                        setImgii(imgii - 1);}
                    getImgSrc(imgii);
                }
                else {
                    if (imgii === (imgCount-1) || imgii >= imgCount){
                        setImgiiDisplay(imgCount);
                        setImgii(0);}
                    else {
                        setImgiiDisplay(imgii + 1);
                        setImgii(imgii + 1);}
                    getImgSrc(imgii);
                }
            }
        }
    }

    const getImgSrc = (ii) => {
        const imgSrcVal = displayImages[ii]?.["key"];
        if (imgSrcVal) {
            setImgSrc(`https://f004.backblazeb2.com/file/swagmanager/clients/${businessRid}/images/swag_${data_swag_rid}_m_${imgSrcVal}?Authorization=${b2Auth}`);
        }
    }

    const clickRow = (rid) => {
        navigate(`/inventory/adjust/${rid}`);
    }

    const clickDisburse = (rid) => {
        // navigate(`/inventory/disbursement/${rid}`);
        navigate(`/disbursements/detail/${rid}`);
    }

    const createInv = () => {
        navigate(`/inventory/create/inventory/${data_swag_rid}`);
    }

    return (
        <Fragment>
            <Paper sx={{ width: '100%', padding: '20px', marginBottom: '3rem' }}>

                <Grid container direction="row" justifyContent="" alignItems="center">
                    <Grid item>
                        {/* <Avatar alt="contact" sx={theme.customStyles.detailImg} /> */}



                        <Card sx={isMobile ? {minWidth:"50px",width:'100%'} : {minWidth:275}}>
                            <CardContent display="flex">
                                <Box sx={{margin:"auto", maxWidth: '400px', width: '100%'}}>
                                    {imgSrc ? 
                                        <Box sx={{width: '100%', minWidth: '180px', height: '100%', minHeight: '100px'}}>
                                            <img src={imgSrc} alt={`img`} width="100%" height="100%" />
                                        </Box>
                                        : 
                                        <Box sx={{ width: '100%', minWidth: '180px', height: '100%', minHeight: '100px'}}></Box>
                                    }
                                    {/* <Box><Typography>{imgDescription}</Typography></Box> */}
                                </Box>
                            </CardContent>
                            <CardActions>
                                <Grid container direction="row" alignItems="flex-end" justifyContent="space-between">
                                    <IconButton color="primary" onClick={()=>handleImgChange('prev')} aria-label="previous image" sx={{border: `${theme.customStyles.iconButtonOutlined.border}`}}>
                                        <ChevronLeftIcon />
                                    </IconButton>
                                    
                                    {imgCount > 0 ? 
                                        <Typography>Image <b>{imgiiDisplay}</b> of <b>{imgCount}</b></Typography> 
                                        : 
                                        <Typography>No images found</Typography>
                                    }
                                    
                                    <IconButton color="primary" onClick={()=>handleImgChange('next')}aria-label="next image" sx={{border: `${theme.customStyles.iconButtonOutlined.border}`}}>
                                        <ChevronRightIcon />
                                    </IconButton>
                                </Grid>
                            </CardActions>
                        </Card>




                    </Grid>
                    <Grid item>
                        <Grid container>
                            <Grid item>
                                <Box sx={theme.customStyles.infoItem}>
                                    <Box sx={theme.customStyles.infoItemTitle}>Category</Box>
                                    <Box sx={theme.customStyles.infoItemContent}>{data_swag_category}</Box>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box sx={theme.customStyles.infoItem}>
                                    <Box sx={theme.customStyles.infoItemTitle}>Sub category</Box>
                                    <Box sx={theme.customStyles.infoItemContent}>{data_swag_subcategory}</Box>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box sx={theme.customStyles.infoItem}>
                                    <Box sx={theme.customStyles.infoItemTitle}>Vendor</Box>
                                    <Box sx={theme.customStyles.infoItemContent}>{data_swag_vendor}</Box>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box sx={theme.customStyles.infoItem}>
                                    <Box sx={theme.customStyles.infoItemTitle}>Value</Box>
                                    <Box sx={theme.customStyles.infoItemContent}>{dispValue}</Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item>
                                <Box sx={theme.customStyles.infoItem}>
                                    <Box sx={theme.customStyles.infoItemTitle}>Manufacturer</Box>
                                    <Box sx={theme.customStyles.infoItemContentLg}>{data_swag_manufacturer}</Box>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box sx={theme.customStyles.infoItem}>
                                    <Box sx={theme.customStyles.infoItemTitle}>Name</Box>
                                    <Box sx={theme.customStyles.infoItemContent}>{data_swag_name}</Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item>
                                <Box sx={theme.customStyles.infoItem}>
                                    <Box sx={theme.customStyles.infoItemTitle}>Description</Box>
                                    <Box sx={theme.customStyles.infoItemContent}>{data_swag_description}</Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Paper>

            <Paper sx={{ width: '100%', padding: '20px', marginBottom: '3rem' }}>
                <Grid container direction="row" justifyContent="space-between">
                    <Grid item>Inventory</Grid>
                    <Grid item>
                        <Button aria-label="add disbursement" variant="outlined" onClick={createInv} endIcon={<PlusIcon strokeWidth={1} />}>Add Inventory</Button>
                    </Grid>
                </Grid>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Color</TableCell>
                                <TableCell>Size</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Location</TableCell>
                                <TableCell>Value</TableCell>
                                <TableCell>Quantity</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data_results?.length > 0 ?
                                data_results.map((row) => {
                                    
                                    let dispValue = "", dispCurrency = "";
                                    const currency = row.currency;
                                    const value = row.value;
                                    if (currency){
                                        const splitCurrency = currency.split("-");
                                        if(splitCurrency[1]){dispCurrency = splitCurrency[1];}
                                        else{dispCurrency = currency;}
                                    }
                                    if(currency || value){dispValue = `${dispCurrency} ${value}`;}

                                    return (
                                        <TableRow hover key={row.rid} onClick={()=>clickRow(row.rid)} sx={{cursor:'pointer'}}>
                                            <TableCell>{row.color}</TableCell>
                                            <TableCell>{row.size}</TableCell>
                                            <TableCell>{row.status}</TableCell>
                                            <TableCell>{row.location}</TableCell>
                                            <TableCell>{dispValue}</TableCell>
                                            <TableCell>{row.quantity}</TableCell>
                                        </TableRow>
                                    );
                                })
                                :
                                <TableRow>
                                    <TableCell colSpan="100%">No Results</TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            <Paper sx={{ width: '100%', padding: '20px', marginBottom: '3rem' }}>
                <Grid container direction="row" justifyContent="space-between">
                    <Grid item>Recent Disbursements</Grid>
                </Grid>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Dest. Type</TableCell>
                                <TableCell>Destination</TableCell>
                                <TableCell>Company</TableCell>
                                <TableCell>Contact</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Quantity</TableCell>
                                <TableCell>Updated</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data_disbursements?.length > 0 ?
                                data_disbursements.map((row) => {
                                    const updatedFormatted = (new Date(row.updated)).toLocaleDateString(undefined, dateOptionsMidTime);
                                    const displayFirst = `${row?.person?.firstName ? `${row?.person?.firstName}` : ``}`;
                                    const displayLast = `${row?.person?.lastName ? `${row?.person?.lastName}` : ``}`;
                                    const displayName = `${(row?.person?.lastName && row?.person?.firstName) ? `${displayLast}, ${displayFirst}` : row?.person?.lastName ? `${row?.person?.lastName}` : `${row?.person?.firstName}`}`;
                                    const displayTitle = `${row?.person?.jobTitle ? `(${row?.person?.jobTitle})` : ``}`;
                                    const displayType = `${row?.person?.persontype ? ` (${row?.person?.persontype})` : ``}`;
                                    let displayContact = "";
                                    if(row?.person){
                                        displayContact = `${displayName} ${displayTitle} ${displayType}`;
                                    }
                                    // const displayContact = `${displayName} ${displayTitle} ${displayType}`;
                                    return (
                                        <TableRow hover key={row.rid} onClick={()=>clickDisburse(row.rid)} sx={{cursor:'pointer'}}>
                                            <TableCell>{row.destinationtype}</TableCell>
                                            <TableCell>{row.destination}</TableCell>
                                            <TableCell>{row.client?.name}</TableCell>
                                            <TableCell>{displayContact}</TableCell>
                                            <TableCell>{row.status}</TableCell>
                                            <TableCell>{row.quantity}</TableCell>
                                            <TableCell>{updatedFormatted}</TableCell>
                                        </TableRow>
                                    );
                                })
                                :
                                <TableRow>
                                    <TableCell colSpan="100%">No Results</TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

        </Fragment>
    );
}