import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import LogIn from '../auth/LogIn';
import LogOut from '../auth/LogOut';
import SignUp from '../auth/SignUp';
import ResetPass from '../auth/ResetPass';
import ResetPassRequest from '../auth/ResetPassRequest';

import LogoGreen from '../img/logo-green.svg';
import TextGrey from '../img/text-grey.svg';

import authTheme from '../utils/theme/auth';
const authModeTheme = createTheme(authTheme);

const Sentry = (props) => {
    const { action, setPopLogout } = props;
    let navigate = useNavigate();

    const [currentMode, setCurrentMode] = useState("login");
    const [registerLoginAlert, setRegisterLoginAlert] = useState(false);

    useEffect(() => {
        if (currentMode === "signup") {
            navigate("/signup");
        }
        else if (currentMode === "reset") {
            navigate("/reset");
        }
        else if (currentMode === "resetrequest") {
            navigate("/resetrequest");
        }
        else if (currentMode === "logout") {
            navigate("/logout");
        }
        else {
            navigate("/login");
        }
    }, [currentMode]);

    return (
        <ThemeProvider theme={authModeTheme}>
            {/* <Box sx={{ display: 'flex' }}> */}
            <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Grid container direction="row" justifyContent="center" alignItems="center" sx={{marginTop: '20%'}}>
                <Grid item>
                    <img
                        src={LogoGreen}
                        alt="swag manager by lucky mallard logo"
                        loading="lazy"
                        height="50px"
                    />
                    <span style={{ marginLeft: '12px' }}>
                        <img
                            src={TextGrey}
                            alt="swag manager by lucky mallard logo text"
                            loading="lazy"
                            height="50px"
                        />
                    </span>
                </Grid>
            </Grid>
            {currentMode !== "signup" && currentMode !== "reset" && currentMode !== "resetrequest" && action !== "logout" && <LogIn setCurrentMode={setCurrentMode} registerLoginAlert={registerLoginAlert} />}
            {currentMode !== "signup" && currentMode !== "reset" && currentMode !== "resetrequest" && action === "logout" && <LogOut setCurrentMode={setCurrentMode} setPopLogout={setPopLogout} />}
            {currentMode === "signup" && <SignUp setCurrentMode={setCurrentMode} setRegisterLoginAlert={setRegisterLoginAlert} />}
            {currentMode === "resetrequest" && <ResetPassRequest setCurrentMode={setCurrentMode} setRegisterLoginAlert={setRegisterLoginAlert} />}
            {currentMode === "reset" && <ResetPass setCurrentMode={setCurrentMode} setRegisterLoginAlert={setRegisterLoginAlert} />}
            {/* </Box> */}
            </Container>
        </ThemeProvider>
    );
}

export default Sentry;