import { React, useState } from 'react';
import { useApolloClient, gql, useMutation } from '@apollo/client';
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Collapse from '@mui/material/Collapse';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

const GET_LOGIN = gql`
	mutation Login($veriu: String, $verip: String) {
		login(veriu: $veriu, verip: $verip) @connection(key: "login", filter: []) {
			id
			responseCode
			responseText
			username
            email
            usertype
            userstatus
			ctok
			hs
			hsclientid
			b2
			businessrid
		}
	}
`;

const USER_SENTRY = gql`
    query userSentryQuery {
        isLoggedIn @client
        cacheUserId @client
		cacheUserType @client
        cacheUserName @client
		hsConnected @client
		hsClientId @client
		b2Auth @client
		businessRid @client
    }
`;

export default function LogIn(props) {
	const { setCurrentMode, registerLoginAlert } = props;
	const client = useApolloClient();
	let navigate = useNavigate();

	const [alertOpen, setAlertOpen] = useState(false);

	const [loginUser, { loading }] = useMutation(GET_LOGIN, {
		onCompleted(data) {
			const responseCode = data?.login?.responseCode;

			if (responseCode === "200") {
				setAlertOpen(false);
				const ctok = data?.login?.ctok;
				const ses = data?.login?.responseText;
				const uid = data?.login?.id;
				localStorage.setItem('ctok', ctok);
				localStorage.setItem('ses', ses);
				localStorage.setItem('uid', uid);

				client.writeQuery({
					query: USER_SENTRY,
					data: {
						isLoggedIn: true,
						cacheUserId: data?.login?.id,
						cacheUserType: data?.login?.usertype,
						cacheUserName: data?.login?.username,
						hsConnected: data?.login?.hs,
						hsClientId: data?.login?.hsclientid,
						b2Auth: data?.login?.b2,
						businessRid: data?.login?.businessrid
					},
				});
				navigate("/");
			}
			else {
				setAlertOpen(true)
			}

		}
	});

	const handleSubmit = (event) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		const veriu = data.get('veriu');
		const verip = data.get('verip');
		if(veriu && verip && veriu.length > 1 && verip.length > 1){
			loginUser({ variables: { veriu: veriu, verip: verip } });
		}
	};

	return (
			<Box
				sx={{
					marginTop: '40px',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<Typography component="h1" variant="h5">Log In</Typography>

				{loading &&
					<Box sx={{ paddingTop: '20px', display: 'flex' }}>
						<CircularProgress />
					</Box>
				}

				<Collapse in={alertOpen}>
					<Alert severity="warning">
						<AlertTitle>Login Issue</AlertTitle>
						Your login credentials are invalid
					</Alert>
				</Collapse>

				{registerLoginAlert &&
				<Collapse in={true}>
					<Alert severity="success">
						<AlertTitle>Log In</AlertTitle>
						Please log in with your new credentials!
					</Alert>
				</Collapse>
				}

				<Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
					<TextField
						variant="standard"
						margin="normal"
						required
						fullWidth
						id="email"
						label="Username"
						name="veriu"
						autoComplete="veriu"
						autoFocus
					/>
					<TextField
						variant="standard"
						margin="normal"
						required
						fullWidth
						name="verip"
						label="Password"
						type="password"
						id="password"
					/>
					<Button type="submit" fullWidth variant="outlined" sx={{ mt: 3, mb: 2 }}>
						Sign In
					</Button>

					<Grid container>
						<Grid item xs sx={{ marginRight: 3, marginTop: 3 }}>
							<Button variant="outlined" size="small" fullWidth onClick={() => { setCurrentMode('resetrequest');navigate("/resetrequest"); }}>Forgot Password</Button>
						</Grid>
						<Grid item xs sx={{ marginLeft: 3, marginTop: 3 }}>
							<Button variant="outlined" size="small" fullWidth onClick={() => { setCurrentMode('signup');navigate("/signup"); }}>Create an Account</Button>
						</Grid>
					</Grid>
				</Box>
			</Box>
	);
}