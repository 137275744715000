import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { Save as SaveIcon } from 'lucide-react';

import DisbursementPanel from './DisbursementPanel';
import ManualPanel from './ManualPanel';
import EditPanel from './EditPanel';
import DeletePanel from './DeletePanel';

const SET_SWAGINVENTORY = gql`
    mutation ModifySwaginventory($rid: String, $swagRid: String, $status: String, $color: String, $size: String, $location: String, $quantity: String, $delete: String, $action: String, $note: String) {
        modifySwaginventory(rid: $rid, swagRid: $swagRid, status: $status, color: $color, size: $size, location: $location, quantity: $quantity, delete: $delete, action: $action, note: $note){
            responseCode
            responseText
            actionType
            objectType
            objectId
		}
	}
`;

const CREATE_DISBURSEMENT = gql`
    mutation CreateDisbursement($swagRid: String, $swaginventoryRid: String, $disbursetype: String, $clientRid: String, $personRid: String, $status: String, $destinationtype: String, $destination: String, $quantity: String, $note: String) {
        createDisbursement(swagRid: $swagRid, swaginventoryRid: $swaginventoryRid, disbursetype: $disbursetype, clientRid: $clientRid, personRid: $personRid, status: $status, destinationtype: $destinationtype, destination: $destination, quantity: $quantity, note: $note){
            responseCode
            responseText
            actionType
            objectType
            objectId
		}
	}
`;

const scrollToTop = () => { window.scrollTo({ top: 0, behavior: 'smooth' }); };

export default function AdjustForm(props) {
    const { data, navGoBack, navToUpdate, isMobile } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [serverErr, setServerErr] = useState(false);

    const [tabSelect, setTabSelect] = React.useState('disbursement');

    const handleTabChange = (event, newValue) => {
        setTabSelect(newValue);
    };

    const theme = useTheme();

    const data_swag_rid = data?.swaginventoryinfo[0]?.result?.swag?.rid;
    const data_swag_status = data?.swaginventoryinfo[0]?.result?.swag?.status;
    const data_swag_manufacturer = data?.swaginventoryinfo[0]?.result?.swag?.manufacturer;
    const data_swag_name = data?.swaginventoryinfo[0]?.result?.swag?.name;
    const data_swag_category = data?.swaginventoryinfo[0]?.result?.swag?.category;
    const data_swag_subcategory = data?.swaginventoryinfo[0]?.result?.swag?.subcategory;
    const data_rid = data?.swaginventoryinfo[0]?.result?.rid;
    const data_color = data?.swaginventoryinfo[0]?.result?.color;
    const data_size = data?.swaginventoryinfo[0]?.result?.size;
    const data_status = data?.swaginventoryinfo[0]?.result?.status;
    const data_location = data?.swaginventoryinfo[0]?.result?.location;
    const data_quantity = data?.swaginventoryinfo[0]?.result?.quantity;
    const data_locations = data?.swaginventoryinfo[0].locations;
    // const data_images = data?.clientdetail[0].images;

    // const imgSrc = "";

    const [errCode, errCodeSet] = useState(false);
    const [statusErr, statusErrSet] = useState(false);

    const [companyErr, companyErrSet] = useState(false);
    const [contactErr, contactErrSet] = useState(false);
    const [quantityErr, quantityErrSet] = useState(false);

    const [status, statusSet] = useState(data_status || "");
    // const handleChangestatus = (event) => {
    //     statusSet(event.target.value);
    // };

    const [confirmDelete, deleteSet] = useState("");
    // const handleChangedelete = (event) => {
    //     deleteSet(event.target.value);
    // };

    const [location, locationSet] = useState(data_location || "");
    // const handleChangelocation = (event) => {
    //     locationSet(event.target.value);
    // };

    const [quantity, quantitySet] = useState(data_quantity || "");
    // const handleChangequantity = (event) => {
    //     quantitySet(event.target.value);
    // };

    const [color, colorSet] = useState(data_color || "");

    const [size, sizeSet] = useState(data_size || "");

    const [disburseType, setdisburseType] = useState('');
    const [contact, contactSet] = useState('');
    const [company, companySet] = useState('');
    const [statusD, statusDSet] = useState('');
    const [destinationtype, destinationtypeSet] = useState('');
    const [destination, destinationSet] = useState('');
    const [quantityD, quantityDSet] = useState('');

    const [note, noteSet] = useState("");
    // const handleChangenote = (event) => {
    //     noteSet(event.target.value);
    // };

    const processForm = () => {
        if (tabSelect === "disbursement"){
            collectDisbursementForm();
        }
        else{
            collectFormData();
        }
    }

    const collectDisbursementForm = () => {
        const formData = {};
        let formValid = true;

        // swagRid
        // swaginventoryRid
        // disbursetype(contact or company)
        // company (client)
        // contact (person)
        // status
        // destinationtype
        // destination
        // quantity
        // note

        errCodeSet(false);
        setServerErr(false);
        companyErrSet(false);
        contactErrSet(false);
        quantityErrSet(false);

        if (!data_rid || data_rid === "") {
            formValid = false;
        }

        if (!company && !contact) {
            companyErrSet(true);
            contactErrSet(true);
            formValid = false;
        }

        if (!quantityD || parseInt(quantityD) < 1) {
            quantityErrSet(true);
            formValid = false;
        }

        if (formValid) {
            setServerErr(false);
            formData.swagRid = data_swag_rid;
            formData.swaginventoryRid = data_rid;
            formData.disbursetype = disburseType;
            formData.personRid = contact;
            formData.clientRid = company;
            formData.status = statusD;
            formData.destinationtype = destinationtype;
            formData.destination = destination;
            formData.quantity = quantityD;
            formData.note = note;

            setIsLoading(true);
            createDisbursement({ variables: { ...formData } });
        }
        else {
            scrollToTop();
            if (!errCode) {
                errCodeSet(true);
            }
        }

    }

    const collectFormData = () => {
        const formData = {};
        let formValid = true;

        errCodeSet(false);
        setServerErr(false);
        statusErrSet(false);

        if (!data_rid || data_rid === "") {
            formValid = false;
        }

        if (!status || status === "") {
            statusErrSet(true);
            formValid = false;
        }

        if (formValid) {
            setServerErr(false);
            formData.rid = data_rid;
            formData.swagRid = data_swag_rid;
            formData.status = status;
            formData.location = location;
            formData.quantity = quantity;
            formData.color = color;
            formData.size = size;

            if(tabSelect === "delete" && confirmDelete === "delete"){
                formData.delete = confirmDelete;
            }

            formData.note = note;
            formData.action = tabSelect;

            setIsLoading(true);
            setSwagInventory({ variables: { ...formData } });
        }
        else {
            scrollToTop();
            if (!errCode) {
                errCodeSet(true);
            }
        }

    }

    const [setSwagInventory] = useMutation(SET_SWAGINVENTORY, {
        onCompleted: (setData) => {
            setIsLoading(false);
            if (setData && setData?.modifySwaginventory?.responseCode === "200") {
                navToUpdate(data_swag_rid);
            }
            else {
                setServerErr(`There was an issue with your request.`);
                scrollToTop();
            }
        },
        onError: (setError) => {
            setIsLoading(false);
            setServerErr(`There was an error processing your request.`);
            scrollToTop();
        }
    });

    const [createDisbursement] = useMutation(CREATE_DISBURSEMENT, {
        onCompleted: (setData) => {
            setIsLoading(false);
            if (setData && setData?.createDisbursement?.responseCode === "200") {
                const rid = setData?.createDisbursement?.objectId;
                navToUpdate(rid);
            }
            else {
                setServerErr(`There was an issue with your request.`);
                scrollToTop();
            }
        },
        onError: (setError) => {
            setIsLoading(false);
            setServerErr(`There was an error processing your request.`);
            scrollToTop();
        }
    });

    // <Box>Show current</Box>
    // <Box>+ / - increment</Box>
    // <Box>Manual set int value input</Box>
    // <Box>If adjust prompt to add a note regarding quantity change</Box>
    // delete button, but qty must be 0

    return (
        <Container>
            {isLoading &&
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}><CircularProgress sx={{ color: '#c9fb00' }} thickness={1} size={60} /></Backdrop>
            }

            {serverErr &&
                <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ marginTop: '12px', marginBottom: '12px' }}>
                    <Grid item>
                        <Alert severity="error" variant="outlined">
                            <AlertTitle>Error</AlertTitle>
                            {serverErr}
                        </Alert>
                    </Grid>
                </Grid>
            }

            {errCode &&
                <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ marginTop: '12px', marginBottom: '12px' }}>
                    <Grid item>
                        <Alert severity="error" variant="outlined">
                            <AlertTitle>Error</AlertTitle>
                            Please resolve the form errors below!
                        </Alert>
                    </Grid>
                </Grid>
            }

            <Paper sx={{ width: '100%', padding: '20px', marginBottom: '3rem' }}>
                <Grid container>
                    <Grid item>
                        <Box sx={theme.customStyles.infoItem}>
                            <Box sx={theme.customStyles.infoItemTitle}>Category</Box>
                            <Box sx={theme.customStyles.infoItemContent}>{data_swag_category}</Box>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box sx={theme.customStyles.infoItem}>
                            <Box sx={theme.customStyles.infoItemTitle}>Sub category</Box>
                            <Box sx={theme.customStyles.infoItemContent}>{data_swag_subcategory}</Box>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box sx={theme.customStyles.infoItem}>
                            <Box sx={theme.customStyles.infoItemTitle}>Manufacturer</Box>
                            <Box sx={theme.customStyles.infoItemContent}>{data_swag_manufacturer}</Box>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box sx={theme.customStyles.infoItem}>
                            <Box sx={theme.customStyles.infoItemTitle}>Name</Box>
                            <Box sx={theme.customStyles.infoItemContent}>{data_swag_name}</Box>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item>
                        <Box sx={theme.customStyles.infoItem}>
                            <Box sx={theme.customStyles.infoItemTitle}>Status</Box>
                            <Box sx={theme.customStyles.infoItemContent}>{data_status}</Box>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box sx={theme.customStyles.infoItem}>
                            <Box sx={theme.customStyles.infoItemTitle}>Color</Box>
                            <Box sx={theme.customStyles.infoItemContent}>{data_color}</Box>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box sx={theme.customStyles.infoItem}>
                            <Box sx={theme.customStyles.infoItemTitle}>Size</Box>
                            <Box sx={theme.customStyles.infoItemContent}>{data_size}</Box>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box sx={theme.customStyles.infoItem}>
                            <Box sx={theme.customStyles.infoItemTitle}>Location</Box>
                            <Box sx={theme.customStyles.infoItemContent}>{data_location}</Box>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box sx={theme.customStyles.infoItem}>
                            <Box sx={theme.customStyles.infoItemTitle}>Listed Quantity</Box>
                            <Box sx={theme.customStyles.infoItemContent}>{data_quantity}</Box>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>

            <Paper sx={{ width: '100%', padding: '20px', marginBottom: '3rem' }}>
                <Container maxWidth="sm">
                <Box component="form">
                    <TabContext value={tabSelect}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleTabChange} aria-label="inventory adjust type" scrollButtons allowScrollButtonsMobile variant="scrollable">
                                <Tab label={isMobile ? `Disburse` : `Disbursement`} value="disbursement" />
                                <Tab label={isMobile ? `Adjust` : `Manual Adjustment`} value="manual" />
                                <Tab label={isMobile ? `Edit` : `Edit`} value="edit" />
                                <Tab label={isMobile ? `Delete` : `Delete`} value="delete" />
                            </TabList>
                        </Box>
                        <TabPanel value="disbursement">
                            <DisbursementPanel 
                                disburseType={disburseType} setdisburseType={setdisburseType} 
                                contact={contact} contactSet={contactSet} 
                                company={company} companySet={companySet} 
                                statusD={statusD} statusDSet={statusDSet} 
                                destinationtype={destinationtype} destinationtypeSet={destinationtypeSet} 
                                destination={destination} destinationSet={destinationSet} 
                                quantityD={quantityD} quantityDSet={quantityDSet} 
                                note={note} noteSet={noteSet}
                                companyErr={companyErr} contactErr={contactErr} quantityErr={quantityErr}
                            />
                        </TabPanel>
                        <TabPanel value="edit">
                            <EditPanel status={status} statusSet={statusSet} location={location} locationSet={locationSet} color={color} colorSet={colorSet} size={size} sizeSet={sizeSet} />
                        </TabPanel>
                        <TabPanel value="manual">
                            <ManualPanel assignedQty={data_quantity} quantity={quantity} quantitySet={quantitySet} note={note} noteSet={noteSet} />
                        </TabPanel>
                        <TabPanel value="delete">
                            <DeletePanel confirmDelete={confirmDelete} deleteSet={deleteSet} note={note} noteSet={noteSet} />
                        </TabPanel>
                    </TabContext>
                </Box>

                <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ marginTop: '20px' }}>
                    <Grid item>
                        <Button aria-label="cancel" variant="outlined" onClick={navGoBack}>Cancel</Button>
                    </Grid>
                    <Grid item>
                        <Button aria-label="save form" variant="outlined" onClick={processForm} endIcon={<SaveIcon strokeWidth={1} size={16} />}>Save</Button>
                    </Grid>
                </Grid>
                </Container>
            </Paper>

            {/* <Paper sx={{ width: '100%', padding: '20px', marginBottom: '3rem' }}>
                <Box component="form" sx={{ '& > :not(style)': { padding: '20px', paddingBottom: '30px' } }}>



                    <TextField variant="standard" id="status" label="Status" error={statusErr} value={status} onChange={handleChangestatus} select fullWidth>
                        {swagInventory.map((option) => (<MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>))}
                    </TextField>

                    <TextField variant="standard" id="location" label="Location" value={location} onChange={handleChangelocation} fullWidth />
                    <TextField variant="standard" id="quantity" label="Quantity" value={quantity} onChange={handleChangequantity} fullWidth />
                    <TextField variant="standard" id="note" label="Note" multiline rows={4} value={note} onChange={handleChangenote} fullWidth />

                </Box>

                <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ marginTop: '20px' }}>
                    <Grid item>
                        <Button aria-label="cancel" variant="outlined" onClick={navGoBack}>Cancel</Button>
                    </Grid>
                    <Grid item>
                        <Button aria-label="save form" variant="outlined" onClick={collectFormData} endIcon={<SaveIcon strokeWidth={1} size={16} />}>Save</Button>
                    </Grid>
                </Grid>

            </Paper> */}

        </Container>
    );
}