import React, { Fragment } from 'react';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import { Plus as PlusIcon } from 'lucide-react';

import { dateOptionsMidTime } from '../../utils/dateoptions';

export default function DetailDisplay(props) {
    const { data } = props;
    const theme = useTheme();

    const rid = data?.userdetail[0].result?.rid;
    const firstName = data?.userdetail[0].result.firstName;
    const lastName = data?.userdetail[0].result.lastName;
    const casedUsername = data?.userdetail[0].result.casedUsername;
    const email = data?.userdetail[0].result.email;
    const userType = data?.userdetail[0].result.userType;
    const lastLogin = data?.userdetail[0].result.lastLogin;
    const status = data?.userdetail[0].result.status;

    const imgSrc = "";

    const updatedFormatted = (new Date(lastLogin)).toLocaleDateString(undefined, dateOptionsMidTime);

    return (
        <Fragment>
            <Paper sx={{ width: '100%', padding: '20px', marginBottom: '3rem' }}>

                <Grid container direction="row" justifyContent="" alignItems="center">
                    <Grid item>
                        <Avatar alt="contact" sx={theme.customStyles.detailImg} />
                    </Grid>
                    <Grid item>
                        <Grid container>
                            <Grid item>
                                <Box sx={theme.customStyles.infoItem}>
                                    <Box sx={theme.customStyles.infoItemTitle}>First Name</Box>
                                    <Box sx={theme.customStyles.infoItemContent}>{firstName}</Box>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box sx={theme.customStyles.infoItem}>
                                    <Box sx={theme.customStyles.infoItemTitle}>Last Name</Box>
                                    <Box sx={theme.customStyles.infoItemContent}>{lastName}</Box>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box sx={theme.customStyles.infoItem}>
                                    <Box sx={theme.customStyles.infoItemTitle}>Username</Box>
                                    <Box sx={theme.customStyles.infoItemContent}>{casedUsername}</Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item>
                                <Box sx={theme.customStyles.infoItem}>
                                    <Box sx={theme.customStyles.infoItemTitle}>Status</Box>
                                    <Box sx={theme.customStyles.infoItemContent}>{status}</Box>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box sx={theme.customStyles.infoItem}>
                                    <Box sx={theme.customStyles.infoItemTitle}>Type</Box>
                                    <Box sx={theme.customStyles.infoItemContent}>{userType}</Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item>
                                <Box sx={theme.customStyles.infoItem}>
                                    <Box sx={theme.customStyles.infoItemTitle}>Email</Box>
                                    <Box sx={theme.customStyles.infoItemContent}>{email}</Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item>
                                <Box sx={theme.customStyles.infoItem}>
                                    <Box sx={theme.customStyles.infoItemTitle}>Last Login</Box>
                                    <Box sx={theme.customStyles.infoItemContent}>{updatedFormatted}</Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Paper>

        </Fragment>
    );
}