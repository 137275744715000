import {React, useEffect} from 'react';
import {useApolloClient,gql,useMutation} from '@apollo/client';
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

const SUBMIT_LOGOUT = gql`
	mutation Logout {
		logout {
            responseCode
            responseText
		}
	}
`;

export default function LogOut(props) {
    const {setPopLogout} = props;
    const client = useApolloClient();
    let navigate = useNavigate();

    const [logoutUser, { loading, data }] = useMutation(SUBMIT_LOGOUT);

	const remLocalStorage = () => {
		localStorage.setItem('ctok', '');
		localStorage.setItem('ses', '');
		localStorage.setItem('uid', '');
	}

    useEffect(() => {

        async function logoutUserAsync() {
			await remLocalStorage();
            await logoutUser();
            await client.resetStore();
            await setPopLogout(false);
        }
        logoutUserAsync();

    }, [logoutUser,client,setPopLogout]);

	return (
			<Box
				sx={{
					marginTop: '40px',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<Typography component="h1" variant="h5">Logged Out</Typography>

                {loading && 
					<Box sx={{ paddingTop: '20px', display: 'flex' }}>
						<CircularProgress />
					</Box>
				}

                {data &&
                    <Button fullWidth variant="outlined" sx={{ mt: 3, mb: 2 }} onClick={() => {navigate("/login");}}>
                        Click here to Log In
                    </Button>
                }

			</Box>
	);
}