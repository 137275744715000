import React, { useEffect, useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { ChevronLeft as ChevronLeftIcon, Pencil as PencilIcon } from 'lucide-react';

import DetailDisplay from './DetailDisplay';

const GET_NOTE = gql`
    query GetNoteDetail(
        $getRid: String
    ) {
        note(
            getRid: $getRid
        ) {
            result{
                rid
                severity
                notetype
                status
                title
                content
                client{
                    rid
                    name
                }
                person{
                    rid
                    firstName
                    lastName
                }
                swag{
                    rid
                    manufacturer
                    name
                }
                swaginventory{
                    rid
                    swag{
                        rid
                        manufacturer
                        name
                    }
                }
                disbursement{
                    rid
                    destinationtype
                    swag{
                        rid
                        manufacturer
                        name
                    }
                }        
            }
            history{
                action 
                created
                modData
                user{
                    rid
                    username
                }
            }
            responseType
            responseInfo
        }
    }
`;

export default function DetailNote(props) {
    const { isMobile } = props;
    const location = useLocation();
    const updateActionInit = location?.state?.modify || false;
    const [updateAction, setUpdateAction] = useState(false);

    let { rid } = useParams();

    let navigate = useNavigate();

    const [getDetail, { error, loading, data }] = useLazyQuery(
        GET_NOTE, { fetchPolicy: "network-only", variables: { "getRid": rid } }
    );

    useEffect(() => {
        getDetail();
    }, [rid]);

    useEffect(() => {
        setUpdateAction(updateActionInit);
    }, [updateActionInit]);

    const navGoBack = () => {
        navigate(-1);
    }

    const editBtnAction = () => {
        navigate(`/notes/modify/${rid}`)
    }

    return (
        <Box component="main" sx={{flexGrow: 1,height: '100vh',overflow: 'auto',}}>
        <Container sx={{ marginTop: '80px' }} maxWidth="xl">

            <Grid container direction="row" justifyContent="space-between" alignItems="flex-end" spacing={2} sx={{ marginBottom: '8px' }}>
                <Grid item>
                    {isMobile ?
                        <Button onClick={navGoBack} aria-label="go back"><ChevronLeftIcon strokeWidth={1} /></Button>
                        :
                        <Button onClick={navGoBack} aria-label="go back" startIcon={<ChevronLeftIcon strokeWidth={1} />}>Go Back</Button>
                    }
                </Grid>
                <Grid item md order={{ xs: 2, md: 3 }}>
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item>
                            {/* {isMobile ?
                                <Button aria-label="edit" variant="outlined" onClick={() => navigate(`/notes/modify/${rid}`)}><PencilIcon strokeWidth={1} /></Button>
                                :
                                <Button aria-label="edit" variant="outlined" onClick={() => navigate(`/notes/modify/${rid}`)} endIcon={<PencilIcon strokeWidth={1} size={14} />}>Edit Note</Button>
                            } */}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item order={{ xs: 2, md: 3 }}>
                    {isMobile ?
                        <Button aria-label="edit" variant="outlined" onClick={editBtnAction}><PencilIcon strokeWidth={1} /></Button>
                        :
                        <Button aria-label="edit" variant="outlined" onClick={editBtnAction} endIcon={<PencilIcon strokeWidth={1} size={14} />}>Edit Note</Button>
                    }
                    {/* {isMobile ?
                        <Button aria-label="add note" variant="outlined" onClick={navToNotes}><PlusIcon strokeWidth={1} /></Button>
                        :
                        <Button aria-label="add note" variant="outlined" onClick={navToNotes} endIcon={<PlusIcon strokeWidth={1} />}>Add Note</Button>
                    } */}
                </Grid>
            </Grid>

            {updateAction &&
                <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ marginTop: '12px', marginBottom: '12px' }}>
                    <Grid item>
                        <Alert severity="success" variant="outlined" onClose={() => {setUpdateAction(false);}}>
                            <AlertTitle>Note Modified</AlertTitle>
                            This note was {updateAction} successfully!
                        </Alert>
                    </Grid>
                </Grid>
            }

            {error ?
                <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ marginTop: '20%' }}>
                    <Grid item>
                        <Alert severity="error" variant="outlined">
                            <AlertTitle>Error</AlertTitle>
                            There was an error processing this request. Please try again or note us for support!
                        </Alert>
                    </Grid>
                </Grid>
                :
                loading ?
                    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
                        <CircularProgress sx={{ color: '#c9fb00' }} thickness={1} size={60} />
                    </Backdrop>
                    :
                    <DetailDisplay data={data} />
            }

        </Container>
        </Box>
    );
}