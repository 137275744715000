import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { gql, useMutation } from '@apollo/client';
import { useNavigate } from "react-router-dom";

const GET_REGISTER = gql`
	mutation Register($email: String, $username: String, $businessname: String, $pw: String) {
		register(email: $email, username: $username, businessname: $businessname, pw: $pw) @connection(key: "register", filter: []) {
			responseCode
			responseText
			username
		}
	}
`;

export default function SignUp(props) {
	let navigate = useNavigate();
    const {setCurrentMode, setRegisterLoginAlert} = props;
	const [errCode, setErrCode] = useState(false);
	const [serverError, setServerError] = useState(false);
	const [alertOpen, setAlertOpen] = useState(false);
	const [emailErr, setEmailErr] = useState(false);
	const [usernameErr, setUsernameErr] = useState(false);
	const [businessnameErr, setBusinessnameErr] = useState(false);
	const [pwErr, setPwErr] = useState(false);
	const [pwconfirmErr, setPwconfirmErr] = useState(false);

	const [registerUser, { loading }] = useMutation(GET_REGISTER, {
		onCompleted(data){
			setAlertOpen(false);
            const responseCode = data?.register?.responseCode;
			const responseText = data?.register?.responseText;
			if(responseCode === "200"){
				setRegisterLoginAlert(true);
				setCurrentMode('login');
				navigate("/login");
			}
			else{
				setErrCode("server");
				setServerError(responseText);
				setAlertOpen(true);
			}

		},
		onError: (registerError) => {
            console.log(`err -> ${registerError}`);
            setErrCode("server");
			setServerError(registerError);
            setAlertOpen(true);
        }
	});	  

	const handleSubmit = (event) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		
		const username = data.get('username');
		const email = data.get('email');
		const businessname = data.get('businessname');
		const pw = data.get('pw');
		const pwConfirm = data.get('pwConfirm');

		let formValid = true;

		if (!username || username === "" || username.length < 3){
			formValid = false;
			setUsernameErr(true);
		}
		else{
			setUsernameErr(false);
		}

		if (!email || email === ""){
			formValid = false;
			setEmailErr(true);
		}
		else{
			setEmailErr(false);
		}

		if (!businessname || businessname === ""){
			formValid = false;
			setBusinessnameErr(true);
		}
		else{
			setBusinessnameErr(false);
		}

		if (pw && formValid){
			const pwRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");

			if(!pwRegex.test(pw)){
				setPwErr(true);
				formValid = false;
			}
			else{
				setPwErr(false);
			}
		}
		else{
			formValid = false;
			setPwErr(true);
		}

		if (pw !== pwConfirm){
			formValid = false;
			setPwconfirmErr(true);
		}
		else{
			setPwconfirmErr(false);
		}

		if (formValid){
			registerUser({ variables: { username: data.get('username'), email: data.get('email'), businessname: data.get('businessname'), pw: data.get('pw') } });
		}
		else{
			if(!errCode){
				setErrCode("form");
				setAlertOpen(true);
				console.log(`form error`)
			}
		}

	};

	return (
			<Box
				sx={{
					marginTop: '40px',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<Typography component="h1" variant="h5">Sign Up</Typography>

				{loading && 
					<Box sx={{ paddingTop: '20px', display: 'flex' }}>
						<CircularProgress />
					</Box>
				}

				{errCode &&
					<Collapse in={alertOpen}>
                    <Alert severity="error" variant="outlined" sx={{marginBottom: '16px'}}>
                        <AlertTitle>Form Error</AlertTitle>
						{usernameErr && <Typography>You must enter a username with at least 3 characters.</Typography>}
                        {emailErr && <Typography>You must enter an email address.</Typography>}
						{businessnameErr && <Typography>You must include the name of your business!</Typography>}
                        {pwErr && <Typography>Password does not meet the requirements!</Typography>}
                        {pwconfirmErr && <Typography>Passwords do not match!</Typography>}
						{serverError && <Typography>{serverError}</Typography>}
                    </Alert>
					</Collapse>
                }

				<Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>

					<TextField
						variant="standard"
						type="email"
						margin="normal"
						error={emailErr}
						required
						fullWidth
						id="email"
						label="Email"
						name="email"
						autoComplete="email"
						autoFocus
						sx={{marginBottom:'20px'}}
					/>
					<TextField
						variant="standard"
						margin="normal"
						error={usernameErr}
						required
						fullWidth
						id="username"
						label="Username"
						name="username"
						autoComplete="username"
						helperText="Must contain at least: 5 characters"
						sx={{marginBottom:'20px'}}
					/>
					<TextField
						variant="standard"
						margin="normal"
						error={businessnameErr}
						required
						fullWidth
						id="businessname"
						label="Business Name"
						name="businessname"
						// helperText="Must contain at least: 5 characters"
						sx={{marginBottom:'20px'}}
					/>

					{/* <Box sx={{padding: '12px'}}> */}
					<TextField id="pw" 
						type="password"
						name="pw"
						variant="standard"
						label="Password" 
						required
						error={pwErr}
						helperText="Must contain at least: 8 characters, 1 lowecase, 1 uppercase, 1 number"
						fullWidth 
					/>
					<TextField id="pwConfirm" 
						type="password"
						name="pwConfirm"
						variant="standard"
						required
						label="Confirm Password" 
						error={pwconfirmErr}
						fullWidth 
					/>
                    {/* </Box> */}

					<Button type="submit" fullWidth variant="outlined" sx={{ mt: 3, mb: 2 }}>
						Sign Up
					</Button>

					<Grid container>
                        <Grid item xs sx={{ marginRight: 3, marginTop: 3 }}>
							<Button variant="outlined" size="small" fullWidth onClick={() => {setCurrentMode('login');navigate("/login");}}>Log In</Button>
						</Grid>
						<Grid item xs sx={{ marginLeft: 3, marginTop: 3 }}>
							<Button variant="outlined" size="small" fullWidth onClick={() => {setCurrentMode('resetrequest');navigate("/resetrequest");}}>Forgot Password</Button>
						</Grid>
					</Grid>
				</Box>
			</Box>
	);
}