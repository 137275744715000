const lightTheme = {

    //#ffd5af - #596886 - #052b4a (blue/gray with peach accent)
    //#155263 - #1e758d - #0c2f39 - #CBB26B - #d8c691 - #be9e45 (teal/navy with gold)

    // fontFamily: [
    //     'IBM Plex Sans',
    //     '"Source Sans 3"',
    //     '"Segoe UI"',
    //     'Open Sans',
    //     'Roboto',
    //     '"Helvetica Neue"',
    //     'Arial',
    //     'sans-serif',
    // ].join(','),

    palette: {
        mode: 'light',
        primary: {
            main: '#2d2e32',
        },
        background: {
            default: '#2d2e32'
        }
    },
    typography: {
        fontFamily: 'Open Sans, Source Sans Pro, IBM Plex Sans, Raleway, Arial',
        textDecoration: 'none'
    },
    customStyles: {
        drawerText: {
            fontSize: '14px',
            '&:hover': {
                color: '#fff',
            },
        },
        drawerIcon: {
            color: '#fff',
            size: '20',
            strokeWidth: '1.5'
        },
        appBarMenuItem: {
            fontSize: '14px',
            color: '#545454',
            paddingTop: '10px',
            paddingBottom: '10px',
            '&:hover': {
                color: '#000',
            },
        },
        appBarMenuIcon: {
            color: '#545454',
            size: '20',
            strokeWidth: '1.5',
            '&:hover': {
                color: '#000',
            },
        }
    },
    components: {
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    background: 'rgb(42,92,94)',
                    // background: 'linear-gradient(0deg, rgba(42,92,94,1) 0%, rgba(95,152,137,1) 100%)',
                    border: '0px'
                }
            }
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    color: '#fff',
                    textTransform: 'none',
                    borderLeft: '3px solid rgba(0, 0, 0, 0)',
                    '&:hover': {
                        background: '#2a5c5e',
                        borderLeft: '3px solid #fff'
                    },
                    "&.Mui-selected": {
                        background: '#21494b',
                        borderLeft: '3px solid #fff',
                        '&:hover': {
                            background: '#2a5c5e',
                            borderLeft: '3px solid #F9F9F9'
                        },
                    },
                },
            },
        },
        // MuiListItemIcon: {
        //     styleOverrides: {
        //         root: {
        //             color:'#fff'
        //         }
        //     }
        // },


    },
};

export default lightTheme;