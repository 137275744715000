import React, { useEffect, useState } from 'react';
import { gql, useQuery, useLazyQuery } from '@apollo/client';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { Plus as PlusIcon, ChevronLeft as ChevronLeftIcon, Pencil as PencilIcon } from 'lucide-react';

import DetailDisplay from './DetailDisplay';

const IMG_AUTH = gql`
    query userImgAuth {
        b2Auth @client
        businessRid @client
    }
`;

const GET_COMPANY = gql`
    query GetClientDetail(
        $getRid: String
    ) {
        clientdetail(
            getRid: $getRid
        ) {
            result{
                rid
                name
                website
                phoneNumber
                address
                addressTwo
                city
                stateRegion
                postalCode
                country
                crmId
                status
                images            
            }
            disbursements{
                rid
                swag{
                    rid
                    manufacturer
                    name
                }
                destinationtype
                destination
                status
                quantity
                updated
                updatedBy{
                    rid
                    username
                }
            }
            contacts{
                rid
                firstName
                lastName
                jobTitle
                status
            }
            notes{
                rid
                severity
                notetype
                status
                title
                content
                updated
                updatedBy{
                    rid
                    username
                }
            }
            responseType
            responseInfo
        }
    }
`;

export default function DetailContact(props) {
    const { isMobile } = props;
    const location = useLocation();
    const updateActionInit = location?.state?.modify || false;
    const updateActionMessage = location?.state?.message || "";
    const [updateAction, setUpdateAction] = useState(false);

    let { rid } = useParams();

    let navigate = useNavigate();

    const { data: cacheImgAuth } = useQuery(IMG_AUTH);
    const b2Auth = cacheImgAuth?.b2Auth;
    const businessRid = cacheImgAuth?.businessRid;

    const [getDetail, { error, loading, data }] = useLazyQuery(
        GET_COMPANY, { fetchPolicy: "network-only", variables: { "getRid": rid } }
    );

    useEffect(() => {
        getDetail();
    }, [rid]);

    useEffect(() => {
        setUpdateAction(updateActionInit);
    }, [updateActionInit]);

    const createNote = () => {
        navigate(`/notes/create/companies/${rid}`);
    }

    const createContact = () => {
        navigate(`/contacts/create/companies/${rid}`);
    }

    const navGoBack = () => {
        navigate(-1);
    }

    const noteDetail = (rid) => {
        navigate(`/notes/detail/${rid}`);
    }

    const contactDetail = (rid) => {
        navigate(`/contacts/detail/${rid}`);
    }

    const disbursementDetail = (rid) => {
        navigate(`/disbursements/detail/${rid}`);
    }

    const editBtnAction = () => {
        navigate(`/companies/modify/${rid}`)
    }

    return (
        <Box component="main" sx={{flexGrow: 1,height: '100vh',overflow: 'auto',}}>
        <Container sx={{ marginTop: '80px' }} maxWidth="xl">

            <Grid container direction="row" justifyContent="space-between" alignItems="flex-end" spacing={2} sx={{ marginBottom: '8px' }}>
                <Grid item>
                    {isMobile ?
                        <Button onClick={navGoBack} aria-label="go back"><ChevronLeftIcon strokeWidth={1} /></Button>
                        :
                        <Button onClick={navGoBack} aria-label="go back" startIcon={<ChevronLeftIcon strokeWidth={1} />}>Go Back</Button>
                    }
                </Grid>
                <Grid item md order={{ xs: 2, md: 3 }}>
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item>
                            {isMobile ?
                                <Button aria-label="edit" variant="outlined" onClick={editBtnAction}><PencilIcon strokeWidth={1} /></Button>
                                :
                                <Button aria-label="edit" variant="outlined" onClick={editBtnAction} endIcon={<PencilIcon strokeWidth={1} size={14} />}>Edit Company</Button>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item order={{ xs: 2, md: 3 }}>
                    {isMobile ?
                        <Button aria-label="add note" variant="outlined" onClick={createNote}><PlusIcon strokeWidth={1} /></Button>
                        :
                        <Button aria-label="add note" variant="outlined" onClick={createNote} endIcon={<PlusIcon strokeWidth={1} />}>Add Note</Button>
                    }
                </Grid>
            </Grid>

            {updateAction &&
                <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ marginTop: '12px', marginBottom: '12px' }}>
                    <Grid item>
                        <Alert severity="success" variant="outlined" onClose={() => {setUpdateAction(false);}}>
                            <AlertTitle>Company Modified</AlertTitle>
                            {updateActionMessage ? updateActionMessage : `This company was ${updateAction} successfully!`}
                        </Alert>
                    </Grid>
                </Grid>
            }

            {error ?
                <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ marginTop: '20%' }}>
                    <Grid item>
                        <Alert severity="error" variant="outlined">
                            <AlertTitle>Error</AlertTitle>
                            There was an error processing this request. Please try again or contact us for support!
                        </Alert>
                    </Grid>
                </Grid>
                :
                loading ?
                    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
                        <CircularProgress sx={{ color: '#c9fb00' }} thickness={1} size={60} />
                    </Backdrop>
                    :
                    <DetailDisplay data={data} noteDetail={noteDetail} contactDetail={contactDetail} createContact={createContact} disbursementDetail={disbursementDetail} createNote={createNote} isMobile={isMobile} b2Auth={b2Auth} businessRid={businessRid} />
            }

        </Container>
        </Box>
    );
}