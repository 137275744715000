import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { gql, useMutation } from '@apollo/client';
import { useNavigate } from "react-router-dom";

const GET_RESET = gql`
	mutation Reset($action: String, $email: String) {
		reset(action: $action, email: $email) @connection(key: "resetrequest", filter: []) {
			responseCode
			responseText
		}
	}
`;

export default function ResetPassRequest(props) {
	let navigate = useNavigate();
    const {setCurrentMode, setRegisterLoginAlert} = props;
	const [errCode, setErrCode] = useState(false);
	const [serverError, setServerError] = useState(false);
	const [alertOpen, setAlertOpen] = useState(false);
	const [emailErr, setEmailErr] = useState(false);

	const [resetPw, { loading }] = useMutation(GET_RESET, {
		onCompleted(data){
			setAlertOpen(false);
            const responseCode = data?.reset?.responseCode;
			const responseText = data?.reset?.responseText;

			if(responseCode === "200"){
				setRegisterLoginAlert(true);
				setCurrentMode('reset');
				navigate("/reset");
			}
			else{
				setErrCode("server");
				setServerError(responseText);
				setAlertOpen(true);
			}

		},
		onError: (resetError) => {
            console.log(`err -> ${resetError}`);
			const errorMessage = resetError?.message;
            setErrCode("server");
			setServerError(errorMessage);
            setAlertOpen(true);
        }
	});	  

	const handleSubmit = (event) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);

		const email = data.get('email');

		let formValid = true;

		if (!email || email === ""){
			formValid = false;
			setEmailErr(true);
		}
		else{
			setEmailErr(false);
		}

		if (formValid){
			resetPw({ variables: { action: 'request', email: data.get('email') } });
		}
		else{
			if(!errCode){
				setErrCode("form");
				setAlertOpen(true);
				console.log(`form error`)
			}
		}

	};

	return (
			<Box
				sx={{
					marginTop: '40px',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<Typography component="h1" variant="h5">Password Reset Request</Typography>

				{loading && 
					<Box sx={{ paddingTop: '20px', display: 'flex' }}>
						<CircularProgress />
					</Box>
				}

				{errCode &&
					<Collapse in={alertOpen}>
                    <Alert severity="error" variant="outlined" sx={{marginBottom: '16px'}}>
                        <AlertTitle>Form Error</AlertTitle>
                        {emailErr && <Typography>You must enter an email address.</Typography>}
						{serverError && <Typography>{serverError}</Typography>}
                    </Alert>
					</Collapse>
                }

					<Alert severity="info" variant="outlined" sx={{marginBottom: '16px', marginTop: '16px'}}>
                        <AlertTitle>Did you already receive an email with a request code?</AlertTitle>
						<Button variant="outlined" size="small" fullWidth onClick={() => {setCurrentMode('reset');navigate("/reset");}}>Click to Reset Password</Button>
                    </Alert>

				<Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>

					<TextField
						variant="standard"
						type="email"
						margin="normal"
						error={emailErr}
						required
						fullWidth
						id="email"
						label="Email"
						name="email"
						autoComplete="email"
						autoFocus
						sx={{marginBottom:'20px'}}
					/>

					<Button type="submit" fullWidth variant="outlined" sx={{ mt: 3, mb: 2 }}>
						Submit Reset Request
					</Button>

					<Grid container>
						<Grid item xs sx={{ marginRight: 3, marginTop: 3 }}>
							<Button variant="outlined" size="small" fullWidth onClick={() => {setCurrentMode('login');navigate("/login");}}>Log In</Button>
						</Grid>
						<Grid item xs sx={{ marginLeft: 3, marginTop: 3 }}>
							<Button variant="outlined" size="small" fullWidth onClick={() => {setCurrentMode('signup');navigate("/signup");}}>Create an Account</Button>
						</Grid>
					</Grid>
				</Box>
			</Box>
	);
}