import React, { useState, useEffect, Fragment } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Backdrop from '@mui/material/Backdrop';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';

import { swagInventoryStatus } from '../../utils/selectOptions';

const GET_LOCATIONS = gql`
    query GetLocationlist
        {
        locationslist
            {
            results
        }
    }
`;

export default function EditPanel(props) {
    const { navGoBack, navToUpdate, status, statusSet, location, locationSet, color, colorSet, size, sizeSet } = props;

    const [openLocation, setOpenLocation] = useState(false);
    const [optionsLocation, setOptionsLocation] = useState([]);

    const [errCode, errCodeSet] = useState(false);
    const [statusErr, statusErrSet] = useState(false);

    const [getLocationlist, { data: dataLocationlist, loading: loadingLocationList }] = useLazyQuery(
        GET_LOCATIONS, { fetchPolicy: "network-only" }
    );

    useEffect(() => {
        if (dataLocationlist) {
            const resultData = dataLocationlist?.locationslist?.[0]?.results;
            const quotedData = resultData.replace(/'/g, '"');
            let locList;
            try {
                locList = JSON.parse(quotedData);
            }
            catch {
                locList = [];
            }
            setOptionsLocation(locList);
        }
    }, [dataLocationlist]);

    useEffect(() => {
        if (openLocation && !dataLocationlist) { getLocationlist(); }
    }, [openLocation]);

    // const [status, statusSet] = useState(data_status || "");
    const handleChangestatus = (event) => {
        statusSet(event.target.value);
    };

    // const [color, colorSet] = useState(data_color || "");
    const handleChangecolor = (event) => {
        colorSet(event.target.value);
    };

    // const [size, sizeSet] = useState(data_size || "");
    const handleChangesize = (event) => {
        sizeSet(event.target.value);
    };

    // const [location, locationSet] = useState(data_location || "");
    const handleChangelocation = (value) => {
        locationSet(value);
    };



    return (
        // <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
        <Box>

            <TextField sx={{ marginTop: '30px' }} variant="standard" id="status" label="Status" error={statusErr} value={status} onChange={handleChangestatus} select fullWidth>
                {swagInventoryStatus.map((option) => (<MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>))}
            </TextField>

            <Autocomplete
                id="location"
                freeSolo
                autoSelect
                open={openLocation}
                onOpen={() => { setOpenLocation(true); }}
                onClose={() => { setOpenLocation(false); }}
                isOptionEqualToValue={(option, value) => option === value}
                getOptionLabel={(option) => option}
                options={optionsLocation}
                loading={loadingLocationList}
                renderOption={(props, option) => { return (<li {...props} key={option}>{option}</li>); }}
                value={location}
                onChange={(event, newValue) => {
                    handleChangelocation(newValue);
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Location"
                        variant="standard"
                        sx={{ marginTop: '30px' }}
                        fullWidth
                        InputProps={{
                            ...params.InputProps, endAdornment: (
                                <Fragment>
                                    {loadingLocationList ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </Fragment>
                            ),
                        }}
                    />
                )}
            />

            <TextField sx={{ marginTop: '30px' }} variant="standard" id="color" label="Color" value={color} onChange={handleChangecolor} fullWidth />
            <TextField sx={{ marginTop: '30px' }} variant="standard" id="size" label="Size" value={size} onChange={handleChangesize} fullWidth />
 
        </Box>
    );
}