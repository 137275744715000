import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Filler,
    LineElement,
    PointElement,
    Tooltip,
    Legend,
} from 'chart.js';

import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

export default function ChartTopValue(props) {
    const { dataCounts, dataValues, labels } = props;

    const options = {
        responsive: true,
        indexAxis: 'y',
        scales: {
            x: { 
                ticks: { color: 'white' }
            },
            y: { 
                ticks: { color: 'white' }
            }
        },
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: true,
                color: 'white',
                text: 'Top Disbursements - Value',
            },
        },
    };

    const data = {
        labels,
        datasets: [
            {
                fill: true,
                label: 'Quantity',
                data: dataCounts,
                borderColor: 'rgb(34,139,34)',
                backgroundColor: 'rgba(34,139,34, 1)',
            }
        ],
    };

    return (

        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', '& > *': { m: 1, }, }}>
                <Bar options={options} data={data} />
            </Box>
        </Paper>

    );
}