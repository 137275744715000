import React, { useEffect } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { useNavigate, useParams } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { ChevronLeft as ChevronLeftIcon, Pencil as PencilIcon } from 'lucide-react';

import DetailDisplay from './DetailDisplay';

const GET_DISBURSEMENT = gql`
    query GetDisbursement(
        $getRid: String
    ) {
        disbursementdetail(
            getRid: $getRid
        ) {
            result{
                rid
                status
                destinationtype
                destination
                client{
                    rid
                    name
                }
                person{
                    rid
                    persontype
                    firstName
                    lastName
                    jobTitle
                }
                quantity
                currency
                value
                updated
                swag{
                    rid
                    category
                    subcategory
                    manufacturer
                    name
                }
                swaginventory{
                    rid
                    color
                    size
                }
            }
            logs{
                user{
                    rid
                    username
                }
                action
                modData
                created
            }
            responseType
            responseInfo
        }
    }
`;

export default function DetailDisbursement(props) {
    const { isMobile } = props;
    let { rid } = useParams();
    let navigate = useNavigate();

    const [getDetail, { error, loading, data }] = useLazyQuery(
        GET_DISBURSEMENT, { fetchPolicy: "network-only", variables: { "getRid": rid } }
    );

    useEffect(() => {
        getDetail();
    }, [rid]);

    const navGoBack = () => {
        navigate(-1);
    }

    const editBtnAction = () => {
        navigate(`/inventory/disbursement/${rid}`)
    }

    return (
        <Box component="main" sx={{flexGrow: 1,height: '100vh',overflow: 'auto',}}>
        <Container sx={{ marginTop: '80px' }} maxWidth="xl">

            <Grid container direction="row" justifyContent="space-between" alignItems="flex-end" spacing={2} sx={{ marginBottom: '8px' }}>
                <Grid item>
                    {isMobile ?
                        <Button onClick={navGoBack} aria-label="go back"><ChevronLeftIcon strokeWidth={1} /></Button>
                        :
                        <Button onClick={navGoBack} aria-label="go back" startIcon={<ChevronLeftIcon strokeWidth={1} />}>Go Back</Button>
                    }
                </Grid>
                <Grid item>
                    {/* <Grid container justifyContent="center" alignItems="center"> */}
                        {/* <Grid item> */}
                            {isMobile ?
                                <Button aria-label="edit" variant="outlined" onClick={editBtnAction}><PencilIcon strokeWidth={1} /></Button>
                                :
                                <Button aria-label="edit" variant="outlined" onClick={editBtnAction} endIcon={<PencilIcon strokeWidth={1} size={14} />}>Edit Disbursement</Button>
                            }
                        {/* </Grid> */}
                    {/* </Grid> */}
                </Grid>
            </Grid>

            {error ?
                <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ marginTop: '20%' }}>
                    <Grid item>
                        <Alert severity="error" variant="outlined">
                            <AlertTitle>Error</AlertTitle>
                            There was an error processing this request. Please try again or contact us for support!
                        </Alert>
                    </Grid>
                </Grid>
                :
                loading ?
                    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
                        <CircularProgress sx={{ color: '#c9fb00' }} thickness={1} size={60} />
                    </Backdrop>
                    :
                    <DetailDisplay data={data} />
            }

        </Container>
        </Box>
    );
}