import React, { useState, useEffect, Fragment } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import { disbursementsStatus, disbursementsDestinationType } from '../../utils/selectOptions';

const GET_COMPANIES = gql`
    query GetCompanylist
        {
        clientslist
            {
            results{
                rid
                name
                status
            }
        }
    }
`;

const GET_CONTACTS = gql`
    query GetContactlist
        {
        persons
            {
            results{
                rid
                firstName
                lastName
                jobTitle
                status
                client{
                    rid
                    name
                }
            }
        }
    }
`;

export default function DisbursementPanel(props) {
    const { companyErr, contactErr, quantityErr, disburseType, setdisburseType, contact, contactSet, company, companySet, statusD, statusDSet, destination, destinationSet, destinationtype, destinationtypeSet, quantityD, quantityDSet, note, noteSet } = props;
    const [openCompany, setOpenCompany] = useState(false);
    const [optionsCompany, setOptionsCompany] = useState([]);
    const [openContact, setOpenContact] = useState(false);
    const [optionsContact, setOptionsContact] = useState([]);
    const [companyActive, setCompanyActive] = useState(false);
    const [contactActive, setContactActive] = useState(false);

    const handleChangenote = (event) => {
        noteSet(event.target.value);
    };

    const handleDisbursetype = (event) => {
        const selectedVal = event.target.value;
        if (selectedVal === "company") {
            setCompanyActive(true);
            setContactActive(false);
        }
        if (selectedVal === "contact") {
            setCompanyActive(false);
            setContactActive(true);
        }
        setdisburseType(selectedVal);
    };

    const handlequantitySet = (event) => {
        quantityDSet(event.target.value);
    };
    const handledestinationSet = (event) => {
        destinationSet(event.target.value);
    };
    const handledestinationtypeSet = (event) => {
        destinationtypeSet(event.target.value);
    };
    const handleChangestatus = (event) => {
        statusDSet(event.target.value);
    };
    const handleChangecompany = (event, newValue) => {
        companySet(newValue.rid);
    };
    const handleChangecontact = (event, newValue) => {
        contactSet(newValue.rid);
    };

    const [getCompanylist, { data: dataCompanylist, loading: loadingCompanyList }] = useLazyQuery(
        GET_COMPANIES, { fetchPolicy: "network-only" }
    );

    const [getContactlist, { data: dataContactlist, loading: loadingContactList }] = useLazyQuery(
        GET_CONTACTS, { fetchPolicy: "network-only" }
    );

    useEffect(() => {
        if (dataCompanylist) { setOptionsCompany(dataCompanylist?.clientslist?.[0]?.results); }
    }, [dataCompanylist]);

    useEffect(() => {
        if (openCompany && !dataCompanylist) { getCompanylist(); }
    }, [openCompany]);

    useEffect(() => {
        if (dataContactlist) { setOptionsContact(dataContactlist?.persons?.[0]?.results); }
    }, [dataContactlist]);

    useEffect(() => {
        if (openContact && !dataContactlist) { getContactlist(); }
    }, [openContact]);

    return (
        <Box>
            <FormControl sx={{ width: '100%' }}>
                <RadioGroup
                    aria-labelledby="disburse-type-radio-group"
                    name="disburse type radio group"
                    value={disburseType}
                    onChange={handleDisbursetype}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                        <Box>
                            <FormControlLabel value="company" control={<Radio />} label="Company" />
                        </Box>
                        <Box sx={{ flexGrow: 1 }}>
                            <Autocomplete
                                id="company"
                                disabled={companyActive ? false : true}
                                open={openCompany}
                                onOpen={() => { setOpenCompany(true); }}
                                onClose={() => { setOpenCompany(false); }}
                                isOptionEqualToValue={(option, value) => option?.rid === value?.rid}
                                getOptionLabel={(option) => option?.name || ""}
                                options={optionsCompany}
                                loading={loadingCompanyList}
                                onChange={(event, newValue) => {
                                    handleChangecompany(event, newValue)
                                }}
                                renderOption={(props, option) => { return (<li {...props} key={option?.rid}>{option?.name}</li>); }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Company"
                                        error={companyErr}
                                        InputProps={{
                                            ...params.InputProps, endAdornment: (
                                                <Fragment>
                                                    {loadingCompanyList ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </Fragment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                        <Box>
                            <FormControlLabel value="contact" control={<Radio />} label="Contact" />
                        </Box>
                        <Box sx={{ flexGrow: 1 }}>
                            <Autocomplete
                                id="contact"
                                disabled={contactActive ? false : true}
                                open={openContact}
                                onOpen={() => { setOpenContact(true); }}
                                onClose={() => { setOpenContact(false); }}
                                isOptionEqualToValue={(option, value) => option.rid === value.rid}
                                getOptionLabel={(option) => {
                                    const displayFirst = `${option?.firstName ? `${option?.firstName}` : ``}`;
                                    const displayLast = `${option?.lastName ? `${option?.lastName}` : ``}`;
                                    const displayName = `${(option?.lastName && option?.firstName) ? `${displayLast}, ${displayFirst}` : option?.lastName ? `${option?.lastName}` : `${option?.firstName}`}`;
                                    const displayTitle = `${option?.jobTitle ? `(${option?.jobTitle})` : ``}`;
                                    const displayClient = `${option?.client?.name ? ` - ${option?.client?.name}` : ``}`;
                                    const displaySelect = `${displayName} ${displayTitle} ${displayClient}`;
                                    return (displaySelect);
                                }}
                                options={optionsContact}
                                loading={loadingContactList}
                                renderOption={(props, option) => {
                                    const displayFirst = `${option?.firstName ? `${option?.firstName}` : ``}`;
                                    const displayLast = `${option?.lastName ? `${option?.lastName}` : ``}`;
                                    const displayName = `${(option?.lastName && option?.firstName) ? `${displayLast}, ${displayFirst}` : option?.lastName ? `${option?.lastName}` : `${option?.firstName}`}`;
                                    const displayTitle = `${option?.jobTitle ? `(${option?.jobTitle})` : ``}`;
                                    const displayClient = `${option?.client?.name ? ` - ${option?.client?.name}` : ``}`;
                                    const displaySelect = `${displayName} ${displayTitle} ${displayClient}`;
                                    return (<li {...props} key={option.rid}>{displaySelect}</li>);
                                }}
                                onChange={(event, newValue) => {
                                    handleChangecontact(event, newValue)
                                }}
                                
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Contact"
                                        error={contactErr}
                                        InputProps={{
                                            ...params.InputProps, endAdornment: (
                                                <Fragment>
                                                    {loadingContactList ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </Fragment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Box>
                    </Box>
                </RadioGroup>

                <TextField sx={{ marginTop: '20px' }} variant="standard" id="status" label="Status" value={statusD} onChange={handleChangestatus} select fullWidth>
                    {disbursementsStatus.map((option) => (<MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>))}
                </TextField>

                <TextField sx={{ marginTop: '20px' }} variant="standard" id="destinationtype" label="Destination Type" value={destinationtype} onChange={handledestinationtypeSet} select fullWidth>
                    {disbursementsDestinationType.map((option) => (<MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>))}
                </TextField>

                <TextField sx={{ marginTop: '20px' }} variant="standard" id="destination" label="Destination" value={destination} onChange={handledestinationSet} fullWidth />
                <TextField sx={{ marginTop: '20px' }} error={quantityErr} variant="standard" id="quantity" label="Quantity" type="number" value={quantityD} onChange={handlequantitySet} fullWidth />

                <TextField sx={{ marginTop: '20px' }} variant="outlined" id="note" label="Note" multiline rows={3} value={note} onChange={handleChangenote} fullWidth />
            </FormControl>

        </Box>
    );
}