import {React, useState} from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider, from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from '@apollo/client/link/context';
import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist';
// import { createTheme, ThemeProvider } from '@mui/material/styles';
import { createUploadLink } from 'apollo-upload-client';

// import lightTheme from './utils/theme/light';
// import darkTheme from './utils/theme/dark';
import Frame from './screens/Frame';

const App = () => {
    const [popLogout, setPopLogout] = useState(false);
    // const darkThemex = createTheme(darkTheme);
	// const theme = darkTheme;

	let linkVar;
	// if(process.env.NODE_ENV === 'production'){
	// 	linkVar = "https://api.swagmanager.com/graphql/"
	// }
	// else{
	// 	linkVar = "http://172.16.163.129:8088/graphql/";
	// }

	linkVar = "https://api.swagmanager.com/graphql/"

    const httpLink = createUploadLink({
        // uri: 'http://172.16.163.129:8088/graphql/',
		uri: linkVar,
        // package.json --> "proxy": "https://172.16.163.129:8083/graphql/",
        credentials: 'include'
    });

    //BEGIN NEW ERROR CATCH
	const errorLink = onError(({ graphQLErrors, networkError }) => {
		if (graphQLErrors)
			graphQLErrors.forEach(({ message, locations, path }) => {
				console.log(`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`,)
                if(message === "NOAUTH"){
					console.log(`pop-${popLogout}`);
                    setPopLogout(true);
                }
                }
			);

		if (networkError) console.log(`[Network error]: ${networkError}`);
	});
    //END NEW ERROR CATCH

    const authLink = setContext((_, { headers }) => {
		const readDataX = async () => {
            const ctok = localStorage.getItem('ctok');
            const ses = localStorage.getItem('ses');
			return {
				headers: {
					...headers,
					'X-CSRFToken': ctok,
					'Cookie': `csrftoken=${ctok}; sessionid=${ses}`
				}
			}
		}
		return readDataX()
	});

	const cache = new InMemoryCache({});

	const setupCache = async () => {
		await persistCache({
			cache,
			storage: new LocalStorageWrapper(window.localStorage),
		});
	}
	setupCache();

	const client = new ApolloClient({
		link: from([errorLink, authLink, httpLink]),
		cache
	});

	return (
        <ApolloProvider client={client}>
            {/* <ThemeProvider theme={darkThemex}> */}
                <Frame popLogout={popLogout} setPopLogout={setPopLogout} />
            {/* </ThemeProvider> */}
		</ApolloProvider>
    );
}

export default App;