import React, {useEffect} from 'react';
import { BrowserRouter, Routes, Route, Navigate, Outlet } from "react-router-dom";
import { gql, useQuery } from '@apollo/client';
// import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// import Box from '@mui/material/Box';
// import Backdrop from '@mui/material/Backdrop';
// import CircularProgress from '@mui/material/CircularProgress';

import Sentry from './Sentry';
import Layout from '../components/Layout/Layout';

import Dashboard from '../components/Dashboard/Dashboard';

import Inventory from '../components/Inventory/Inventory';
import DetailInventory from '../components/Inventory/DetailInventory';
import Adjust from '../components/Inventory/Adjust';
import ModifyDisbursement from '../components/Inventory/ModifyDisbursement';
import ModifySwag from '../components/Inventory/ModifySwag';
import CreateSwag from '../components/Inventory/CreateSwag';
import CreateInventory from '../components/Inventory/CreateInventory';

import Disbursements from '../components/Disbursements/Disbursements';
import DetailDisbursement from '../components/Disbursements/DetailDisbursement';

import Users from '../components/Users/Users';
import CreateUser from '../components/Users/CreateUser';
import ModifyUser from '../components/Users/ModifyUser';
import DetailUser from '../components/Users/DetailUser';

import Companies from '../components/Companies/Companies';
import CreateCompany from '../components/Companies/CreateCompany';
import ModifyCompany from '../components/Companies/ModifyCompany';
import DetailCompany from '../components/Companies/DetailCompany';

import Contacts from '../components/Contacts/Contacts';
import CreateContact from '../components/Contacts/CreateContact';
import ModifyContact from '../components/Contacts/ModifyContact';
import DetailContact from '../components/Contacts/DetailContact';
// import Users from '../components/Users/Users';
// import Financial from '../components/Financial/Financial';
import CreateNote from '../components/Notes/CreateNote';
import ModifyNote from '../components/Notes/ModifyNote';
import DetailNote from '../components/Notes/DetailNote';
import Settings from '../components/Settings/Settings';

const USER_SENTRY = gql`
    query userSentryQuery {
        isLoggedIn @client
        cacheUserId @client
        cacheUserName @client
        cacheUserType @client
    }
`;

const Frame = (props) => {
    const { popLogout, setPopLogout } = props;
    const isMobile = useMediaQuery('(max-width:900px)');
    const { data: cacheUserData, loading } = useQuery(USER_SENTRY);
    const isLoggedIn = cacheUserData?.isLoggedIn;
    const cacheUserName = cacheUserData?.cacheUserName;
    // const theme = useTheme();

    // useEffect(() => {
    //     console.log(`cacheUserData-UE: ${cacheUserData}`);
    // }, [cacheUserData]);

    // const RequireAuth = () => {
    //     if ((cacheUserData && !isLoggedIn) || popLogout || !cacheUserData) {
    //         return <Navigate to="/logout" />;
    //     }
    //     return <Outlet />;
    // }

    const RequireAuth = () => {
        // {loading && 
        //     <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}><CircularProgress sx={{ color: '#c9fb00' }} thickness={1} size={60} /></Backdrop>
        // }

        if ((cacheUserData && !isLoggedIn) || popLogout) {
            return <Navigate to="/logout" />;
        }
        return <Outlet />;
    }

    // b2_get_download_authorization
    // https://www.backblaze.com/b2/docs/b2_get_download_authorization.html
    // call once on login to get token and save
    // luckymallard/swag/clientrid/*
    // luckymallard/swag/clientrid/[company/contact/swag/logos]



    return (
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={<Sentry action="login" />} />
                <Route path="/resetrequest" element={<Sentry action="resetrequest" />} />
                <Route path="/reset" element={<Sentry action="reset" />} />
                <Route path="/signup" element={<Sentry action="signup" />} />
                <Route path="/logout" element={<Sentry action="logout" setPopLogout={setPopLogout} />} />
                {popLogout && <Route render={() => <Navigate to="/logout" />} />}

                <Route element={<RequireAuth />}>
                    <Route path="/" element={<Layout isMobile={isMobile} cacheUserName={cacheUserName} />}>
                        {/* <Route index element={<Sentry action="login" />} /> */}
                        <Route index element={<Dashboard />} />
                        <Route path="dashboard" element={<Dashboard />} />
                        <Route path="dashboard/*" element={<Navigate to="/dashboard" replace />} />

                        <Route path="inventory" element={<Inventory />} />
                        <Route path="inventory/detail/:rid" element={<DetailInventory isMobile={isMobile} />} />
                        <Route path="inventory/adjust/:rid" element={<Adjust isMobile={isMobile} />} />
                        <Route path="inventory/modify/:rid" element={<ModifySwag isMobile={isMobile} />} />
                        <Route path="inventory/create/swag" element={<CreateSwag isMobile={isMobile} />} />
                        <Route path="inventory/create/inventory/:rid" element={<CreateInventory isMobile={isMobile} />} />
                        <Route path="inventory/disbursement/:rid" element={<ModifyDisbursement isMobile={isMobile} />} />
                        <Route path="inventory/*" element={<Navigate to="/inventory" replace />} />

                        <Route path="disbursements" element={<Disbursements />} />
                        <Route path="disbursements/detail/:rid" element={<DetailDisbursement isMobile={isMobile} />} />

                        <Route path="companies" element={<Companies />} />
                        <Route path="companies/detail/:rid" element={<DetailCompany isMobile={isMobile} />} />
                        <Route path="companies/modify/:rid" element={<ModifyCompany isMobile={isMobile} />} />
                        <Route path="companies/create" element={<CreateCompany isMobile={isMobile} />} />
                        <Route path="companies/*" element={<Navigate to="/companies" replace />} />

                        <Route path="contacts" element={<Contacts />} />
                        <Route path="contacts/detail/:rid" element={<DetailContact isMobile={isMobile} />} />
                        <Route path="contacts/modify/:rid" element={<ModifyContact isMobile={isMobile} />} />
                        <Route path="contacts/create" element={<CreateContact isMobile={isMobile} />} />
                        <Route path="contacts/create/:type/:rid" element={<CreateContact isMobile={isMobile} />} />
                        <Route path="contacts/*" element={<Navigate to="/contacts" replace />} />

                        <Route path="notes/detail/:rid" element={<DetailNote isMobile={isMobile} />} />
                        <Route path="notes/modify/:rid" element={<ModifyNote isMobile={isMobile} />} />
                        <Route path="notes/create/:type/:rid" element={<CreateNote isMobile={isMobile} />} />
                        <Route path="notes/*" element={<Navigate to="/dashboard" replace />} />

                        <Route path="users" element={<Users />} />
                        <Route path="users/detail/:rid" element={<DetailUser isMobile={isMobile} />} />
                        <Route path="users/modify/:rid" element={<ModifyUser isMobile={isMobile} />} />
                        <Route path="users/create" element={<CreateUser isMobile={isMobile} />} />
                        <Route path="users/*" element={<Navigate to="/dashboard" replace />} />

                        {/* <Route path="disbursements" element={<Disbursements />} /> */}
                        {/* <Route path="disbursements/*" element={<Navigate to="/disbursements" replace />} /> */}
                        {/* <Route path="users" element={<Users />} /> */}
                        {/* <Route path="users/*" element={<Navigate to="/users" replace />} /> */}
                        {/* <Route path="financial" element={<Financial />} /> */}
                        {/* <Route path="financial/*" element={<Navigate to="/financial" replace />} /> */}
                        <Route path="settings" element={<Settings />} />
                        <Route path="settings/:code" element={<Settings />} />
                        <Route path="settings:code" element={<Settings />} />
                        <Route path="settings/*" element={<Navigate to="/settings" replace />} />
                        <Route path="mysettings" element={<Dashboard />} />
                        <Route path="mysettings/*" element={<Navigate to="/mysettings" replace />} />
                        <Route path="*" element={<Navigate to="/" replace />} />
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default Frame;