import React, { useState, useEffect } from 'react';
import { gql, useMutation } from '@apollo/client';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { Save as SaveIcon } from 'lucide-react';

import ImageDrop from '../common/ImageDrop';
import ImageList from '../common/ImageList';
import { personType, personStatus } from '../../utils/selectOptions';

const SET_CONTACT = gql`
	mutation ModifyPerson($rid: String, $firstName: String, $lastName: String, $persontype: String, $company: String, $email: String, $jobTitle: String, $phoneNumber: String, $address: String, $addressTwo: String, $city: String, $stateRegion: String, $postalCode: String, $country: String, $crmId: String, $status: String, $imgFile: Upload) {
		modifyPerson(rid: $rid, firstName: $firstName, lastName: $lastName, persontype: $persontype, company: $company, email: $email, jobTitle: $jobTitle, phoneNumber: $phoneNumber, address: $address, addressTwo: $addressTwo, city: $city, stateRegion: $stateRegion, postalCode: $postalCode, country: $country, crmId: $crmId, status: $status, imgFile: $imgFile){
            responseCode
            responseText
            actionType
            objectType
            objectId
		}
	}
`;

const scrollToTop = () =>{window.scrollTo({top: 0, behavior: 'smooth'});};

export default function ModifyForm(props) {
    const { data, dataCompanylist, navGoBack, navToUpdate, refetchQuery, b2Auth, businessRid } = props;
    const company_list = dataCompanylist?.clientslist[0].results || [];
    const [isLoading, setIsLoading] = useState(false);
    const [serverErr, setServerErr] = useState(false);

    const data_rid = data?.persondetail[0].result.rid;
    const data_clientRid = data?.persondetail[0].result.client?.rid;
    const data_persontype = data?.persondetail[0].result.persontype;
    const data_firstName = data?.persondetail[0].result.firstName;
    const data_lastName = data?.persondetail[0].result.lastName;
    const data_email = data?.persondetail[0].result.email;
    const data_jobTitle = data?.persondetail[0].result.jobTitle;
    const data_phoneNumber = data?.persondetail[0].result.phoneNumber;
    const data_address = data?.persondetail[0].result.address;
    const data_addressTwo = data?.persondetail[0].result.addressTwo;
    const data_city = data?.persondetail[0].result.city;
    const data_stateRegion = data?.persondetail[0].result.stateRegion;
    const data_postalCode = data?.persondetail[0].result.postalCode;
    const data_country = data?.persondetail[0].result.country;
    const data_crmId = data?.persondetail[0].result.crmId;
    const data_status = data?.persondetail[0].result.status;
    const foundImages = data?.persondetail[0].result?.images;

    const [displayImages, setDisplayImages] = useState(null);
    const [imgFile, setImgFile] = useState(false);

    useEffect(() => {
        if(foundImages && foundImages !== "[]"){
            const displayImagesPreSort = JSON.parse(foundImages);
            setDisplayImages(displayImagesPreSort.sort((a, b)=>{return a.displayOrder - b.displayOrder;}));
        }
    }, [foundImages]);

    const [errCode, errCodeSet] = useState(false);
    const [firstNameErr, firstNameErrSet] = useState(false);
    const [companyErr, companyErrSet] = useState(false);
    const [statusErr, statusErrSet] = useState(false);
    const [persontypeErr, persontypeErrSet] = useState(false);

    const [company, companySet] = useState(data_clientRid || '');
    const handleChangecompany = (event) => {
        companySet(event.target.value);
    };

    const [persontype, persontypeSet] = useState(data_persontype || '');
    const handleChangepersontype = (event) => {
        persontypeSet(event.target.value);
    };

    const [status, statusSet] = useState(data_status || '');
    const handleChangestatus = (event) => {
        statusSet(event.target.value);
    };

    const [firstName, firstNameSet] = useState(data_firstName || '');
    const handleChangefirstName = (event) => {
        firstNameSet(event.target.value);
    };

    const [lastName, lastNameSet] = useState(data_lastName || '');
    const handleChangelastName = (event) => {
        lastNameSet(event.target.value);
    };

    const [email, emailSet] = useState(data_email || '');
    const handleChangeemail = (event) => {
        emailSet(event.target.value);
    };

    const [jobTitle, jobTitleSet] = useState(data_jobTitle || '');
    const handleChangejobTitle = (event) => {
        jobTitleSet(event.target.value);
    };

    const [phoneNumber, phoneNumberSet] = useState(data_phoneNumber || '');
    const handleChangephoneNumber = (event) => {
        phoneNumberSet(event.target.value);
    };

    const [address, addressSet] = useState(data_address || '');
    const handleChangeaddress = (event) => {
        addressSet(event.target.value);
    };

    const [addressTwo, addressTwoSet] = useState(data_addressTwo || '');
    const handleChangeaddressTwo = (event) => {
        addressTwoSet(event.target.value);
    };

    const [city, citySet] = useState(data_city || '');
    const handleChangecity = (event) => {
        citySet(event.target.value);
    };

    const [stateRegion, stateRegionSet] = useState(data_stateRegion || '');
    const handleChangestateRegion = (event) => {
        stateRegionSet(event.target.value);
    };

    const [postalCode, postalCodeSet] = useState(data_postalCode || '');
    const handleChangepostalCode = (event) => {
        postalCodeSet(event.target.value);
    };

    const [country, countrySet] = useState(data_country || '');
    const handleChangecountry = (event) => {
        countrySet(event.target.value);
    };

    const [crmId, crmIdSet] = useState(data_crmId || '');
    const handleChangecrmId = (event) => {
        crmIdSet(event.target.value);
    };

    const collectFormData = () => {
        const formData = {};
        let formValid = true;

        errCodeSet(false);
        setServerErr(false);
        firstNameErrSet(false);
        companyErrSet(false);
        statusErrSet(false);
        persontypeErrSet(false);

        if(!data_rid || data_rid === ""){
            formValid = false;
        }

        if (!firstName || firstName === ""){
            firstNameErrSet(true);
            formValid = false;
        }

        if (!company || company === ""){
            companyErrSet(true);
            formValid = false;
        }

        if (!status || status === ""){
            statusErrSet(true);
            formValid = false;
        }

        if (!persontype || persontype === ""){
            persontypeErrSet(true);
            formValid = false;
        }

        if (formValid){
            setServerErr(false);
            formData.rid = data_rid;
            formData.firstName = firstName;
            formData.lastName = lastName;
            formData.persontype = persontype;
            formData.company = company;
            formData.email = email;
            formData.jobTitle = jobTitle;
            formData.phoneNumber = phoneNumber;
            formData.address = address;
            formData.addressTwo = addressTwo;
            formData.city = city;
            formData.stateRegion = stateRegion;
            formData.postalCode = postalCode;
            formData.country = country;
            formData.crmId = crmId;
            formData.status = status;
            formData.imgFile = imgFile[0];

            setIsLoading(true);
            setContact({variables:{...formData}});
        }
        else{
            scrollToTop();
            if(!errCode){
                errCodeSet(true);
            }
        }
        
    }

    const [setContact] = useMutation(SET_CONTACT, {
        onCompleted: (setData) => {
            setIsLoading(false);
            if (setData && setData?.modifyPerson?.responseCode === "200"){
                navToUpdate(data_rid);
            }
            else{
                setServerErr(`There was an issue with your request.`);
                scrollToTop();
            }
        },
        onError: (setError) => {
            setIsLoading(false);
            setServerErr(`There was an error processing your request.`);
            scrollToTop();
        }
    });

    return (
        <Container maxWidth="sm">            
            {isLoading && 
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}><CircularProgress sx={{color:'#c9fb00'}} thickness={1} size={60} /></Backdrop>
            }

            {serverErr &&
            <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ marginTop: '12px', marginBottom: '12px'}}>
                <Grid item>
                    <Alert severity="error" variant="outlined">
                        <AlertTitle>Error</AlertTitle>
                        {serverErr}
                    </Alert>
                </Grid>
            </Grid>
            }

            {errCode &&
            <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ marginTop: '12px', marginBottom: '12px'}}>
                <Grid item>
                    <Alert severity="error" variant="outlined">
                        <AlertTitle>Error</AlertTitle>
                        Please resolve the form errors below!
                    </Alert>
                </Grid>
            </Grid>
            }

            <Paper sx={{ width: '100%', padding: '20px', marginBottom: '3rem' }}>
                <Box component="form" sx={{ '& > :not(style)': { padding: '20px', paddingBottom: '30px' } }}>

                    <TextField variant="standard" id="firstName" label="First Name" error={firstNameErr} value={firstName} onChange={handleChangefirstName} fullWidth />
                    <TextField variant="standard" id="lastName" label="Last Name" value={lastName} onChange={handleChangelastName} fullWidth />

                    <TextField variant="standard" id="company" label="Company" error={companyErr} value={company} onChange={handleChangecompany} select fullWidth>
                        {company_list.map((option) => (<MenuItem key={option.rid} value={option.rid}>{option.name}</MenuItem>))}
                    </TextField>

                    <TextField variant="standard" id="persontype" label="Type" error={persontypeErr} value={persontype} onChange={handleChangepersontype} select fullWidth>
                        {personType.map((option) => (<MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>))}
                    </TextField>

                    <TextField variant="standard" id="crmId" label="CRM ID" value={crmId} onChange={handleChangecrmId} fullWidth />

                    <TextField variant="standard" id="status" label="Status" error={statusErr} value={status} onChange={handleChangestatus} select fullWidth>
                        {personStatus.map((option) => (<MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>))}
                    </TextField>

                    <TextField variant="standard" id="email" label="Email" value={email} onChange={handleChangeemail} fullWidth />
                    <TextField variant="standard" id="jobTitle" label="Job Title" value={jobTitle} onChange={handleChangejobTitle} fullWidth />
                    <TextField variant="standard" id="phoneNumber" label="Phone Number" value={phoneNumber} onChange={handleChangephoneNumber} fullWidth />
                    <TextField variant="standard" id="address" label="Address" value={address} onChange={handleChangeaddress} fullWidth />
                    <TextField variant="standard" id="addressTwo" label="Address Two" value={addressTwo} onChange={handleChangeaddressTwo} fullWidth />
                    <TextField variant="standard" id="city" label="City" value={city} onChange={handleChangecity} fullWidth />
                    <TextField variant="standard" id="stateRegion" label="State / Region" value={stateRegion} onChange={handleChangestateRegion} fullWidth />
                    <TextField variant="standard" id="postalCode" label="Postal Code" value={postalCode} onChange={handleChangepostalCode} fullWidth />
                    <TextField variant="standard" id="country" label="Country" value={country} onChange={handleChangecountry} fullWidth />
                
                </Box>

                {/* <Paper sx={{ width: '100%', padding: '20px', marginBottom: '20px' }}> */}
                <Box sx={{paddingTop:'20px',paddingBottom:'20px'}}>
                    <ImageList imgType="person" sourceTypeId={data_rid} images={displayImages} refetchQuery={refetchQuery} b2Auth={b2Auth} businessRid={businessRid} />
                    <ImageDrop setImgFile={setImgFile} />
                </Box>
                {/* </Paper> */}

                <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{marginTop: '20px'}}>
                    <Grid item>
                        <Button aria-label="cancel" variant="outlined" onClick={navGoBack}>Cancel</Button>
                    </Grid>
                    <Grid item>
                        <Button aria-label="save form" variant="outlined" onClick={collectFormData} endIcon={<SaveIcon strokeWidth={1} size={16} />}>Save Contact</Button>
                    </Grid>
                </Grid>

            </Paper>

        </Container>
    );
}