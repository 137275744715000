import React, {useEffect,useState} from 'react';
import { gql, useQuery, useLazyQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
// import Paper from '@mui/material/Paper';

import ChartTotal from './ChartTotal';
import ChartTopCount from './ChartTopCount';
import ChartTopValue from './ChartTopValue';

const GET_DASHBOARD = gql`
    query GetDashboard(
        $action: String
        $period: String
    ) {
        dashboard(
            action: $action
            period: $period
        ) {
            topCount
            topCountLabels
            topValue
            topValueLabels
            totalCount
            totalValue
            totalLabels
            action
            period
            responseType
            responseInfo
        }
    }
`;

export default function Dashboard() {

    const [getDashboard, { error, loading, data }] = useLazyQuery(
        GET_DASHBOARD, { fetchPolicy: "network-only" }
    );

    useEffect(() => {
        getDashboard({ variables: { "action": 'all', "period": 'month' } });
    }, []);

    const [period, setPeriod] = useState('month');

    const handleChangePeriod = (event, newPeriod) => {
        setPeriod(newPeriod);
        getDashboard({ variables: { "action": 'all', "period": newPeriod } });
    };

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
            }}
        >
            <Toolbar />
            <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                <Grid container justifyContent="center" spacing={3}>

                    <Grid item justify="center">
                    <ToggleButtonGroup
                        color="primary"
                        value={period}
                        exclusive
                        onChange={handleChangePeriod}
                        aria-label="Time Period"
                        size="small"
                    >
                        <ToggleButton value="month">This Month</ToggleButton>
                        <ToggleButton value="thisyear">This Year</ToggleButton>
                        <ToggleButton value="lastyear">Last Year</ToggleButton>
                        {/* <ToggleButton value="all">Prev. 3 Years</ToggleButton> */}
                    </ToggleButtonGroup>
                    </Grid>

                    <Grid item xs={12}>
                        {data?.dashboard?.[0]?.totalLabels && <ChartTotal dataValues={data?.dashboard?.[0]?.totalValue} dataCounts={data?.dashboard?.[0]?.totalCount} labels={data?.dashboard?.[0]?.totalLabels} />}
                    </Grid>

                    <Grid item xs={12} md={6}>
                        {data?.dashboard?.[0]?.topCountLabels && <ChartTopCount dataValues={data?.dashboard?.[0]?.topCount} dataCounts={data?.dashboard?.[0]?.topCount} labels={data?.dashboard?.[0]?.topCountLabels} />}
                    </Grid>

                    <Grid item xs={12} md={6}>
                        {data?.dashboard?.[0]?.topValueLabels && <ChartTopValue dataValues={data?.dashboard?.[0]?.topValue} dataCounts={data?.dashboard?.[0]?.topValue} labels={data?.dashboard?.[0]?.topValueLabels} />}
                    </Grid>

                </Grid>

            </Container>
        </Box>
    );
}