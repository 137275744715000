import React, { useState, useEffect } from 'react';
import { gql, useQuery, useLazyQuery } from '@apollo/client';
import { useNavigate } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { Plus as PlusIcon, XCircle as XCircleIcon } from 'lucide-react';

import DataTable from './DataTable';

const IMG_AUTH = gql`
    query userImgAuth {
        b2Auth @client
        businessRid @client
    }
`;

const GET_COMPANIES = gql`
    query GetClients(
        $searchKeyword: String
        $searchFilter: String
        $getPage: Int
        $getPageAction: String
        $sortField: String
        $sortDir: String
        $currentLimit: String
    ) {
        clients(
            searchKeyword: $searchKeyword
            searchFilter: $searchFilter
            getPage: $getPage
            getPageAction: $getPageAction
            sortField: $sortField
            sortDir: $sortDir
            currentLimit: $currentLimit
        ) {
            results{
                rid
                name
                status
                city
                country
                phoneNumber
                images
                lastDisbursement
                crmId
            }
            totalResults
            totalPages
            getPage
            currentLimit
        }
    }
`;

export default function Contacts() {
    const [currentLimit, setCurrentLimit] = useState(10);
    const [getPage, setGetPage] = useState(0);
    const [sort, setSort] = useState('name');
    const [sortDir, setSortDir] = useState('desc');
    const [keyword, setKeyword] = useState('');
    const [keywordText, setKeywordText] = useState('');
    let navigate = useNavigate();

    const theme = useTheme();

    const { data: cacheImgAuth } = useQuery(IMG_AUTH);
    const b2Auth = cacheImgAuth?.b2Auth;
    const businessRid = cacheImgAuth?.businessRid

    const [getCompanies, { error, loading, data }] = useLazyQuery(
        GET_COMPANIES, { fetchPolicy: "network-only" }
    );

    useEffect(() => {
        let sortDirVal = "-";
        if (sortDir !== "asc") { sortDirVal = ""; }
        getCompanies({ variables: { "getPage": (getPage + 1), "currentLimit": currentLimit, "sortField": sort, "sortDir": sortDirVal, "searchKeyword": keyword } });
    }, [currentLimit, getPage, sort, sortDir, keyword]);

    const handleKeyword = () => {
        setKeyword(keywordText);
    }

    const handleKeywordChange = (event) => {
        setKeywordText(event.target.value);
    }

    const handleClearKeyword = () => {
        setKeywordText('');
    }

    const navToCreate = () => {
        navigate(`/companies/create`);
    }

    return (
        <Box component="main" sx={{flexGrow: 1,height: '100vh',overflow: 'auto',}}>
        <Container sx={{ marginTop: '80px' }} maxWidth="xl">

            <Grid container direction="row" justifyContent="space-between" alignItems="flex-end" spacing={2} sx={{ marginBottom: '8px' }}>
                <Grid item>
                    <Typography variant="h5">Companies</Typography>
                </Grid>
                <Grid item xs={12} md order={{ xs: 3, md: 2 }}>
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item>
                            <TextField
                                id="search-keyword"
                                value={keywordText}
                                onChange={handleKeywordChange}
                                sx={theme.customStyles.searchKeyword}
                                aria-label="search keyword"
                                size="small"
                                InputProps={{
                                    'aria-label': 'search',
                                    endAdornment: keywordText ? (
                                        <IconButton onClick={handleClearKeyword}>
                                            <XCircleIcon strokeWidth={1} />
                                        </IconButton>
                                    ) : undefined
                                }}
                            />
                        </Grid>
                        <Grid item sx={{ marginLeft: '12px' }}>
                            <Button aria-label="search" variant="outlined" onClick={handleKeyword} >Search</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item order={{ xs: 2, md: 3 }}>
                    <Button aria-label="add company" variant="outlined" onClick={navToCreate} endIcon={<PlusIcon strokeWidth={1} />}>Add Company</Button>
                </Grid>
            </Grid>

            {error ?
                <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ marginTop: '20%' }}>
                    <Grid item>
                        <Alert severity="error" variant="outlined">
                            <AlertTitle>Error</AlertTitle>
                            There was an error processing this request. Please try again or contact us for support!
                        </Alert>
                    </Grid>
                </Grid>
                :
                <DataTable b2Auth={b2Auth} businessRid={businessRid} loading={loading} data={data} currentLimit={currentLimit} setCurrentLimit={setCurrentLimit} getPage={getPage} setGetPage={setGetPage} sort={sort} setSort={setSort} sortDir={sortDir} setSortDir={setSortDir} />

            }

        </Container>
        </Box>
    );
}