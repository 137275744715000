import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Filler,
    LineElement,
    PointElement,
    Tooltip,
    Legend,
} from 'chart.js';

import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

export default function ChartTotal(props) {
    const { dataCounts, dataValues, labels } = props;

    const options = {
        responsive: true,
        scales: {
            x: { 
                ticks: { color: 'white' }
            },
            y: { 
                ticks: { color: 'white' }
            }
        },
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                color: 'white',
                text: 'Total Disbursements',
            },
        },
    };

    const data = {
        labels,
        datasets: [
            {
                fill: false,
                label: 'Value',
                data: dataValues,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
            },
            {
                fill: true,
                label: 'Quantity',
                data: dataCounts,
                borderColor: 'rgb(54, 162, 235)',
                backgroundColor: 'rgb(54, 162, 235, 0.5)',
            }
        ],
    };

    return (

        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', '& > *': { m: 1, }, }}>
                <Line options={options} data={data} />
            </Box>
        </Paper>

    );
}