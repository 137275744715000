import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

export default function DeletePanel(props) {
    // const { assignedQty, quantity, quantitySet, navGoBack, navToUpdate, collectFormData } = props;
    const { note, noteSet, confirmDelete, deleteSet } = props;

    // const [note, noteSet] = useState("");
    const handleChangenote = (event) => {
        noteSet(event.target.value);
    };

    // const [confirmDelete, deleteSet] = useState("");
    const handleChangedelete = (event) => {
        deleteSet(event.target.value);
    };

    return (
        <Box>
            <Box sx={{ display: 'block', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>

                <Box>Note: <strong>Quantity will be manually adjusted to 0</strong></Box>

                <TextField sx={{marginTop:'20px', marginBottom:'20px'}} variant="standard" id="delete" label="Confirm Delete" value={confirmDelete} onChange={handleChangedelete} select fullWidth>
                    <MenuItem value="">Do not delete</MenuItem>
                    <MenuItem value="delete">Confirm delete</MenuItem>
                </TextField>

            </Box>
            <TextField sx={{marginTop:'20px'}} variant="outlined" id="note" label="Note" multiline rows={3} value={note} onChange={handleChangenote} fullWidth />
        </Box>
    );
}