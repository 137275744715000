import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { Save as SaveIcon } from 'lucide-react';

import { clientStatus } from '../../utils/selectOptions';

const CREATE_COMPANY = gql`
	mutation CreateClient($name: String, $website: String, $phoneNumber: String, $address: String, $addressTwo: String, $city: String, $stateRegion: String, $postalCode: String, $country: String, $crmId: String, $status: String, $imgFile: Upload) {
		createClient(name: $name, website: $website, phoneNumber: $phoneNumber, address: $address, addressTwo: $addressTwo, city: $city, stateRegion: $stateRegion, postalCode: $postalCode, country: $country, crmId: $crmId, status: $status, imgFile: $imgFile){
            responseCode
            responseText
            actionType
            objectType
            objectId
		}
	}
`;

const scrollToTop = () =>{window.scrollTo({top: 0, behavior: 'smooth'});};

export default function CreateForm(props) {
    const { navGoBack, navToUpdate } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [serverErr, setServerErr] = useState(false);

    const [errCode, errCodeSet] = useState(false);
    const [nameErr, nameErrSet] = useState(false);
    const [statusErr, statusErrSet] = useState(false);

    const [status, statusSet] = useState('');
    const handleChangestatus = (event) => {
        statusSet(event.target.value);
    };

    const [name, nameSet] = useState('');
    const handleChangename = (event) => {
        nameSet(event.target.value);
    };

    const [website, websiteSet] = useState('');
    const handleChangewebsite = (event) => {
        websiteSet(event.target.value);
    };

    const [phoneNumber, phoneNumberSet] = useState('');
    const handleChangephoneNumber = (event) => {
        phoneNumberSet(event.target.value);
    };

    const [address, addressSet] = useState('');
    const handleChangeaddress = (event) => {
        addressSet(event.target.value);
    };

    const [addressTwo, addressTwoSet] = useState('');
    const handleChangeaddressTwo = (event) => {
        addressTwoSet(event.target.value);
    };

    const [city, citySet] = useState('');
    const handleChangecity = (event) => {
        citySet(event.target.value);
    };

    const [stateRegion, stateRegionSet] = useState('');
    const handleChangestateRegion = (event) => {
        stateRegionSet(event.target.value);
    };

    const [postalCode, postalCodeSet] = useState('');
    const handleChangepostalCode = (event) => {
        postalCodeSet(event.target.value);
    };

    const [country, countrySet] = useState('');
    const handleChangecountry = (event) => {
        countrySet(event.target.value);
    };

    const [crmId, crmIdSet] = useState('');
    const handleChangecrmId = (event) => {
        crmIdSet(event.target.value);
    };

    const collectFormData = () => {
        const formData = {};
        let formValid = true;

        errCodeSet(false);
        setServerErr(false);
        nameErrSet(false);
        statusErrSet(false);
        
        if (!name || name === ""){
            nameErrSet(true);
            formValid = false;
        }

        if (!status || status === ""){
            statusErrSet(true);
            formValid = false;
        }

        if (formValid){
            setServerErr(false);
            formData.name = name;
            formData.website = website;
            formData.phoneNumber = phoneNumber;
            formData.address = address;
            formData.addressTwo = addressTwo;
            formData.city = city;
            formData.stateRegion = stateRegion;
            formData.postalCode = postalCode;
            formData.country = country;
            formData.crmId = crmId;
            formData.status = status;

            setIsLoading(true);
            createCompany({variables:{...formData}});
        }
        else{
            scrollToTop();
            if(!errCode){
                errCodeSet(true);
            }
        }
        
    }

    const [createCompany] = useMutation(CREATE_COMPANY, {
        onCompleted: (setData) => {
            setIsLoading(false);
            if (setData && setData?.createClient?.responseCode === "200"){
                const rid = setData?.createClient?.objectId;
                navToUpdate(rid);
            }
            else{
                setServerErr(`There was an issue with your request.`);
                scrollToTop();
            }
        },
        onError: (setError) => {
            setIsLoading(false);
            setServerErr(`There was an error processing your request.`);
            scrollToTop();
        }
    });

    return (
        <Container maxWidth="sm">            
            {isLoading && 
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}><CircularProgress sx={{color:'#c9fb00'}} thickness={1} size={60} /></Backdrop>
            }

            {serverErr &&
            <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ marginTop: '12px', marginBottom: '12px'}}>
                <Grid item>
                    <Alert severity="error" variant="outlined">
                        <AlertTitle>Error</AlertTitle>
                        {serverErr}
                    </Alert>
                </Grid>
            </Grid>
            }

            {errCode &&
            <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ marginTop: '12px', marginBottom: '12px'}}>
                <Grid item>
                    <Alert severity="error" variant="outlined">
                        <AlertTitle>Error</AlertTitle>
                        Please resolve the form errors below!
                    </Alert>
                </Grid>
            </Grid>
            }

            <Paper sx={{ width: '100%', padding: '20px', marginBottom: '3rem' }}>
                <Box component="form" sx={{ '& > :not(style)': { padding: '20px', paddingBottom: '30px' } }}>

                    <TextField variant="standard" id="name" label="Name" error={nameErr} value={name} onChange={handleChangename} fullWidth />

                    <TextField variant="standard" id="website" label="Website" value={website} onChange={handleChangewebsite} fullWidth />

                    <TextField variant="standard" id="crmId" label="CRM ID" value={crmId} onChange={handleChangecrmId} fullWidth />

                    <TextField variant="standard" id="status" label="Status" error={statusErr} value={status} onChange={handleChangestatus} select fullWidth>
                        {clientStatus.map((option) => (<MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>))}
                    </TextField>

                    <TextField variant="standard" id="phoneNumber" label="Phone Number" value={phoneNumber} onChange={handleChangephoneNumber} fullWidth />
                    <TextField variant="standard" id="address" label="Address" value={address} onChange={handleChangeaddress} fullWidth />
                    <TextField variant="standard" id="addressTwo" label="Address Two" value={addressTwo} onChange={handleChangeaddressTwo} fullWidth />
                    <TextField variant="standard" id="city" label="City" value={city} onChange={handleChangecity} fullWidth />
                    <TextField variant="standard" id="stateRegion" label="State / Region" value={stateRegion} onChange={handleChangestateRegion} fullWidth />
                    <TextField variant="standard" id="postalCode" label="Postal Code" value={postalCode} onChange={handleChangepostalCode} fullWidth />
                    <TextField variant="standard" id="country" label="Country" value={country} onChange={handleChangecountry} fullWidth />
                
                </Box>

                <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{marginTop: '20px'}}>
                    <Grid item>
                        <Button aria-label="cancel" variant="outlined" onClick={navGoBack}>Cancel</Button>
                    </Grid>
                    <Grid item>
                        <Button aria-label="save form" variant="outlined" onClick={collectFormData} endIcon={<SaveIcon strokeWidth={1} size={16} />}>Create Company</Button>
                    </Grid>
                </Grid>

            </Paper>

        </Container>
    );
}