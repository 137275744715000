import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { Save as SaveIcon } from 'lucide-react';

import { swagStatus, swagCategory, currencies } from '../../utils/selectOptions';

const CREATE_SWAG = gql`
    mutation CreateSwag($status: String, $manufacturer: String, $name: String, $category: String, $subcategory: String, $vendor: String, $description: String, $currency: String, $value: String, $imgFile: Upload) {
        createSwag(status: $status, manufacturer: $manufacturer, name: $name, category: $category, subcategory: $subcategory, vendor: $vendor, description: $description, currency: $currency, value: $value, imgFile: $imgFile){
            responseCode
            responseText
            actionType
            objectType
            objectId
		}
	}
`;

const scrollToTop = () =>{window.scrollTo({top: 0, behavior: 'smooth'});};

export default function CreateForm(props) {
    const { navGoBack, navToUpdate } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [serverErr, setServerErr] = useState(false);

    const [errCode, errCodeSet] = useState(false);
    const [statusErr, statusErrSet] = useState(false);
    const [categoryErr, categoryErrSet] = useState(false);
    const [subcategoryErr, subcategoryErrSet] = useState(false);
    const [nameErr, nameErrSet] = useState(false);

    const [status, statusSet] = useState("");
    const handleChangestatus = (event) => {
        statusSet(event.target.value);
    };

    const [category, categorySet] = useState("");
    const handleChangecategory = (event) => {
        categorySet(event.target.value);
    };

    const [subcategory, subcategorySet] = useState("");
    const handleChangesubcategory = (event) => {
        subcategorySet(event.target.value);
    };

    const [manufacturer, manufacturerSet] = useState("");
    const handleChangemanufacturer = (event) => {
        manufacturerSet(event.target.value);
    };

    const [name, nameSet] = useState("");
    const handleChangename = (event) => {
        nameSet(event.target.value);
    };

    const [vendor, vendorSet] = useState("");
    const handleChangevendor = (event) => {
        vendorSet(event.target.value);
    };

    const [description, descriptionSet] = useState("");
    const handleChangedescription = (event) => {
        descriptionSet(event.target.value);
    };

    const [currency, currencySet] = useState("");
    const handleChangecurrency = (event) => {
        currencySet(event.target.value);
    };

    const [value, valueSet] = useState("");
    const handleChangevalue = (event) => {
        valueSet(event.target.value);
    };

    const collectFormData = () => {
        const formData = {};
        let formValid = true;

        errCodeSet(false);
        setServerErr(false);
        statusErrSet(false);
        categoryErrSet(false);
        subcategoryErrSet(false);
        nameErrSet(false);
        
        if (!status || status === ""){
            statusErrSet(true);
            formValid = false;
        }

        if (!category || category === ""){
            categoryErrSet(true);
            formValid = false;
        }

        if (!subcategory || subcategory === ""){
            subcategoryErrSet(true);
            formValid = false;
        }

        if (!name || name === ""){
            nameErrSet(true);
            formValid = false;
        }

        if (formValid){
            setServerErr(false);
            formData.status = status;
            formData.category = category;
            formData.subcategory = subcategory;
            formData.manufacturer = manufacturer;
            formData.name = name;
            formData.vendor = vendor;
            formData.currency = currency;
            formData.value = value;
            formData.description = description;

            setIsLoading(true);
            createSwag({variables:{...formData}});
        }
        else{
            scrollToTop();
            if(!errCode){
                errCodeSet(true);
            }
        }
        
    }

    const [createSwag] = useMutation(CREATE_SWAG, {
        onCompleted: (setData) => {
            setIsLoading(false);
            if (setData && setData?.createSwag?.responseCode === "200"){
                const rid = setData?.createSwag?.objectId;
                navToUpdate(rid);
            }
            else{
                setServerErr(`There was an issue with your request.`);
                scrollToTop();
            }
        },
        onError: (setError) => {
            setIsLoading(false);
            setServerErr(`There was an error processing your request.`);
            scrollToTop();
        }
    });

    return (
        <Container maxWidth="sm">            
            {isLoading && 
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}><CircularProgress sx={{color:'#c9fb00'}} thickness={1} size={60} /></Backdrop>
            }

            {serverErr &&
            <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ marginTop: '12px', marginBottom: '12px'}}>
                <Grid item>
                    <Alert severity="error" variant="outlined">
                        <AlertTitle>Error</AlertTitle>
                        {serverErr}
                    </Alert>
                </Grid>
            </Grid>
            }

            {errCode &&
            <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ marginTop: '12px', marginBottom: '12px'}}>
                <Grid item>
                    <Alert severity="error" variant="outlined">
                        <AlertTitle>Error</AlertTitle>
                        Please resolve the form errors below!
                    </Alert>
                </Grid>
            </Grid>
            }

            <Paper sx={{ width: '100%', padding: '20px', marginBottom: '3rem' }}>
                <Box component="form" sx={{ '& > :not(style)': { padding: '20px', paddingBottom: '30px' } }}>

                    <TextField variant="standard" id="status" label="Status" error={statusErr} value={status} onChange={handleChangestatus} select fullWidth>
                        {swagStatus.map((option) => (<MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>))}
                    </TextField>

                    <TextField variant="standard" id="category" label="Category" error={categoryErr} value={category} onChange={handleChangecategory} select fullWidth>
                        {swagCategory.map((option) => (<MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>))}
                    </TextField>

                    <TextField variant="standard" id="subcategory" label="Sub category" error={subcategoryErr} value={subcategory} onChange={handleChangesubcategory} fullWidth />
                    <TextField variant="standard" id="manufacturer" label="Manufacturer" value={manufacturer} onChange={handleChangemanufacturer} fullWidth />
                    <TextField variant="standard" id="name" label="Name" error={nameErr} value={name} onChange={handleChangename} fullWidth />
                    <TextField variant="standard" id="vendor" label="Vendor" value={vendor} onChange={handleChangevendor} fullWidth />

                    <TextField variant="standard" id="currency" label="Currency" value={currency} onChange={handleChangecurrency} select fullWidth>
                        {currencies.map((option) => (<MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>))}
                    </TextField>

                    <TextField variant="standard" id="value" label="Value" type="number" value={value} onChange={handleChangevalue} />

                    <TextField variant="standard" id="description" label="Description" multiline rows={4} value={description} onChange={handleChangedescription} fullWidth />
                
                </Box>

                <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{marginTop: '20px'}}>
                    <Grid item>
                        <Button aria-label="cancel" variant="outlined" onClick={navGoBack}>Cancel</Button>
                    </Grid>
                    <Grid item>
                        <Button aria-label="save form" variant="outlined" onClick={collectFormData} endIcon={<SaveIcon strokeWidth={1} size={16} />}>Create Swag</Button>
                    </Grid>
                </Grid>

            </Paper>

        </Container>
    );
}