import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { gql, useMutation } from '@apollo/client';
import { useNavigate } from "react-router-dom";

const GET_RESET = gql`
	mutation Reset($action: String, $email: String, $token: String, $pw: String) {
		reset(action: $action, email: $email, token: $token, pw: $pw) @connection(key: "reset", filter: []) {
			responseCode
			responseText
		}
	}
`;

export default function ResetPass(props) {
	let navigate = useNavigate();
    const {setCurrentMode, setRegisterLoginAlert} = props;
	const [errCode, setErrCode] = useState(false);
	const [serverError, setServerError] = useState(false);
	const [alertOpen, setAlertOpen] = useState(false);
	const [emailErr, setEmailErr] = useState(false);
	const [codeErr, setCodeErr] = useState(false);
	const [pwErr, setPwErr] = useState(false);
	const [pwconfirmErr, setPwconfirmErr] = useState(false);

	const [resetPw, { loading }] = useMutation(GET_RESET, {
		onCompleted(data){
			setAlertOpen(false);
            const responseCode = data?.reset?.responseCode;
			const responseText = data?.reset?.responseText;

			if(responseCode === "200"){
				setRegisterLoginAlert(true);
				setCurrentMode('login');
				navigate("/login");
			}
			else{
				setErrCode("server");
				setServerError(responseText);
				setAlertOpen(true);
			}

		},
		onError: (resetError) => {
            console.log(`err -> ${resetError}`);
            setErrCode("server");
			setServerError(resetError);
            setAlertOpen(true);
        }
	});	  

	const handleSubmit = (event) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		
		const code = data.get('code');
		const email = data.get('email');
		const pw = data.get('pw');
		const pwConfirm = data.get('pwConfirm');

		let formValid = true;

		if (!code || code === ""){
			formValid = false;
			setCodeErr(true);
		}
		else{
			setCodeErr(false);
		}

		if (!email || email === ""){
			formValid = false;
			setEmailErr(true);
		}
		else{
			setEmailErr(false);
		}

		if (pw && formValid){
			const pwRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");

			if(!pwRegex.test(pw)){
				setPwErr(true);
				formValid = false;
			}
			else{
				setPwErr(false);
			}
		}
		else{
			formValid = false;
			setPwErr(true);
		}

		if (pw !== pwConfirm){
			formValid = false;
			setPwconfirmErr(true);
		}
		else{
			setPwconfirmErr(false);
		}

		if (formValid){
			resetPw({ variables: { action: 'reset', token: data.get('code'), email: data.get('email'), pw: data.get('pw') } });
		}
		else{
			if(!errCode){
				setErrCode("form");
				setAlertOpen(true);
				console.log(`form error`)
			}
		}

	};

	return (
			<Box
				sx={{
					marginTop: '40px',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<Typography component="h1" variant="h5">Password Reset</Typography>

				{loading && 
					<Box sx={{ paddingTop: '20px', display: 'flex' }}>
						<CircularProgress />
					</Box>
				}

				{errCode &&
					<Collapse in={alertOpen}>
                    <Alert severity="error" variant="outlined" sx={{marginBottom: '16px'}}>
                        <AlertTitle>Form Error</AlertTitle>
						{codeErr && <Typography>You must enter a reset code.</Typography>}
                        {emailErr && <Typography>You must enter an email address.</Typography>}
                        {pwErr && <Typography>Password does not meet the requirements!</Typography>}
                        {pwconfirmErr && <Typography>Passwords do not match!</Typography>}
						{serverError && <Typography>{serverError}</Typography>}
                    </Alert>
					</Collapse>
                }

				<Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>

					<TextField
						variant="standard"
						type="email"
						margin="normal"
						error={emailErr}
						required
						fullWidth
						id="email"
						label="Email"
						name="email"
						autoComplete="email"
						autoFocus
						sx={{marginBottom:'20px'}}
					/>
					<TextField
						variant="standard"
						margin="normal"
						error={codeErr}
						required
						fullWidth
						id="code"
						label="Reset Code"
						name="code"
						helperText="Paste code from reset email"
						sx={{marginBottom:'20px'}}
					/>

					{/* <Box sx={{padding: '12px'}}> */}
					<TextField id="pw" 
						type="password"
						name="pw"
						variant="standard"
						label="Password" 
						required
						error={pwErr}
						helperText="Must contain at least: 8 characters, 1 lowecase, 1 uppercase, 1 number"
						fullWidth 
					/>
					<TextField id="pwConfirm" 
						type="password"
						name="pwConfirm"
						variant="standard"
						required
						label="Confirm Password" 
						error={pwconfirmErr}
						fullWidth 
					/>
                    {/* </Box> */}

					<Button type="submit" fullWidth variant="outlined" sx={{ mt: 3, mb: 2 }}>
						Reset Password
					</Button>

					<Grid container>
						<Grid item xs sx={{ marginRight: 3, marginTop: 3 }}>
							<Button variant="outlined" size="small" fullWidth onClick={() => {setCurrentMode('login');navigate("/login");}}>Log In</Button>
						</Grid>
						<Grid item xs sx={{ marginLeft: 3, marginTop: 3 }}>
							<Button variant="outlined" size="small" fullWidth onClick={() => {setCurrentMode('signup');navigate("/signup");}}>Create an Account</Button>
						</Grid>
					</Grid>
				</Box>
			</Box>
	);
}