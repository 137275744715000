import React, { useState, useEffect, Fragment } from 'react';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { Save as SaveIcon } from 'lucide-react';

import { dateOptionsMidTime } from '../../utils/dateoptions';
import { disbursementsStatus, disbursementsDestinationType, currencies } from '../../utils/selectOptions';

const GET_COMPANIES = gql`
    query GetCompanylist
        {
        clientslist
            {
            results{
                rid
                name
                status
            }
        }
    }
`;

const GET_CONTACTS = gql`
    query GetContactlist
        {
        persons
            {
            results{
                rid
                firstName
                lastName
                jobTitle
                status
                client{
                    rid
                    name
                }
            }
        }
    }
`;

const SET_DISBURSEMENT = gql`
    mutation ModifyDisbursement($rid: String, $disbursetype: String, $clientRid: String, $personRid: String, $status: String, $destinationtype: String, $destination: String, $currency: String, $value: String, $quantity: String, $note: String) {
        modifyDisbursement(rid: $rid, disbursetype: $disbursetype, clientRid: $clientRid, personRid: $personRid, status: $status, destinationtype: $destinationtype, destination: $destination, currency: $currency, value: $value, quantity: $quantity, note: $note){
            responseCode
            responseText
            actionType
            objectType
            objectId
		}
	}
`;

const scrollToTop = () => { window.scrollTo({ top: 0, behavior: 'smooth' }); };

export default function ModifyDisbursementForm(props) {
    const { data, navGoBack, navToUpdate } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [serverErr, setServerErr] = useState(false);
    const [companyErr, companyErrSet] = useState(false);
    const [contactErr, contactErrSet] = useState(false);
    const [quantityErr, quantityErrSet] = useState(false);

    const theme = useTheme();

    const data_rid = data?.disbursement[0].result?.rid;
    const data_status = data?.disbursement[0].result?.status;
    const data_destinationtype = data?.disbursement[0].result?.destinationtype;
    const data_destination = data?.disbursement[0].result?.destination;
    const data_quantity = data?.disbursement[0].result?.quantity;
    const data_currency = data?.disbursement[0].result?.currency;
    const data_value = data?.disbursement[0].result?.value;
    const data_client_rid = data?.disbursement[0].result?.client?.rid;
    const data_client_name = data?.disbursement[0].result?.client?.name;
    const data_person_rid = data?.disbursement[0].result?.person?.rid;
    const data_person_persontype = data?.disbursement[0].result?.person?.persontype;
    const data_person_firstName = data?.disbursement[0].result?.person?.firstName;
    const data_person_lastName = data?.disbursement[0].result?.person?.lastName;
    const data_person_jobTitle = data?.disbursement[0].result?.person?.jobTitle;
    const data_category = data?.disbursement[0].result?.swag?.category;
    const data_subcategory = data?.disbursement[0].result?.swag?.subcategory;
    const data_manufacturer = data?.disbursement[0].result?.swag?.manufacturer;
    const data_name = data?.disbursement[0].result?.swag?.name;
    const data_swagcategory = `${data_category} - ${data_subcategory}`;
    const data_swag = `${data_manufacturer} - ${data_name}`;

    const displayContactObj = { rid: data_person_rid, firstName: data_person_firstName, lastName: data_person_lastName, jobTitle: data_person_jobTitle, client: { name: data_client_name, rid: data_client_rid } }

    const data_updated = data?.disbursement[0].result.updated;
    const updatedFormatted = (new Date(data_updated)).toLocaleDateString(undefined, dateOptionsMidTime);

    const [errCode, errCodeSet] = useState(false);
    const [assignedErr, assignedErrSet] = useState(false);
    const [statusErr, statusErrSet] = useState(false);
    const [destinationtypeErr, destinationtypeErrSet] = useState(false);

    const [openCompany, setOpenCompany] = useState(false);
    const [optionsCompany, setOptionsCompany] = useState([]);
    const [openContact, setOpenContact] = useState(false);
    const [optionsContact, setOptionsContact] = useState([]);
    const [companyActive, setCompanyActive] = useState(false);
    const [contactActive, setContactActive] = useState(false);

    const [contact, contactSet] = useState(displayContactObj || {});
    const [company, companySet] = useState({ rid: data_client_rid, name: data_client_name } || {});
    const [status, statusSet] = useState(data_status || '');
    const [destinationtype, destinationtypeSet] = useState(data_destinationtype || '');
    const [destination, destinationSet] = useState(data_destination || '');
    const [quantity, quantitySet] = useState(data_quantity || '');
    const [currency, currencySet] = useState(data_currency || '');
    const [value, valueSet] = useState(data_value || '');

    const [note, noteSet] = useState("");

    const handleChangenote = (event) => {
        noteSet(event.target.value);
    };

    const handlequantitySet = (event) => {
        quantitySet(event.target.value);
    };
    const handledestinationSet = (event) => {
        destinationSet(event.target.value);
    };
    const handledestinationtypeSet = (event) => {
        destinationtypeSet(event.target.value);
    };
    const handleChangestatus = (event) => {
        statusSet(event.target.value);
    };
    const handleChangecompany = (event, newValue) => {
        companySet(newValue);
    };
    const handleChangecontact = (event, newValue) => {
        contactSet(newValue);
    };
    const handleChangecurrency = (event) => {
        currencySet(event.target.value);
    }
    const handleChangevalue = (event) => {
        valueSet(event.target.value);
    };

    const [getCompanylist, { data: dataCompanylist, loading: loadingCompanyList }] = useLazyQuery(
        GET_COMPANIES, { fetchPolicy: "network-only" }
    );

    const [getContactlist, { data: dataContactlist, loading: loadingContactList }] = useLazyQuery(
        GET_CONTACTS, { fetchPolicy: "network-only" }
    );

    useEffect(() => {
        if (dataCompanylist) { setOptionsCompany(dataCompanylist?.clientslist?.[0]?.results); }
    }, [dataCompanylist]);

    useEffect(() => {
        if (openCompany && !dataCompanylist) { getCompanylist(); }
    }, [openCompany]);

    useEffect(() => {
        if (dataContactlist) { setOptionsContact(dataContactlist?.persons?.[0]?.results); }
    }, [dataContactlist]);

    useEffect(() => {
        if (openContact && !dataContactlist) { getContactlist(); }
    }, [openContact]);

    const collectFormData = () => {
        const formData = {};
        let formValid = true;

        errCodeSet(false);
        setServerErr(false);
        statusErrSet(false);
        destinationtypeErrSet(false);
        assignedErrSet(false);

        if (!data_rid || data_rid === "") {
            formValid = false;
        }

        if (!status || status === "") {
            statusErrSet(true);
            formValid = false;
        }

        if ((!company?.rid || company?.rid === "") && (!contact?.rid || contact?.rid === "")){
            assignedErrSet(true);
            formValid = false;
        }

        if (!destinationtype || destinationtype === "") {
            destinationtypeErrSet(true);
            formValid = false;
        }

        if (formValid) {
            setServerErr(false);
            formData.rid = data_rid;
            formData.status = status;
            formData.destinationtype = destinationtype;
            formData.destination = destination;
            formData.quantity = quantity;
            formData.currency = currency;
            formData.value = value;
            formData.note = note;
            formData.personRid = contact?.rid;
            formData.clientRid = company?.rid;

            setIsLoading(true);
            setDisbursement({ variables: { ...formData } });
        }
        else {
            scrollToTop();
            if (!errCode) {
                errCodeSet(true);
            }
        }

    }

    const [setDisbursement] = useMutation(SET_DISBURSEMENT, {
        onCompleted: (setData) => {
            setIsLoading(false);
            if (setData && setData?.modifyDisbursement?.responseCode === "200") {
                const navToRid = setData?.modifyDisbursement?.objectId;
                navToUpdate(navToRid);
            }
            else {
                setServerErr(`There was an issue with your request.`);
                scrollToTop();
            }
        },
        onError: (setError) => {
            setIsLoading(false);
            setServerErr(`There was an error processing your request.`);
            scrollToTop();
        }
    });

    return (
        <Container maxWidth="sm">
            {isLoading &&
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}><CircularProgress sx={{ color: '#c9fb00' }} thickness={1} size={60} /></Backdrop>
            }

            {serverErr &&
                <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ marginTop: '12px', marginBottom: '12px' }}>
                    <Grid item>
                        <Alert severity="error" variant="outlined">
                            <AlertTitle>Error</AlertTitle>
                            {serverErr}
                        </Alert>
                    </Grid>
                </Grid>
            }

            {errCode &&
                <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ marginTop: '12px', marginBottom: '12px' }}>
                    <Grid item>
                        <Alert severity="error" variant="outlined">
                            <AlertTitle>Error</AlertTitle>
                            Please resolve the form errors below!
                            {assignedErr && <Box>You must select a Company or a Contact!</Box>}
                        </Alert>
                    </Grid>
                </Grid>
            }

            <Paper>
                <Box sx={theme.customStyles.infoItem}>
                    <Box sx={theme.customStyles.infoItemTitle}>Swag Category</Box>
                    <Box sx={theme.customStyles.infoItemContent}>{data_swagcategory}</Box>
                </Box>
                <Box sx={theme.customStyles.infoItem}>
                    <Box sx={theme.customStyles.infoItemTitle}>Swag</Box>
                    <Box sx={theme.customStyles.infoItemContent}>{data_swag}</Box>
                </Box>
                <Box sx={theme.customStyles.infoItem}>
                    <Box sx={theme.customStyles.infoItemTitle}>Updated</Box>
                    <Box sx={theme.customStyles.infoItemContent}>{updatedFormatted}</Box>
                </Box>
            </Paper>

            <Paper sx={{ width: '100%', padding: '20px', marginBottom: '3rem' }}>
                <Alert severity="info" variant="outlined">
                    <AlertTitle>Quantity Adjustment Note</AlertTitle>
                    Modifying quanity here will <strong>not</strong> adjust the inventory totals! You will need to adjust manually if necessary.
                </Alert>
                <FormControl sx={{ width: '100%' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                        <Box sx={{ flexGrow: 1 }}>
                            <Autocomplete
                                id="company"
                                open={openCompany}
                                onOpen={() => { setOpenCompany(true); }}
                                onClose={() => { setOpenCompany(false); }}
                                isOptionEqualToValue={(option, value) => option.rid === value.rid}
                                getOptionLabel={(option) => {
                                    let displayLabel = option?.name;
                                    if (!displayLabel || displayLabel === undefined || !option?.rid) {
                                        displayLabel = "";
                                    }
                                    return (displayLabel)
                                }}
                                options={optionsCompany}
                                loading={loadingCompanyList}
                                onChange={(event, newValue) => {
                                    handleChangecompany(event, newValue)
                                }}
                                value={company || null}
                                renderOption={(props, option) => { return (<li {...props} key={option.rid}>{option.name}</li>); }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Company"
                                        error={companyErr}
                                        InputProps={{
                                            ...params.InputProps, endAdornment: (
                                                <Fragment>
                                                    {loadingCompanyList ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </Fragment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                        <Box sx={{ flexGrow: 1 }}>
                            <Autocomplete
                                id="contact"
                                open={openContact}
                                onOpen={() => { setOpenContact(true); }}
                                onClose={() => { setOpenContact(false); }}
                                isOptionEqualToValue={(option, value) => option.rid === value.rid}
                                getOptionLabel={(option) => {
                                    const displayFirst = `${option?.firstName ? `${option?.firstName}` : ``}`;
                                    const displayLast = `${option?.lastName ? `${option?.lastName}` : ``}`;
                                    const displayName = `${(option?.lastName && option?.firstName) ? `${displayLast}, ${displayFirst}` : option?.lastName ? `${option?.lastName}` : `${option?.firstName}`}`;
                                    const displayTitle = `${option?.jobTitle ? `(${option?.jobTitle})` : ``}`;
                                    const displayClient = `${option?.client?.name ? ` - ${option?.client?.name}` : ``}`;
                                    let displaySelect = `${displayName} ${displayTitle} ${displayClient}`;
                                    if (!displaySelect || displaySelect === undefined || !option?.rid) {
                                        displaySelect = "";
                                    }
                                    return (displaySelect);
                                }}
                                options={optionsContact}
                                loading={loadingContactList}
                                renderOption={(props, option) => {
                                    const displayFirst = `${option?.firstName ? `${option?.firstName}` : ``}`;
                                    const displayLast = `${option?.lastName ? `${option?.lastName}` : ``}`;
                                    const displayName = `${(option?.lastName && option?.firstName) ? `${displayLast}, ${displayFirst}` : option?.lastName ? `${option?.lastName}` : `${option?.firstName}`}`;
                                    const displayTitle = `${option?.jobTitle ? `(${option?.jobTitle})` : ``}`;
                                    const displayClient = `${option?.client?.name ? ` - ${option?.client?.name}` : ``}`;
                                    const displaySelect = `${displayName} ${displayTitle} ${displayClient}`;
                                    return (<li {...props} key={option.rid}>{displaySelect}</li>);
                                }}
                                onChange={(event, newValue) => {
                                    handleChangecontact(event, newValue)
                                }}
                                value={contact || null}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Contact"
                                        error={contactErr}
                                        InputProps={{
                                            ...params.InputProps, endAdornment: (
                                                <Fragment>
                                                    {loadingContactList ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </Fragment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Box>
                    </Box>

                    <TextField sx={{ marginTop: '20px' }} variant="standard" id="status" label="Status" value={status} error={statusErr} onChange={handleChangestatus} select fullWidth>
                        {disbursementsStatus.map((option) => (<MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>))}
                    </TextField>

                    <TextField sx={{ marginTop: '20px' }} variant="standard" id="destinationtype" label="Destination Type" value={destinationtype} error={destinationtypeErr} onChange={handledestinationtypeSet} select fullWidth>
                        {disbursementsDestinationType.map((option) => (<MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>))}
                    </TextField>

                    <TextField sx={{ marginTop: '20px' }} variant="standard" id="destination" label="Destination" value={destination} onChange={handledestinationSet} fullWidth />
                    <TextField sx={{ marginTop: '20px' }} error={quantityErr} variant="standard" id="quantity" label="Quantity" type="number" value={quantity} onChange={handlequantitySet} fullWidth />

                    <TextField variant="standard" id="currency" label="Currency" value={currency} onChange={handleChangecurrency} select fullWidth>
                        {currencies.map((option) => (<MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>))}
                    </TextField>

                    <TextField variant="standard" id="value" label="Value" type="number" value={value} onChange={handleChangevalue} />

                    <TextField sx={{ marginTop: '20px' }} variant="outlined" id="note" label="Note" multiline rows={3} value={note} onChange={handleChangenote} fullWidth />
                </FormControl>

                <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{marginTop: '20px'}}>
                    <Grid item>
                        <Button aria-label="cancel" variant="outlined" onClick={navGoBack}>Cancel</Button>
                    </Grid>
                    <Grid item>
                        <Button aria-label="save form" variant="outlined" onClick={collectFormData} endIcon={<SaveIcon strokeWidth={1} size={16} />}>Save</Button>
                    </Grid>
                </Grid>

            </Paper>

        </Container>
    );
}