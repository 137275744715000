import React, { useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { gql, useLazyQuery } from '@apollo/client';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { ChevronLeft as ChevronLeftIcon } from 'lucide-react';

import CreateInventoryForm from './CreateInventoryForm';

const GET_SWAG = gql`
    query GetSwag(
        $getRid: String
    ) {
        swaginventorydetail(
            getRid: $getRid
        ) {
            swag{
                rid
                status
                manufacturer
                name
                category
                subcategory
                vendor
                description
                currency
                value
                images
            }
            responseType
            responseInfo
        }
    }
`;

export default function CreateInventory(props) {
    const { isMobile } = props;
    let { rid } = useParams();

    let navigate = useNavigate();

    const [getDetail, { error, loading, data }] = useLazyQuery(
        GET_SWAG, { fetchPolicy: "network-only", variables:{"getRid":rid} }
    );

    useEffect(() => {
        getDetail();
    }, [rid]);

    const navToUpdate = (rid) => {
        navigate(`/inventory/detail/${rid}`, { replace: true, state: { modify: 'created' } });
    }

    const navGoBack = () => {
        navigate(-1, { replace: true });
    }

    return (
        <Box component="main" sx={{flexGrow: 1,height: '100vh',overflow: 'auto',}}>
        <Container sx={{ marginTop: '80px' }} maxWidth="xl">

            <Grid container direction="row" justifyContent="space-between" alignItems="flex-end" spacing={2} sx={{ marginBottom: '8px' }}>
                <Grid item>
                    {isMobile ?
                    <Button onClick={navGoBack} aria-label="go back"><ChevronLeftIcon strokeWidth={1} /></Button>
                    :
                    <Button onClick={navGoBack} aria-label="go back" startIcon={<ChevronLeftIcon strokeWidth={1} />}>Go Back</Button>
                    }
                </Grid>
                <Grid item md order={{ xs: 2, md: 3 }}>
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item sx={{ fontSize: '1.6rem' }}>
                            Create Inventory
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item order={{ xs: 2, md: 3 }}>
                    <Button aria-label="cancel" variant="outlined" onClick={navGoBack}>Cancel</Button>
                </Grid>
            </Grid>

            {error ?
                <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ marginTop: '20%' }}>
                    <Grid item>
                        <Alert severity="error" variant="outlined">
                            <AlertTitle>Error</AlertTitle>
                            There was an error processing this request. Please try again or contact us for support!
                        </Alert>
                    </Grid>
                </Grid>
                :
                loading ?
                    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
                        <CircularProgress sx={{color:'#c9fb00'}} thickness={1} size={60} />
                    </Backdrop>
                    :
                    (data) ?
                        <CreateInventoryForm data={data} navGoBack={navGoBack} navToUpdate={navToUpdate} />
                        :
                        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
                            <CircularProgress sx={{color:'#c9fb00'}} thickness={1} size={60} />
                        </Backdrop>
            }

        </Container>
        </Box>
    );
}