import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';

import { MinusCircle as MinusCircleIcon, PlusCircle as PlusCircleIcon } from 'lucide-react';

export default function ManualPanel(props) {
    const { assignedQty, quantity, quantitySet, note, noteSet } = props;

    const handleChangenote = (event) => {
        noteSet(event.target.value);
    };

    useEffect(() => {
        if(Number.isInteger(assignedQty)){
            adjustQty(assignedQty);
        }
        else{
            adjustQty(0);
        }
    }, [assignedQty]);

    const handlequantitySet = (event) => {
        adjustQty(event.target.value);
    };

    const incrementValue = () => {
        let qtyVal;
        if (!quantity || quantity < 1){
            qtyVal = 1;
        }
        else{
            qtyVal = quantity + 1;
        }
        adjustQty(qtyVal);
    }

    const decrementValue = () => {
        let qtyVal;
        if (!quantity || quantity < 1){
            qtyVal = 0;
        }
        else{
            qtyVal = quantity - 1;
        }
        adjustQty(qtyVal);    
    }

    const adjustQty = (qty) => {
        if (qty < 1){
            qty = 0;
        }
        quantitySet(qty);
    }

    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                <IconButton aria-label="decrement" onClick={decrementValue}>
                    <MinusCircleIcon strokeWidth={1} size={36} />
                </IconButton>

                <TextField variant="outlined" id="quantity" label="Quantity" type="number" value={quantity} onChange={handlequantitySet} />

                <IconButton aria-label="increment" onClick={incrementValue}>
                    <PlusCircleIcon strokeWidth={1} size={36} />
                </IconButton>
            </Box>
            <TextField sx={{marginTop:'20px'}} variant="outlined" id="note" label="Note" multiline rows={3} value={note} onChange={handleChangenote} fullWidth />
        </Box>
    );
}