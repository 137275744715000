import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { Save as SaveIcon } from 'lucide-react';

import { userStatus, userTypeList } from '../../utils/selectOptions';

const SET_USER = gql`
    mutation ModifyUser($rid: String, $firstName: String, $lastName: String, $email: String, $status: String, $userType: String, $username: String, $imgFile: Upload) {
        modifyUser(rid: $rid, firstName: $firstName, lastName: $lastName, email: $email, status: $status, userType: $userType, username: $username, imgFile: $imgFile){
            responseCode
            responseText
            actionType
            objectType
            objectId
		}
	}
`;

const scrollToTop = () =>{window.scrollTo({top: 0, behavior: 'smooth'});};

export default function ModifyForm(props) {
    const { data, navGoBack, navToUpdate } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [serverErr, setServerErr] = useState(false);

    const data_rid = data?.userdetail[0].result.rid;
    const data_status = data?.userdetail[0].result.status;
    const data_userType = data?.userdetail[0].result.userType;
    const data_firstName = data?.userdetail[0].result.firstName;
    const data_lastName = data?.userdetail[0].result.lastName;
    const data_casedUsername = data?.userdetail[0].result.casedUsername;
    const data_email = data?.userdetail[0].result.email;
    
    // const data_images = data?.userdetail[0].result.images;

    // const imgSrc = "";

    const [errCode, errCodeSet] = useState(false);
    const [usernameErr, usernameErrSet] = useState(false);
    const [statusErr, statusErrSet] = useState(false);
    const [userTypeErr, userTypeErrSet] = useState(false);

    const [status, statusSet] = useState(data_status || '');
    const handleChangestatus = (event) => {
        statusSet(event.target.value);
    };

    const [userType, userTypeSet] = useState(data_userType || '');
    const handleChangeuserType = (event) => {
        userTypeSet(event.target.value);
    };

    const [firstName, firstNameSet] = useState(data_firstName || '');
    const handleChangefirstName = (event) => {
        firstNameSet(event.target.value);
    };

    const [lastName, lastNameSet] = useState(data_lastName || '');
    const handleChangelastName = (event) => {
        lastNameSet(event.target.value);
    };

    const [username, usernameSet] = useState(data_casedUsername || '');
    const handleChangeusername = (event) => {
        usernameSet(event.target.value);
    };

    const [email, emailSet] = useState(data_email || '');
    const handleChangeemail = (event) => {
        emailSet(event.target.value);
    };

    const collectFormData = () => {
        const formData = {};
        let formValid = true;

        errCodeSet(false);
        setServerErr(false);
        usernameErrSet(false);
        statusErrSet(false);
        userTypeErrSet(false);

        if(!data_rid || data_rid === ""){
            formValid = false;
        }

        if (!username || username === ""){
            usernameErrSet(true);
            formValid = false;
        }

        if (!status || status === ""){
            statusErrSet(true);
            formValid = false;
        }

        if (!userType || userType === ""){
            userTypeErrSet(true);
            formValid = false;
        }

        if (formValid){
            setServerErr(false);
            formData.rid = data_rid;
            formData.firstName = firstName;
            formData.lastName = lastName;
            formData.username = username;
            formData.email = email;
            formData.userType = userType;
            formData.status = status;

            setIsLoading(true);
            setUser({variables:{...formData}});
        }
        else{
            scrollToTop();
            if(!errCode){
                errCodeSet(true);
            }
        }
        
    }

    const [setUser] = useMutation(SET_USER, {
        onCompleted: (setData) => {
            setIsLoading(false);
            if (setData && setData?.modifyUser?.responseCode === "200"){
                navToUpdate(data_rid);
            }
            else{
                setServerErr(`There was an issue with your request.`);
                scrollToTop();
            }
        },
        onError: (setError) => {
            setIsLoading(false);
            setServerErr(`There was an error processing your request.`);
            scrollToTop();
        }
    });

    return (
        <Container maxWidth="sm">            
            {isLoading && 
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}><CircularProgress sx={{color:'#c9fb00'}} thickness={1} size={60} /></Backdrop>
            }

            {serverErr &&
            <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ marginTop: '12px', marginBottom: '12px'}}>
                <Grid item>
                    <Alert severity="error" variant="outlined">
                        <AlertTitle>Error</AlertTitle>
                        {serverErr}
                    </Alert>
                </Grid>
            </Grid>
            }

            {errCode &&
            <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ marginTop: '12px', marginBottom: '12px'}}>
                <Grid item>
                    <Alert severity="error" variant="outlined">
                        <AlertTitle>Error</AlertTitle>
                        Please resolve the form errors below!
                    </Alert>
                </Grid>
            </Grid>
            }

            <Paper sx={{ width: '100%', padding: '20px', marginBottom: '3rem' }}>
                <Box component="form" sx={{ '& > :not(style)': { padding: '20px', paddingBottom: '30px' } }}>

                    <TextField variant="standard" id="username" label="Username" error={usernameErr} value={username} onChange={handleChangeusername} fullWidth />

                    <TextField variant="standard" id="status" label="Status" error={statusErr} value={status} onChange={handleChangestatus} select fullWidth>
                        {userStatus.map((option) => (<MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>))}
                    </TextField>

                    <TextField variant="standard" id="userType" label="User Type" error={userTypeErr} value={userType} onChange={handleChangeuserType} select fullWidth>
                        {userTypeList.map((option) => (<MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>))}
                    </TextField>

                    <TextField variant="standard" id="firstName" label="First Name" value={firstName} onChange={handleChangefirstName} fullWidth />
                    <TextField variant="standard" id="lastName" label="Last Name" value={lastName} onChange={handleChangelastName} fullWidth />
                    <TextField variant="standard" id="email" label="Email" value={email} onChange={handleChangeemail} fullWidth />
                
                </Box>

                <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{marginTop: '20px'}}>
                    <Grid item>
                        <Button aria-label="cancel" variant="outlined" onClick={navGoBack}>Cancel</Button>
                    </Grid>
                    <Grid item>
                        <Button aria-label="save form" variant="outlined" onClick={collectFormData} endIcon={<SaveIcon strokeWidth={1} size={16} />}>Save User</Button>
                    </Grid>
                </Grid>

            </Paper>

        </Container>
    );
}