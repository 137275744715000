import React, { useState, useEffect } from 'react';
import { gql, useMutation } from '@apollo/client';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { Save as SaveIcon } from 'lucide-react';

import ImageDrop from '../common/ImageDrop';
import ImageList from '../common/ImageList';
import { swagStatus, swagCategory, currencies } from '../../utils/selectOptions';

const SET_SWAG = gql`
    mutation ModifySwag($rid: String, $status: String, $manufacturer: String, $name: String, $category: String, $subcategory: String, $vendor: String, $currency: String, $value: String, $description: String, $imgFile: Upload) {
        modifySwag(rid: $rid, status: $status, manufacturer: $manufacturer, name: $name, category: $category, subcategory: $subcategory, vendor: $vendor, currency: $currency, value: $value, description: $description, imgFile: $imgFile){
            responseCode
            responseText
            actionType
            objectType
            objectId
		}
	}
`;

const scrollToTop = () =>{window.scrollTo({top: 0, behavior: 'smooth'});};

export default function ModifyForm(props) {
    const { data, navGoBack, navToUpdate, refetchQuery, b2Auth, businessRid } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [serverErr, setServerErr] = useState(false);

    const data_swag_rid = data?.swaginventorydetail[0].swag?.rid;
    const data_swag_status = data?.swaginventorydetail[0].swag?.status;
    const data_swag_manufacturer = data?.swaginventorydetail[0].swag?.manufacturer;
    const data_swag_name = data?.swaginventorydetail[0].swag?.name;
    const data_swag_category = data?.swaginventorydetail[0].swag?.category;
    const data_swag_subcategory = data?.swaginventorydetail[0].swag?.subcategory;
    const data_swag_vendor = data?.swaginventorydetail[0].swag?.vendor;
    const data_swag_currency = data?.swaginventorydetail[0].swag?.currency;
    const data_swag_value = data?.swaginventorydetail[0].swag?.value;
    const data_swag_description = data?.swaginventorydetail[0].swag?.description;
    const foundImages = data?.swaginventorydetail[0].swag?.images;

    const displayId = data?.swaginventorydetail[0].swag?.rid;

    const [displayImages, setDisplayImages] = useState(null);
    const [imgFile, setImgFile] = useState(false);

    useEffect(() => {
        if(foundImages && foundImages !== "[]"){
            const displayImagesPreSort = JSON.parse(foundImages);
            setDisplayImages(displayImagesPreSort.sort((a, b)=>{return a.displayOrder - b.displayOrder;}));
        }
    }, [foundImages]);


    const [errCode, errCodeSet] = useState(false);
    const [statusErr, statusErrSet] = useState(false);
    const [categoryErr, categoryErrSet] = useState(false);
    const [subcategoryErr, subcategoryErrSet] = useState(false);
    const [nameErr, nameErrSet] = useState(false);

    const [status, statusSet] = useState(data_swag_status || "");
    const handleChangestatus = (event) => {
        statusSet(event.target.value);
    };

    const [category, categorySet] = useState(data_swag_category || "");
    const handleChangecategory = (event) => {
        categorySet(event.target.value);
    };

    const [subcategory, subcategorySet] = useState(data_swag_subcategory || "");
    const handleChangesubcategory = (event) => {
        subcategorySet(event.target.value);
    };

    const [manufacturer, manufacturerSet] = useState(data_swag_manufacturer || "");
    const handleChangemanufacturer = (event) => {
        manufacturerSet(event.target.value);
    };

    const [name, nameSet] = useState(data_swag_name || "");
    const handleChangename = (event) => {
        nameSet(event.target.value);
    };

    const [vendor, vendorSet] = useState(data_swag_vendor || "");
    const handleChangevendor = (event) => {
        vendorSet(event.target.value);
    };

    const [currency, currencySet] = useState(data_swag_currency || "");
    const handleChangecurrency = (event) => {
        currencySet(event.target.value);
    };

    const [value, valueSet] = useState(data_swag_value || "");
    const handleChangevalue = (event) => {
        valueSet(event.target.value);
    };

    const [description, descriptionSet] = useState(data_swag_description || "");
    const handleChangedescription = (event) => {
        descriptionSet(event.target.value);
    };

    const collectFormData = () => {
        const formData = {};
        let formValid = true;

        errCodeSet(false);
        setServerErr(false);
        statusErrSet(false);
        categoryErrSet(false);
        subcategoryErrSet(false);
        nameErrSet(false);

        if(!data_swag_rid || data_swag_rid === ""){
            formValid = false;
        }

        if (!status || status === ""){
            statusErrSet(true);
            formValid = false;
        }

        if (!category || category === ""){
            categoryErrSet(true);
            formValid = false;
        }

        if (!subcategory || subcategory === ""){
            subcategoryErrSet(true);
            formValid = false;
        }

        if (!name || name === ""){
            nameErrSet(true);
            formValid = false;
        }

        if (formValid){
            setServerErr(false);
            formData.rid = data_swag_rid;
            formData.status = status;
            formData.category = category;
            formData.subcategory = subcategory;
            formData.manufacturer = manufacturer;
            formData.name = name;
            formData.vendor = vendor;
            formData.currency = currency;
            formData.value = value;
            formData.description = description;
            formData.imgFile = imgFile[0];

            setIsLoading(true);
            setSwag({variables:{...formData}});
        }
        else{
            scrollToTop();
            if(!errCode){
                errCodeSet(true);
            }
        }
        
    }

    const [setSwag] = useMutation(SET_SWAG, {
        onCompleted: (setData) => {
            setIsLoading(false);
            if (setData && setData?.modifySwag?.responseCode === "200"){
                navToUpdate(data_swag_rid);
            }
            else{
                setServerErr(`There was an issue with your request.`);
                scrollToTop();
            }
        },
        onError: (setError) => {
            setIsLoading(false);
            setServerErr(`There was an error processing your request.`);
            scrollToTop();
        }
    });

    return (
        <Container maxWidth="sm">            
            {isLoading && 
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}><CircularProgress sx={{color:'#c9fb00'}} thickness={1} size={60} /></Backdrop>
            }

            {serverErr &&
            <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ marginTop: '12px', marginBottom: '12px'}}>
                <Grid item>
                    <Alert severity="error" variant="outlined">
                        <AlertTitle>Error</AlertTitle>
                        {serverErr}
                    </Alert>
                </Grid>
            </Grid>
            }

            {errCode &&
            <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ marginTop: '12px', marginBottom: '12px'}}>
                <Grid item>
                    <Alert severity="error" variant="outlined">
                        <AlertTitle>Error</AlertTitle>
                        Please resolve the form errors below!
                    </Alert>
                </Grid>
            </Grid>
            }

            <Paper sx={{ width: '100%', padding: '20px', marginBottom: '20px' }}>
                <Box component="form" sx={{ '& > :not(style)': { padding: '20px', paddingBottom: '20px' } }}>

                    <TextField variant="standard" id="status" label="Status" error={statusErr} value={status} onChange={handleChangestatus} select fullWidth>
                        {swagStatus.map((option) => (<MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>))}
                    </TextField>

                    <TextField variant="standard" id="category" label="Category" error={categoryErr} value={category} onChange={handleChangecategory} select fullWidth>
                        {swagCategory.map((option) => (<MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>))}
                    </TextField>

                    <TextField variant="standard" id="subcategory" label="Sub category" error={subcategoryErr} value={subcategory} onChange={handleChangesubcategory} fullWidth />
                    <TextField variant="standard" id="manufacturer" label="Manufacturer" value={manufacturer} onChange={handleChangemanufacturer} fullWidth />
                    <TextField variant="standard" id="name" label="Name" error={nameErr} value={name} onChange={handleChangename} fullWidth />
                    <TextField variant="standard" id="vendor" label="Vendor" value={vendor} onChange={handleChangevendor} fullWidth />

                    <TextField variant="standard" id="currency" label="Currency" value={currency} onChange={handleChangecurrency} select fullWidth>
                        {currencies.map((option) => (<MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>))}
                    </TextField>

                    <TextField variant="standard" id="value" label="Value" type="number" value={value} onChange={handleChangevalue} />

                    <TextField variant="standard" id="description" label="Description" multiline rows={4} value={description} onChange={handleChangedescription} fullWidth />

                </Box>
            </Paper>

            <Paper sx={{ width: '100%', padding: '20px', marginBottom: '20px' }}>
                <ImageList imgType="swag" sourceTypeId={displayId} images={displayImages} refetchQuery={refetchQuery} b2Auth={b2Auth} businessRid={businessRid} />
                <ImageDrop setImgFile={setImgFile} />
            </Paper>

            <Paper sx={{ width: '100%', padding: '20px' }}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Button aria-label="cancel" variant="outlined" onClick={navGoBack}>Cancel</Button>
                    </Grid>
                    <Grid item>
                        <Button aria-label="save form" variant="outlined" onClick={collectFormData} endIcon={<SaveIcon strokeWidth={1} size={16} />}>Save</Button>
                    </Grid>
                </Grid>

            </Paper>

        </Container>
    );
}