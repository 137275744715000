import React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { ChevronLeft as ChevronLeftIcon } from 'lucide-react';

import CreateForm from './CreateForm';

export default function CreateNote(props) {
    const { isMobile } = props;
    let { type, rid } = useParams();
    let navigate = useNavigate();

    const navToUpdate = () => {
        navigate(`/${type}/detail/${rid}`, { replace: true, state: { modify: 'notated' } });
    }

    const navGoBack = () => {
        navigate(-1, { replace: true });
    }

    return (
        <Box component="main" sx={{flexGrow: 1,height: '100vh',overflow: 'auto',}}>
        <Container sx={{ marginTop: '80px' }} maxWidth="xl">

            <Grid container direction="row" justifyContent="space-between" alignItems="flex-end" spacing={2} sx={{ marginBottom: '8px' }}>
                <Grid item>
                    {isMobile ?
                    <Button onClick={navGoBack} aria-label="go back"><ChevronLeftIcon strokeWidth={1} /></Button>
                    :
                    <Button onClick={navGoBack} aria-label="go back" startIcon={<ChevronLeftIcon strokeWidth={1} />}>Go Back</Button>
                    }
                </Grid>
                <Grid item md order={{ xs: 2, md: 3 }}>
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item sx={{ fontSize: '1.6rem' }}>
                            Create Note
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item order={{ xs: 2, md: 3 }}>
                    <Button aria-label="cancel" variant="outlined" onClick={navGoBack}>Cancel</Button>
                </Grid>
            </Grid>
            <CreateForm noteobj={type} noteobjrid={rid} navGoBack={navGoBack} navToUpdate={navToUpdate} />
        </Container>
        </Box>
    );
}