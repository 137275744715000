import React, { Fragment } from 'react';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { dateOptionsMidTime } from '../../utils/dateoptions';

export default function DetailDisplay(props) {
    const { data } = props;
    const theme = useTheme();

    const rid = data?.disbursementdetail[0].result?.rid;
    const status = data?.disbursementdetail[0].result.status;
    const quantity = data?.disbursementdetail[0].result.quantity;
    const currency = data?.disbursementdetail[0].result.currency;
    const value = data?.disbursementdetail[0].result.value;
    const destinationtype = data?.disbursementdetail[0].result.destinationtype;
    const destination = data?.disbursementdetail[0].result.destination;
    const color = data?.disbursementdetail[0].result.swaginventory?.color;
    const size = data?.disbursementdetail[0].result.swaginventory?.size;
    const company_rid = data?.disbursementdetail[0].result.client?.rid;
    const company_name = data?.disbursementdetail[0].result.client?.name;
    const contact_rid = data?.disbursementdetail[0].result.person?.rid;
    const contact_persontype = data?.disbursementdetail[0].result.person?.persontype;
    const contact_firstName = data?.disbursementdetail[0].result.person?.firstName;
    const contact_lastName = data?.disbursementdetail[0].result.person?.lastName;
    const contact_jobTitle = data?.disbursementdetail[0].result.person?.jobTitle;
    const displayFirst = `${contact_firstName ? `${contact_firstName}` : ``}`;
    const displayLast = `${contact_lastName ? `${contact_lastName}` : ``}`;
    const displayName = `${(contact_lastName && contact_firstName) ? `${displayLast}, ${displayFirst}` : contact_lastName ? `${contact_lastName}` : `${contact_firstName}`}`;
    const displayTitle = `${contact_jobTitle ? `(${contact_jobTitle})` : ``}`;
    const displayType = `${contact_persontype ? ` (${contact_persontype})` : ``}`;
    let displayContact = "";
    if (data?.disbursementdetail[0].result?.person) {
        displayContact = `${displayName} ${displayTitle} ${displayType}`;
    }
    const swag_rid = data?.disbursementdetail[0].result.swag?.rid;
    const swag_category = data?.disbursementdetail[0].result.swag?.category;
    const swag_subcategory = data?.disbursementdetail[0].result.swag?.subcategory;
    const swag_manufacturer = data?.disbursementdetail[0].result.swag?.manufacturer;
    const swag_name = data?.disbursementdetail[0].result.swag?.name;
    const displayUpdated = (new Date(data?.disbursementdetail[0].result.updated)).toLocaleDateString(undefined, dateOptionsMidTime);
    const logs = data?.disbursementdetail[0].logs;

    let dispValue = "", dispCurrency = "";
    if (currency){
        const splitCurrency = currency.split("-");
        if(splitCurrency[1]){dispCurrency = splitCurrency[1];}
        else{dispCurrency = currency;}
    }
    if(currency || value){dispValue = `${dispCurrency} ${value}`;}

    return (
        <Fragment>
            <Paper sx={{ width: '100%', padding: '20px', marginBottom: '3rem' }}>

                <Grid container direction="row" justifyContent="" alignItems="center">
                    <Grid item>
                        <Grid container>
                            <Grid item>
                                <Box sx={theme.customStyles.infoItem}>
                                    <Box sx={theme.customStyles.infoItemTitle}>Category</Box>
                                    <Box sx={theme.customStyles.infoItemContent}>{swag_category} - {swag_subcategory}</Box>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box sx={theme.customStyles.infoItem}>
                                    <Box sx={theme.customStyles.infoItemTitle}>Manufacturer</Box>
                                    <Box sx={theme.customStyles.infoItemContent}>{swag_manufacturer}</Box>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box sx={theme.customStyles.infoItem}>
                                    <Box sx={theme.customStyles.infoItemTitle}>Item Name</Box>
                                    <Box sx={theme.customStyles.infoItemContent}>{swag_name}</Box>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box sx={theme.customStyles.infoItem}>
                                    <Box sx={theme.customStyles.infoItemTitle}>Color</Box>
                                    <Box sx={theme.customStyles.infoItemContent}>{color}</Box>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box sx={theme.customStyles.infoItem}>
                                    <Box sx={theme.customStyles.infoItemTitle}>Size</Box>
                                    <Box sx={theme.customStyles.infoItemContent}>{size}</Box>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item>
                                <Box sx={theme.customStyles.infoItem}>
                                    <Box sx={theme.customStyles.infoItemTitle}>Status</Box>
                                    <Box sx={theme.customStyles.infoItemContent}>{status}</Box>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box sx={theme.customStyles.infoItem}>
                                    <Box sx={theme.customStyles.infoItemTitle}>Destination Type</Box>
                                    <Box sx={theme.customStyles.infoItemContent}>{destinationtype}</Box>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box sx={theme.customStyles.infoItem}>
                                    <Box sx={theme.customStyles.infoItemTitle}>Destination</Box>
                                    <Box sx={theme.customStyles.infoItemContent}>{destination}</Box>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box sx={theme.customStyles.infoItem}>
                                    <Box sx={theme.customStyles.infoItemTitle}>Company</Box>
                                    <Box sx={theme.customStyles.infoItemContent}>{company_name}</Box>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box sx={theme.customStyles.infoItem}>
                                    <Box sx={theme.customStyles.infoItemTitle}>Contact</Box>
                                    <Box sx={theme.customStyles.infoItemContent}>{displayContact}</Box>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box sx={theme.customStyles.infoItem}>
                                    <Box sx={theme.customStyles.infoItemTitle}>Quantity</Box>
                                    <Box sx={theme.customStyles.infoItemContent}>{quantity}</Box>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box sx={theme.customStyles.infoItem}>
                                    <Box sx={theme.customStyles.infoItemTitle}>Value</Box>
                                    <Box sx={theme.customStyles.infoItemContent}>{dispValue}</Box>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item>
                                <Box sx={theme.customStyles.infoItem}>
                                    <Box sx={theme.customStyles.infoItemTitle}>Updated</Box>
                                    <Box sx={theme.customStyles.infoItemContent}>{displayUpdated}</Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Paper>

            <Paper sx={{ width: '100%', padding: '20px', marginBottom: '3rem' }}>
                <Grid container direction="row" justifyContent="space-between">
                    <Grid item>History</Grid>
                    <Grid item>
                        {/* <Button aria-label="add contact" variant="outlined" onClick={createContact} endIcon={<PlusIcon strokeWidth={1} />}>Add Contact</Button> */}
                    </Grid>
                </Grid>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell>User</TableCell>
                                <TableCell>Action</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Modified Data</TableCell>
                                <TableCell>Note</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {logs?.length > 0 ?
                                logs.map((row, index) => {
                                    const dispCreated = (new Date(row?.created)).toLocaleDateString(undefined, dateOptionsMidTime);
                                    const modData = row.modData;
                                    let parsedModData;
                                    let displayNote = "";
                                    let displayModData = "";

                                    try {
                                        parsedModData = JSON.parse(modData);
                                        parsedModData.forEach(item => {
                                            const keyList = Object.keys(item);
                                            const curField = keyList[0];
                                            if(curField === "note"){
                                                displayNote = item?.note?.data;
                                            }
                                            else{
                                                displayModData = `${displayModData} ${item?.[curField]?.title}: ${item?.[curField]?.data}; `;
                                            }
                                        });

                                    }
                                    catch (err) {
                                        parsedModData = [];
                                    }

                                    return (
                                        <TableRow hover key={index}>
                                            <TableCell>{row.user.username}</TableCell>
                                            <TableCell>{row.action.toUpperCase()}</TableCell>
                                            <TableCell>{dispCreated}</TableCell>
                                            <TableCell>{displayModData}</TableCell>
                                            <TableCell>{displayNote}</TableCell>
                                        </TableRow>
                                    );
                                })
                                :
                                <TableRow>
                                    <TableCell colSpan="100%">No Results</TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

        </Fragment>
    );
}