import React, { useState, Fragment } from 'react';
import { Link } from "react-router-dom";
import { useTheme, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import { Settings as SettingsIcon, LogOut as LogOutIcon } from 'lucide-react';

export default function AppBarMenu(props) {
    const { uname, isMobile} = props;
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const LinkStyled = styled(Link)({
        textDecoration: 'none',
        color: '#000'
    });

    return (
        <Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                {!isMobile && <Typography>{uname}</Typography>}
                <Tooltip title="Account settings">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <LinkStyled to="/mysettings">
                    <MenuItem sx={theme.customStyles.appBarMenuItem}>
                        <ListItemIcon sx={theme.customStyles.appBarMenuIcon}>
                            <SettingsIcon size={theme.customStyles.appBarMenuIcon.size} strokeWidth={theme.customStyles.appBarMenuIcon.strokeWidth} />
                        </ListItemIcon>
                        My Settings
                    </MenuItem>
                </LinkStyled>
                <LinkStyled to="/logout">
                    <MenuItem sx={theme.customStyles.appBarMenuItem}>
                        <ListItemIcon sx={theme.customStyles.appBarMenuIcon}>
                            <LogOutIcon size={theme.customStyles.appBarMenuIcon.size} strokeWidth={theme.customStyles.appBarMenuIcon.strokeWidth} />
                        </ListItemIcon>
                        Logout
                    </MenuItem>
                </LinkStyled>
            </Menu>
        </Fragment>
    );
}