import React, { Fragment } from 'react';
import { useNavigate } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';

import { dateOptionsMidTime } from '../../utils/dateoptions';

export default function DetailDisplay(props) {
    const { data } = props;
    const theme = useTheme();
    let navigate = useNavigate();

    const rid = data?.note[0].result?.rid;
    const severity = data?.note[0].result.severity;
    const notetype = data?.note[0].result.notetype;
    const status = data?.note[0].result.status;
    const title = data?.note[0].result.title;
    const content = data?.note[0].result.content;

    const clientRid = data?.note[0].result.client?.rid;
    const clientName = data?.note[0].result.client?.name;

    const personRid = data?.note[0].result.person?.rid;
    const personfirstName = data?.note[0].result.person?.firstName;
    const personlastName = data?.note[0].result.person?.lastName;
    const person = `${personlastName}, ${personfirstName}`;

    const swagRid = data?.note[0].result.swag?.rid;
    const swagManufacturer = data?.note[0].result.swag?.manufacturer;
    const swagName = data?.note[0].result.swag?.name;
    const swag = `${swagManufacturer} - ${swagName}`;

    const swaginventoryRid = data?.note[0].result.swaginventory?.rid;
    const swaginventoryManufacturer = data?.note[0].result?.swaginventory?.swag?.manufacturer;
    const swaginventoryName = data?.note[0].result?.swaginventory?.swag?.name;
    const swaginventory = `${swaginventoryManufacturer} - ${swaginventoryName}`;

    const disbursementRid = data?.note[0].result.disbursement?.rid;
    const disbursementDestinationtype = data?.note[0].result.disbursement?.destinationtype;
    const disbursementSwagManufacturer = data?.note[0].result.disbursement?.swag?.manufacturer;
    const disbursementSwagName = data?.note[0].result.disbursement?.swag?.name;
    const disbursement = `${disbursementSwagManufacturer} - ${disbursementSwagName} (${disbursementDestinationtype})`;

    const history = data?.note[0].history;

    let noteClass = "", noteAssociated = "", associatedLink = "", associatedLabel = "";

    if(clientRid){
        noteClass = "Company";
        noteAssociated = clientName;
        associatedLabel = `company ${clientName} detail`;
        associatedLink = `/companies/detail/${clientRid}`;
    }
    else if(personRid){
        noteClass = "Contact";
        noteAssociated = person;
        associatedLabel = `contact ${person} detail`;
        associatedLink = `/contacts/detail/${personRid}`;
    }
    else if(swagRid){
        noteClass = "Swag";
        noteAssociated = swag;
        associatedLabel = `swag ${swag} detail`;
        associatedLink = `/swag/detail/${swagRid}`;
    }
    else if(swaginventoryRid){
        noteClass = "Swag Inventory";
        noteAssociated = swaginventory;
        associatedLabel = `swag inventory ${swaginventory} detail`;
        associatedLink = `/swaginventory/detail/${swaginventoryRid}`;
    }
    else if(disbursementRid){
        noteClass = "Disbursement";
        noteAssociated = disbursement;
        associatedLabel = `disbursement ${disbursement} detail`;
        associatedLink = `/disbursements/detail/${disbursementRid}`;
    }

    const navToAssoc = () => {
        navigate(`${associatedLink}`);
    }

    return (
        <Fragment>
            <Paper sx={{ width: '100%', padding: '20px', marginBottom: '3rem' }}>
                <Grid container direction="row" justifyContent="" alignItems="center">
                    <Grid item>
                        <Grid container>
                            <Grid item>
                                <Box sx={theme.customStyles.infoItem}>
                                    <Box sx={theme.customStyles.infoItemTitle}>Class</Box>
                                    <Box sx={theme.customStyles.infoItemContent}>{noteClass}</Box>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box sx={theme.customStyles.infoItem}>
                                    <Box sx={theme.customStyles.infoItemTitle}>Associated</Box>
                                    <Box sx={theme.customStyles.infoItemContent}>
                                        <Button aria-label={associatedLabel} onClick={navToAssoc}>{noteAssociated}</Button>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item>
                                <Box sx={theme.customStyles.infoItem}>
                                    <Box sx={theme.customStyles.infoItemTitle}>Severity</Box>
                                    <Box sx={theme.customStyles.infoItemContent}>{severity}</Box>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box sx={theme.customStyles.infoItem}>
                                    <Box sx={theme.customStyles.infoItemTitle}>Status</Box>
                                    <Box sx={theme.customStyles.infoItemContent}>{status}</Box>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box sx={theme.customStyles.infoItem}>
                                    <Box sx={theme.customStyles.infoItemTitle}>Type</Box>
                                    <Box sx={theme.customStyles.infoItemContent}>{notetype}</Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item>
                                <Box sx={theme.customStyles.infoItem}>
                                    <Box sx={theme.customStyles.infoItemTitle}>Title</Box>
                                    <Box sx={theme.customStyles.infoItemContent}>{title}</Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item>
                                <Box sx={theme.customStyles.infoItem}>
                                    <Box sx={theme.customStyles.infoItemTitle}>Content</Box>
                                    <Box sx={theme.customStyles.infoItemContent}>{content}</Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>

            <Paper sx={{ width: '100%', padding: '20px', marginBottom: '3rem' }}>
                <Grid container direction="row" justifyContent="space-between">
                    <Grid item>History</Grid>
                </Grid>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Action</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>User</TableCell>
                                <TableCell>Changes</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {history?.length > 0 ?
                                history.map((row,index) => {
                                    const updatedFormatted = (new Date(row.created)).toLocaleDateString(undefined, dateOptionsMidTime);
                                    const parsedData = JSON.parse(row?.modData);
                                    let displayChanges = "", displayAction = "";

                                    if(row.action === "modify"){
                                        displayAction = "Updated";
                                        parsedData.map((subrow) => {
                                            const curSubrow = Object.entries(subrow);
                                            const fieldName = curSubrow?.[0]?.[1]?.title;
                                            const fieldData = curSubrow?.[0]?.[1]?.data;
                                            displayChanges = displayChanges + `${fieldName}: ${fieldData}; `
                                        });
                                    }
                                    else{
                                        displayAction = "Created";
                                        displayChanges = "Item created."
                                    }
                                    
                                    return (
                                        <TableRow hover key={index}>
                                            <TableCell>{displayAction}</TableCell>
                                            <TableCell>{updatedFormatted}</TableCell>
                                            <TableCell>{row?.user?.username}</TableCell>
                                            <TableCell>{displayChanges}</TableCell>
                                        </TableRow>
                                    );
                                })
                                :
                                <TableRow>
                                    <TableCell colSpan="100%">No Results</TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

        </Fragment>
    );
}