import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { Save as SaveIcon } from 'lucide-react';

import { noteStatus, noteSeverity, noteType } from '../../utils/selectOptions';

const SET_NOTE = gql`
    mutation ModifyNote($rid: String, $status: String, $severity: String, $notetype: String, $title: String, $content: String) {
        modifyNote(rid: $rid, status: $status, severity: $severity, notetype: $notetype, title: $title, content: $content){
            responseCode
            responseText
            actionType
            objectType
            objectId
		}
	}
`;

const scrollToTop = () =>{window.scrollTo({top: 0, behavior: 'smooth'});};

export default function ModifyForm(props) {
    const { data, navGoBack, navToUpdate } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [serverErr, setServerErr] = useState(false);

    const data_rid = data?.note[0].result?.rid;
    const data_severity = data?.note[0].result.severity;
    const data_notetype = data?.note[0].result.notetype;
    const data_status = data?.note[0].result.status;
    const data_title = data?.note[0].result.title;
    const data_content = data?.note[0].result.content;

    const [errCode, errCodeSet] = useState(false);
    const [severityErr, severityErrSet] = useState(false);
    const [statusErr, statusErrSet] = useState(false);
    const [notetypeErr, notetypeErrSet] = useState(false);
    const [titleErr, titleErrSet] = useState(false);

    const [severity, companySet] = useState(data_severity || '');
    const handleChangeseverity = (event) => {
        companySet(event.target.value);
    };

    const [status, statusSet] = useState(data_status || '');
    const handleChangestatus = (event) => {
        statusSet(event.target.value);
    };

    const [notetype, notetypeSet] = useState(data_notetype || '');
    const handleChangenotetype = (event) => {
        notetypeSet(event.target.value);
    };

    const [title, titleSet] = useState(data_title || '');
    const handleChangetitle = (event) => {
        titleSet(event.target.value);
    };

    const [content, contentSet] = useState(data_content || '');
    const handleChangecontent = (event) => {
        contentSet(event.target.value);
    };

    const collectFormData = () => {
        const formData = {};
        let formValid = true;

        errCodeSet(false);
        setServerErr(false);
        severityErrSet(false);
        statusErrSet(false);
        notetypeErrSet(false);
        titleErrSet(false);

        if(!data_rid || data_rid === ""){
            formValid = false;
        }

        if (!severity || severity === ""){
            severityErrSet(true);
            formValid = false;
        }

        if (!status || status === ""){
            statusErrSet(true);
            formValid = false;
        }

        if (!notetype || notetype === ""){
            notetypeErrSet(true);
            formValid = false;
        }

        if (!title || title === ""){
            titleErrSet(true);
            formValid = false;
        }

        if (formValid){
            setServerErr(false);
            formData.rid = data_rid;
            formData.status = status;
            formData.severity = severity;
            formData.notetype = notetype;
            formData.title = title;
            formData.content = content;
            setIsLoading(true);
            setNote({variables:{...formData}});
        }
        else{
            scrollToTop();
            if(!errCode){
                errCodeSet(true);
            }
        }
        
    }

    const [setNote] = useMutation(SET_NOTE, {
        onCompleted: (setData) => {
            setIsLoading(false);
            if (setData && setData?.modifyNote?.responseCode === "200"){
                navToUpdate(data_rid);
            }
            else{
                setServerErr(`There was an issue with your request.`);
                scrollToTop();
            }
        },
        onError: (setError) => {
            setIsLoading(false);
            setServerErr(`There was an error processing your request.`);
            scrollToTop();
        }
    });

    return (
        <Container maxWidth="sm">            
            {isLoading && 
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}><CircularProgress sx={{color:'#c9fb00'}} thickness={1} size={60} /></Backdrop>
            }

            {serverErr &&
            <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ marginTop: '12px', marginBottom: '12px'}}>
                <Grid item>
                    <Alert severity="error" variant="outlined">
                        <AlertTitle>Error</AlertTitle>
                        {serverErr}
                    </Alert>
                </Grid>
            </Grid>
            }

            {errCode &&
            <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ marginTop: '12px', marginBottom: '12px'}}>
                <Grid item>
                    <Alert severity="error" variant="outlined">
                        <AlertTitle>Error</AlertTitle>
                        Please resolve the form errors below!
                    </Alert>
                </Grid>
            </Grid>
            }

            <Paper sx={{ width: '100%', padding: '20px', marginBottom: '3rem' }}>
                <Box component="form" sx={{ '& > :not(style)': { padding: '20px', paddingBottom: '30px' } }}>

                    <TextField variant="standard" id="status" label="Status" error={statusErr} value={status} onChange={handleChangestatus} select fullWidth>
                        {noteStatus.map((option) => (<MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>))}
                    </TextField>

                    <TextField variant="standard" id="severity" label="Severity" error={severityErr} value={severity} onChange={handleChangeseverity} select fullWidth>
                        {noteSeverity.map((option) => (<MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>))}
                    </TextField>

                    <TextField variant="standard" id="notetype" label="Type" error={notetypeErr} value={notetype} onChange={handleChangenotetype} select fullWidth>
                        {noteType.map((option) => (<MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>))}
                    </TextField>

                    <TextField variant="standard" id="title" label="Title" error={titleErr} value={title} onChange={handleChangetitle} fullWidth />

                    <TextField variant="standard" id="content" label="Content" value={content} onChange={handleChangecontent} fullWidth multiline rows={4} />
                
                </Box>

                <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{marginTop: '20px'}}>
                    <Grid item>
                        <Button aria-label="cancel" variant="outlined" onClick={navGoBack}>Cancel</Button>
                    </Grid>
                    <Grid item>
                        <Button aria-label="save form" variant="outlined" onClick={collectFormData} endIcon={<SaveIcon strokeWidth={1} size={16} />}>Save Note</Button>
                    </Grid>
                </Grid>

            </Paper>

        </Container>
    );
}