export const userStatus = [{value: 'Active',label: 'Active'},{value: 'In-active',label: 'In-active'},{value: 'Removed',label: 'Removed'}];
export const userTypeList = [{value: 'Admin',label: 'Admin'},{value: 'User',label: 'User'},{value: 'View',label: 'View'}];

// export const currencies = [{value: 'USD - $',label: 'USD - $'},{value: 'EUR - €',label: 'EUR - €'},{value: 'JPY - ¥',label: 'JPY - ¥'},{value: 'GBP - £',label: 'GBP - £'},{value: 'CAD - $',label: 'CAD - $'},{value: 'AUD - $',label: 'AUD - $'},{value: 'INR - ₹',label: 'INR - ₹'},{value: 'BRL - $',label: 'BRL - $'},{value: 'MXN - $',label: 'MXN - $'}];
export const currencies = [{value: 'USD - $',label: 'USD - $'}];

export const clientStatus = [{value: 'Active',label: 'Active'},{value: 'In-active',label: 'In-active'},{value: 'Removed',label: 'Removed'}];

export const personType = [{value: 'Client',label: 'Client'},{value: 'Employee',label: 'Employee'},{value: 'Contractor',label: 'Contractor'},{value: 'Intern',label: 'Intern'},{value: 'Friend',label: 'Friend'},{value: 'Press',label: 'Press'},{value: 'Other',label: 'Other'}];
export const personStatus = [{value: 'Active',label: 'Active'},{value: 'In-active',label: 'In-active'},{value: 'Removed',label: 'Removed'}];

export const swagStatus = [{value: 'Active',label: 'Active'},{value: 'In-active',label: 'In-active'},{value: 'Deleted',label: 'Deleted'}];
export const swagCategory = [{value: 'Apparel',label: 'Apparel'},{value: 'Bags',label: 'Bags'},{value: 'Drinkware',label: 'Drinkware'},{value: 'Edible',label: 'Edible'},{value: 'Novelties',label: 'Novelties'},{value: 'Outdoor',label: 'Outdoor'},{value: 'Sinage',label: 'Sinage'},{value: 'Stationery',label: 'Stationery'},{value: 'Technology',label: 'Technology'},{value: 'Toys',label: 'Toys'},{value: 'Wellness',label: 'Wellness'},{value: 'Other',label: 'Other'}];

export const swagInventoryStatus = [{value: 'In-stock',label: 'In-stock'},{value: 'Ordered',label: 'Ordered'},{value: 'On hold',label: 'On hold'},{value: 'In-active',label:'In-active'},{value: 'Deleted',label:'Deleted'}];

// export const disbursementsStatus = [{value: 'Delivered',label: 'Delivered'},{value: 'Ordered',label: 'Ordered'},{value: 'Pending',label: 'Pending'},{value: 'Shipping',label: 'Shipping'},{value: 'On hold',label: 'On hold'},{value: 'In-active',label: 'In-active'},{value: 'Deleted',label: 'Deleted'}];
export const disbursementsStatus = [{value: 'Disbursed',label: 'Disbursed'},{value: 'Reserved',label: 'Reserved'},{value: 'Deleted',label: 'Deleted'}];
export const disbursementsDestinationType = [{value: 'Client Direct',label: 'Client Direct'},{value: 'General',label: 'General'},{value: 'VIP',label: 'VIP'},{value: 'Internal',label: 'Internal'},{value: 'Product Launch',label: 'Product Launch'},{value: 'Trade Show',label: 'Trade Show'}];
export const disbursementsDestination = [{value: 'Office',label: 'Office'},{value: 'Meeting',label: 'Meeting'},{value: 'Hotel',label: 'Hotel'}];

export const noteStatus = [{value: 'General Note',label: 'General Note'},{value: 'Active',label: 'Active'},{value: 'Pending',label: 'Pending'},{value: 'Completed',label:'Completed'}];
export const noteSeverity = [{value: 'Info',label: 'Info'},{value: 'Urgent',label: 'Urgent'},{value: 'Non-urgent',label: 'Non-urgent'}];
export const noteType = [{value: 'General',label: 'General'},{value: 'Request',label: 'Request'},{value: 'Inbound call',label: 'Inbound call'},{value: 'Outbound call',label: 'Outbound call'},{value: 'Email',label:'Email'},{value: 'In person',label:'In person'},{value: 'Tracking',label:'Tracking'}];